@import 'colors';

.lh-2-1-rem {
  line-height: 2.1rem;
}
.adp-directions {
  width: 100%;
}
.pac-container {
  z-index: 1051 !important;
}
.cf-height-calc {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.containerFlex {
  min-height: calc(100% + 90px);
  display: flex;
  flex-direction: column;
}
.mainContent {
  min-width: 100vw;
  flex: 1 0 auto;
}
.mainContentChild {
  flex: 1 0 auto;
}
.footer {
  /* for some reason this will cause problems when zoomed in a lot */
  /* margin-top: 15px; */
  padding-top: 0px !important;
  flex: none;
  flex-shrink: 0;
  width: 100%;
  height: 50px; /* Vertically center the text there */
  background-color: #fff;
}
.footer-left {
  float: left;
  padding: 0px 20px;
  text-align: left;
}
.footer-center {
  padding: 15px 0 0 0;
  margin: 0 auto;
  text-align: center;
}
.footer-right {
  line-height: 1rem;
  float: right;
  padding: 0px 20px;
  text-align: right;
}
.menu-level-3 {
  padding-left: 30px;
}
.darkCardHeader {
  background-color: rgba(0, 0, 0, 0.48);
  color: white;
}
.colorCardHeader {
  background-color: #ffa000;
  color: white;
}
.colorCardHeader2 {
  background-color: #ffb841;
  color: #151515;
}
.colorCardHeader3 {
  background-color: rgba(255, 184, 65, 0.55);
  color: #151515;
}
.colorCardHeader4 {
  background-color: #a6c489;
  color: #151515;
}
.dashboard-widget-card-header {
  background-color: unset;
  color: #151515;
  border-bottom: solid 4px #9e9e9e;
}

.hoverButton {
  // background-color: #303030;
  &:hover {
    background-color: map-get($bootstrap-colors, 'light') !important;
  }
}
.stepCircle {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #efefef;
  border-style: solid;
  border-width: 5px;
  border-color: #000;
}
.stepCircle2 {
  width: 5rem;
  height: 5rem;
  background-color: #efefef;
}
.stepCircle1 {
  width: 2.5rem;
  height: 2.5rem;
}
.stepCircleText {
  position: absolute;
  bottom: -2rem;
}

$line-item-height: 0.25rem;

.lineItem {
  width: 5rem;
  height: $line-item-height;
  background-color: #000;
  border-radius: $line-item-height / 2;
  border-width: 2px;
  border-color: #fff;
}

.Select-menu-outer {
  z-index: 999 !important;
}
.semi-transparent {
  background-color: rgba(255, 255, 255, 0) !important;
}
.login-card {
  border-radius: 0px;
  border: 0;
}
.input-transparent {
  background-color: rgba(255, 255, 255, 0.9) !important;
}

// OVERRIDE
@media (max-width: 1000px) {
  .carousel-indicators li {
    /* visibility: hidden; */
    top: 20px;
  }
}

// Pill form navigation
.nav.nav-pills .nav-link {
  color: white !important;
  background-color: #757575 !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.nav.nav-pills .nav-link.active {
  background-color: #4b4b4b !important;
}
.nav.nav-pills.nav-link:hover {
  background-color: #9d968e !important;
}
.invalid-feedback {
  font-weight: bold;
}

// CustomMultiSelect Component
.card .ReactVirtualized__Grid.ReactVirtualized__List {
  margin-bottom: 0px;
}
.tooltip-wrapper {
  max-width: 100%;
  max-height: 100%;
  display: block;
  text-align: center;
}

// React table row
.-even {
  background-color: #f8f8f8 !important;
}
// React Table header icon
.ReactTable .rt-thead .rt-th {
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: right 4px center;
}
.ReactTable .rt-thead .rt-th.-sort-asc {
  background-image: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-arrow-up-b-512.png');
  opacity: 0.8;
}
.ReactTable .rt-thead .rt-th.-sort-desc {
  background-image: url('https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-arrow-down-b-512.png');
  opacity: 0.8;
}

.nav-item {
  .nav-link {
    .sli-menu {
      font-weight: bold;
    }
  }
}

.rt-td,
div.rt-th {
  white-space: unset !important;
}

.ct-dark-600 {
  background-color: '#222';
}

.ct-dark-200 {
  background-color: '#e5e7eb';
}

.ct-dark-100 {
  background-color: '#f5f6f7';
}

.ct-blue-600 {
  background-color: '#2363eb';
}

.ct-yellow-600 {
  background-color: '#f9d13e';
}

.dark\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: map-get($material-design-colors, 'blue-600');
}

.text-white {
  --tw-text-opacity: 1;
  color: #fff;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-ct-blue-600 {
  --tw-text-opacity: 1;
  color: map-get($material-design-colors, 'blue-600');
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.p-4 {
  padding: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: map-get($material-design-colors, 'blue-700');
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.plr-6 {
  padding: 0 1.5rem;
}

.ptb-2 {
  padding: 0.5rem;
}

.border-b {
  border-bottom-width: 1px;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.list-decimal {
  list-style-type: decimal;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: #fff;
}

*,
::before,
::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb;
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: map-get($material-design-colors, 'blue-500');
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: map-get($material-design-colors, 'blue-500');
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: map-get($material-design-colors, 'grey-900');
}

.p-2\.5 {
  padding: 0.625rem;
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: map-get($material-design-colors, 'grey-50');
}

.border {
  border-width: 1px;
}

.w-2\/4 {
  width: 50%;
}

.block {
  display: block;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.btn.btn-secondary,
.btn.btn-secondary.dropdown-toggle {
  background: #7b1fa2;
  color: #fff;
  border-color: #7b1fa2;
  text-transform: uppercase;
  font-size: 0.875rem;
}

.dark\:text-gray-300 {
  --tw-text-opacity: 1;
  color: map-get($material-design-colors, 'grey-300') !important;
}

.dark\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: map-get($material-design-colors, 'grey-500') !important;
}

.dark\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: map-get($material-design-colors, 'grey-700') !important;
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: map-get($material-design-colors, 'purple-700');
}
