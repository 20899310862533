[data-layout="empty-view-2"] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  height: calc(100%);
  width: calc(100%);
  overflow: hidden;  
  background: url(/assets/images/jbg.jpeg) no-repeat center center fixed;
  background-size: cover;
}
