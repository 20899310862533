.sgbc-home-bg,
.sgbc-home-bg-membership {
  background-image: url(/assets/images/sgbchome.png); }
  .sgbc-home-bg h2,
  .sgbc-home-bg-membership h2 {
    font-size: 33px;
    padding: 8px;
    margin-bottom: 5px;
    text-transform: uppercase; }
  .sgbc-home-bg p,
  .sgbc-home-bg-membership p {
    font-size: 15px;
    font-weight: 600;
    padding: 10px; }

.sgbc-home-bg {
  height: 760px; }

.sgbc-home-bg-membership {
  height: 400px; }

.sgbc-newtheme.homepanel {
  margin-top: 70px;
  margin-bottom: 70px; }

.sgbc-newtheme.media .media-body {
  text-align: center; }

.sgbc-plan-price {
  font-size: 48px;
  padding: 30px 0;
  position: relative;
  background: #f2f2f2; }
  .sgbc-plan-price sub {
    font-size: 18px;
    font-weight: 700; }

.sgbc-home-bg h2,
.sgbc-home-bg p,
.sgbc-home-bg-membership h2,
.sgbc-home-bg-membership p {
  background-color: rgba(49, 59, 61, 0.6) !important;
  color: #fff;
  text-align: center;
  font-family: 'Varela', sans-serif; }

.btn.btn-newtheme {
  background-color: rgba(49, 59, 61, 0.6) !important;
  color: #fff;
  text-align: center;
  font-family: 'Varela', sans-serif; }

.btn.primary-btn-style-noncaps {
  background-color: #6ba17d !important;
  border-color: #6ba17d !important;
  font-weight: bold;
  text-transform: initial !important; }

.btn.primary-btn-style {
  background-color: #6ba17d !important;
  border-color: #6ba17d !important;
  font-weight: bold;
  text-transform: capitalize !important; }

.btn.primary-btn-style-selected {
  background-color: grey !important;
  border-color: grey !important;
  font-weight: bold;
  text-transform: capitalize !important; }

.btn.primary-btn-style-tc {
  background-color: #317446 !important;
  border-color: #317446 !important;
  font-weight: bold;
  text-transform: capitalize !important; }

.btn.primary-btn-style-outline-tc {
  background-color: white !important;
  border-color: #317446 !important;
  color: #317446 !important;
  font-weight: bold;
  text-transform: capitalize !important; }

.btn.primary-btn-style-outline {
  background-color: white !important;
  border-color: #6ba17d !important;
  color: #6ba17d !important;
  font-weight: bold;
  text-transform: capitalize !important; }

.primary-btn-lg {
  width: 200px;
  padding: 0.5rem; }

.primary-btn-md {
  width: 150px;
  padding: 0.5rem; }

.primary-btn-sm {
  width: 100px;
  padding: 0.5rem; }

.primary-btn-xs {
  width: 80px;
  padding: 0.5rem; }

.primary-btn-xxs {
  width: 70px;
  padding: 0.5rem; }
