.standard-4080-panel {
  background-color: rgba(240, 240, 240, 0.95);
  margin-top: 20px;
  padding: 10px 20px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .standard-4080-panel h3 {
    font-size: 1.0625rem;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: normal; }
  .standard-4080-panel .description {
    margin-bottom: 20px;
    font-size: 0.9375rem; }
  .standard-4080-panel label {
    font-weight: normal;
    margin-bottom: 10px; }
  .standard-4080-panel .input-group {
    margin-bottom: 20px; }
  .standard-4080-panel textarea {
    height: 120px; }
