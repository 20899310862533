.top-navigation-2 {
  min-height: 0;
  height: 50px; }
  .top-navigation-2 > ul {
    margin: 0;
    padding: 0; }
    .top-navigation-2 > ul > li {
      position: relative;
      padding: 16px 20px; }
      .top-navigation-2 > ul > li > a {
        white-space: nowrap; }
        .top-navigation-2 > ul > li > a:hover {
          color: #78be20 !important; }
      .top-navigation-2 > ul > li > ul {
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.35s ease;
        position: absolute;
        top: calc(100%);
        right: 0;
        min-width: 160px;
        z-index: 1030; }
        .top-navigation-2 > ul > li > ul > li {
          padding: 10px;
          white-space: nowrap; }
      .top-navigation-2 > ul > li:hover {
        color: #027ec1; }
        .top-navigation-2 > ul > li:hover > ul {
          visibility: visible;
          opacity: 1;
          pointer-events: auto; }

[data-top-navigation='light'] .top-navigation-2 {
  background: #ffffff;
  color: #424242; }
  [data-top-navigation='light'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='light'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='light'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #424242; }
    [data-top-navigation='light'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #424242; }
      [data-top-navigation='light'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='light'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='dark'] .top-navigation-2 {
  background: #424242;
  color: #ffffff; }
  [data-top-navigation='dark'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='dark'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='dark'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='dark'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='dark'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='dark'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='primary'] .top-navigation-2 {
  background: #303f9f;
  color: #ffffff; }
  [data-top-navigation='primary'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='primary'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='primary'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='primary'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='primary'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='primary'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='secondary'] .top-navigation-2 {
  background: #7b1fa2;
  color: #ffffff; }
  [data-top-navigation='secondary'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='secondary'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='secondary'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='secondary'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='secondary'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='secondary'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='info'] .top-navigation-2 {
  background: #0288d1;
  color: #ffffff; }
  [data-top-navigation='info'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='info'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='info'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='info'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='info'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='info'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='success'] .top-navigation-2 {
  background: #388e3c;
  color: #ffffff; }
  [data-top-navigation='success'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='success'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='success'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='success'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='success'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='success'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='warning'] .top-navigation-2 {
  background: #ffa000;
  color: #ffffff; }
  [data-top-navigation='warning'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='warning'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='warning'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='warning'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='warning'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='warning'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='danger'] .top-navigation-2 {
  background: #d32f2f;
  color: #ffffff; }
  [data-top-navigation='danger'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='danger'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='danger'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='danger'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='danger'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='danger'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }

[data-top-navigation='c-newdark'] .top-navigation-2 {
  background: #313b3d;
  color: #ffffff; }
  [data-top-navigation='c-newdark'] .top-navigation-2 .list-link {
    color: #ffffff; }
  [data-top-navigation='c-newdark'] .top-navigation-2 > ul li {
    text-transform: uppercase; }
    [data-top-navigation='c-newdark'] .top-navigation-2 > ul li > ul > li {
      background: #313b3d;
      color: #ffffff; }
    [data-top-navigation='c-newdark'] .top-navigation-2 > ul li:hover > ul > li {
      background: #313b3d;
      color: #ffffff; }
      [data-top-navigation='c-newdark'] .top-navigation-2 > ul li:hover > ul > li a:hover {
        color: #78be20; }
      [data-top-navigation='c-newdark'] .top-navigation-2 > ul li:hover > ul > li:hover {
        background: #313b3d; }
