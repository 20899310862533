.mobile-menu {
  display: block; }
  .mobile-menu header {
    position: absolute; }
  .mobile-menu #menu-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: url(/assets/icons/menu-icon.png) center; }
  .mobile-menu nav ul,
  .mobile-menu nav:active ul {
    position: absolute;
    padding: 20px;
    background: #fff;
    border: 5px solid #444;
    right: 20px;
    top: 60px;
    width: 50%;
    border-radius: 4px 0 4px 4px; }
  .mobile-menu nav li {
    text-align: center;
    width: 100%;
    padding: 10px 0;
    margin: 0; }
  .mobile-menu nav:hover ul {
    display: block; }
