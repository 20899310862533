.big-form {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px; }
  .big-form h3 {
    font-size: 19px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: normal; }
  .big-form .description {
    margin-bottom: 20px;
    font-size: 17px !important; }
  .big-form label {
    font-weight: normal;
    margin-bottom: 10px; }
  .big-form .input-group {
    margin-bottom: 20px; }
  .big-form textarea {
    height: 120px; }
