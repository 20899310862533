.nav.nav-tabs.nav-tabs-justified, .nav.nav-tabs.nav-pills-justified, .nav.nav-pills.nav-tabs-justified, .nav.nav-pills.nav-pills-justified {
  display: table;
  width: 100%; }
  .nav.nav-tabs.nav-tabs-justified > li, .nav.nav-tabs.nav-pills-justified > li, .nav.nav-pills.nav-tabs-justified > li, .nav.nav-pills.nav-pills-justified > li {
    float: none;
    display: table-cell; }
    .nav.nav-tabs.nav-tabs-justified > li > a, .nav.nav-tabs.nav-pills-justified > li > a, .nav.nav-pills.nav-tabs-justified > li > a, .nav.nav-pills.nav-pills-justified > li > a {
      text-align: center; }

.tab-content {
  padding: 20px; }

[data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #ffffff;
    color: #424242; }
    [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #ffffff; }
    @media (max-width: 543px) {
      [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #ffffff;
    color: #424242;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #ffffff;
      font-weight: normal; }
      [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="light"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="light"] .nav.nav-pills {
  border: 0; }
  [data-background="light"] .nav.nav-pills .nav-item {
    background: #ffffff;
    color: #424242; }
    [data-background="light"] .nav.nav-pills .nav-item:hover, [data-background="light"] .nav.nav-pills .nav-item.open {
      background: #ffffff; }
    @media (max-width: 543px) {
      [data-background="light"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="light"] .nav.nav-pills .nav-link {
    border: 0;
    background: #ffffff;
    color: #424242;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="light"] .nav.nav-pills .nav-link:hover, [data-background="light"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="light"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="light"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="light"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="light"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="light"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="light"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="light"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="light"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="light"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="light"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="light"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="light"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="light"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="light"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="light"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="light"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="light"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="light"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="light"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="light"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #424242;
    color: #ffffff; }
    [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #424242; }
    @media (max-width: 543px) {
      [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #424242;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #424242;
      font-weight: normal; }
      [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="dark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="dark"] .nav.nav-pills {
  border: 0; }
  [data-background="dark"] .nav.nav-pills .nav-item {
    background: #424242;
    color: #ffffff; }
    [data-background="dark"] .nav.nav-pills .nav-item:hover, [data-background="dark"] .nav.nav-pills .nav-item.open {
      background: #424242; }
    @media (max-width: 543px) {
      [data-background="dark"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="dark"] .nav.nav-pills .nav-link {
    border: 0;
    background: #424242;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="dark"] .nav.nav-pills .nav-link:hover, [data-background="dark"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="dark"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="dark"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="dark"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="dark"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="dark"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="dark"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="dark"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="dark"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="dark"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="dark"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="dark"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="dark"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="dark"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="dark"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="dark"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="dark"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="dark"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="dark"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="dark"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="dark"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #424242;
    color: #ffffff; }
    [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #424242; }
    @media (max-width: 543px) {
      [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #424242;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #424242;
      font-weight: normal; }
      [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="sgbc"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="sgbc"] .nav.nav-pills {
  border: 0; }
  [data-background="sgbc"] .nav.nav-pills .nav-item {
    background: #424242;
    color: #ffffff; }
    [data-background="sgbc"] .nav.nav-pills .nav-item:hover, [data-background="sgbc"] .nav.nav-pills .nav-item.open {
      background: #424242; }
    @media (max-width: 543px) {
      [data-background="sgbc"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="sgbc"] .nav.nav-pills .nav-link {
    border: 0;
    background: #424242;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="sgbc"] .nav.nav-pills .nav-link:hover, [data-background="sgbc"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="sgbc"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="sgbc"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="sgbc"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #303f9f;
    color: #ffffff; }
    [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #303f9f; }
    @media (max-width: 543px) {
      [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #303f9f;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #303f9f;
      font-weight: normal; }
      [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="primary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="primary"] .nav.nav-pills {
  border: 0; }
  [data-background="primary"] .nav.nav-pills .nav-item {
    background: #303f9f;
    color: #ffffff; }
    [data-background="primary"] .nav.nav-pills .nav-item:hover, [data-background="primary"] .nav.nav-pills .nav-item.open {
      background: #303f9f; }
    @media (max-width: 543px) {
      [data-background="primary"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="primary"] .nav.nav-pills .nav-link {
    border: 0;
    background: #303f9f;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="primary"] .nav.nav-pills .nav-link:hover, [data-background="primary"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="primary"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="primary"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="primary"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="primary"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="primary"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="primary"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="primary"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="primary"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="primary"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="primary"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="primary"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="primary"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="primary"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="primary"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="primary"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="primary"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="primary"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="primary"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="primary"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="primary"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #7b1fa2;
    color: #ffffff; }
    [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #7b1fa2; }
    @media (max-width: 543px) {
      [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #7b1fa2;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #7b1fa2;
      font-weight: normal; }
      [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="secondary"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="secondary"] .nav.nav-pills {
  border: 0; }
  [data-background="secondary"] .nav.nav-pills .nav-item {
    background: #7b1fa2;
    color: #ffffff; }
    [data-background="secondary"] .nav.nav-pills .nav-item:hover, [data-background="secondary"] .nav.nav-pills .nav-item.open {
      background: #7b1fa2; }
    @media (max-width: 543px) {
      [data-background="secondary"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="secondary"] .nav.nav-pills .nav-link {
    border: 0;
    background: #7b1fa2;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="secondary"] .nav.nav-pills .nav-link:hover, [data-background="secondary"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="secondary"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="secondary"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="secondary"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #0288d1;
    color: #ffffff; }
    [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #0288d1; }
    @media (max-width: 543px) {
      [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #0288d1;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #0288d1;
      font-weight: normal; }
      [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="info"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="info"] .nav.nav-pills {
  border: 0; }
  [data-background="info"] .nav.nav-pills .nav-item {
    background: #0288d1;
    color: #ffffff; }
    [data-background="info"] .nav.nav-pills .nav-item:hover, [data-background="info"] .nav.nav-pills .nav-item.open {
      background: #0288d1; }
    @media (max-width: 543px) {
      [data-background="info"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="info"] .nav.nav-pills .nav-link {
    border: 0;
    background: #0288d1;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="info"] .nav.nav-pills .nav-link:hover, [data-background="info"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="info"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="info"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="info"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="info"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="info"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="info"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="info"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="info"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="info"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="info"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="info"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="info"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="info"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="info"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="info"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="info"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="info"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="info"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="info"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="info"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #388e3c;
    color: #ffffff; }
    [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #388e3c; }
    @media (max-width: 543px) {
      [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #388e3c;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #388e3c;
      font-weight: normal; }
      [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="success"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="success"] .nav.nav-pills {
  border: 0; }
  [data-background="success"] .nav.nav-pills .nav-item {
    background: #388e3c;
    color: #ffffff; }
    [data-background="success"] .nav.nav-pills .nav-item:hover, [data-background="success"] .nav.nav-pills .nav-item.open {
      background: #388e3c; }
    @media (max-width: 543px) {
      [data-background="success"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="success"] .nav.nav-pills .nav-link {
    border: 0;
    background: #388e3c;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="success"] .nav.nav-pills .nav-link:hover, [data-background="success"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="success"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="success"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="success"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="success"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="success"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="success"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="success"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="success"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="success"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="success"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="success"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="success"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="success"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="success"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="success"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="success"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="success"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="success"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="success"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="success"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #ffa000;
    color: #ffffff; }
    [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #ffa000; }
    @media (max-width: 543px) {
      [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #ffa000;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #ffa000;
      font-weight: normal; }
      [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="warning"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="warning"] .nav.nav-pills {
  border: 0; }
  [data-background="warning"] .nav.nav-pills .nav-item {
    background: #ffa000;
    color: #ffffff; }
    [data-background="warning"] .nav.nav-pills .nav-item:hover, [data-background="warning"] .nav.nav-pills .nav-item.open {
      background: #ffa000; }
    @media (max-width: 543px) {
      [data-background="warning"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="warning"] .nav.nav-pills .nav-link {
    border: 0;
    background: #ffa000;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="warning"] .nav.nav-pills .nav-link:hover, [data-background="warning"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="warning"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="warning"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="warning"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="warning"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="warning"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="warning"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="warning"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="warning"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="warning"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="warning"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="warning"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="warning"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="warning"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="warning"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="warning"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="warning"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="warning"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="warning"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="warning"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="warning"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #d32f2f;
    color: #ffffff; }
    [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #d32f2f; }
    @media (max-width: 543px) {
      [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #d32f2f;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #d32f2f;
      font-weight: normal; }
      [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="danger"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="danger"] .nav.nav-pills {
  border: 0; }
  [data-background="danger"] .nav.nav-pills .nav-item {
    background: #d32f2f;
    color: #ffffff; }
    [data-background="danger"] .nav.nav-pills .nav-item:hover, [data-background="danger"] .nav.nav-pills .nav-item.open {
      background: #d32f2f; }
    @media (max-width: 543px) {
      [data-background="danger"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="danger"] .nav.nav-pills .nav-link {
    border: 0;
    background: #d32f2f;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="danger"] .nav.nav-pills .nav-link:hover, [data-background="danger"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="danger"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="danger"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="danger"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="danger"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="danger"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="danger"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="danger"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="danger"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="danger"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="danger"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="danger"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="danger"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="danger"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="danger"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="danger"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="danger"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="danger"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="danger"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="danger"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="danger"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #5d7e3d;
    color: #ffffff; }
    [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #5d7e3d; }
    @media (max-width: 543px) {
      [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #5d7e3d;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #5d7e3d;
      font-weight: normal; }
      [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="green"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="green"] .nav.nav-pills {
  border: 0; }
  [data-background="green"] .nav.nav-pills .nav-item {
    background: #5d7e3d;
    color: #ffffff; }
    [data-background="green"] .nav.nav-pills .nav-item:hover, [data-background="green"] .nav.nav-pills .nav-item.open {
      background: #5d7e3d; }
    @media (max-width: 543px) {
      [data-background="green"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="green"] .nav.nav-pills .nav-link {
    border: 0;
    background: #5d7e3d;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="green"] .nav.nav-pills .nav-link:hover, [data-background="green"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="green"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="green"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="green"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="green"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="green"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="green"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="green"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="green"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="green"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="green"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="green"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="green"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="green"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="green"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="green"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="green"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="green"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="green"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="green"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="green"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }

[data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) {
  border: 0; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
    background: #313b3d;
    color: #ffffff; }
    [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item:hover, [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item.open {
      background: #313b3d; }
    @media (max-width: 543px) {
      [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-item {
        display: block;
        float: none; } }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link {
    border: 0;
    background: #313b3d;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:after {
      content: "";
      display: block;
      visibility: hidden;
      position: absolute;
      bottom: -3px;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background-color: #303f9f;
      z-index: 2; }
    [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover, [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active {
      background: #313b3d;
      font-weight: normal; }
      [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link:hover:after, [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.active:after {
        display: block;
        visibility: visible; }
    [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical) .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-dark .nav-link:after {
    background-color: #424242; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-grey .nav-link:after {
    background-color: #63666a; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-sgbc .nav-link:after {
    background-color: #63666a; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-default .nav-link:after {
    background-color: #212121; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-green .nav-link:after {
    background-color: #5d7e3d; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1 .nav-link:after {
    background-color: #027ec1; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-2 .nav-link:after {
    background-color: #2bbed3; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1 .nav-link:after {
    background-color: #439b58; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2 .nav-link:after {
    background-color: #21401c; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1 .nav-link:after {
    background-color: #ffab1a; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1 .nav-link:after {
    background-color: #ec5a71; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-yellow-1 .nav-link:after {
    background-color: #ffd351; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-blue-1-darken .nav-link:after {
    background-color: #014d75; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-1-darken .nav-link:after {
    background-color: #2c663a; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-green-2-darken .nav-link:after {
    background-color: #060b05; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-orange-1-darken .nav-link:after {
    background-color: #cd8100; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile-darken .nav-link:after {
    background-color: #40a9ab; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-cyder-pink-1-darken .nav-link:after {
    background-color: #e01a39; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-newdark .nav-link:after {
    background-color: #313b3d; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lightblue .nav-link:after {
    background-color: #78adc9; }
  [data-background="c-newdark"] .nav.nav-tabs:not(.nav-pills):not(.nav-vertical).nav-tabs-c-lighttile .nav-link:after {
    background-color: #6fc7c9; }

[data-background="c-newdark"] .nav.nav-pills {
  border: 0; }
  [data-background="c-newdark"] .nav.nav-pills .nav-item {
    background: #313b3d;
    color: #ffffff; }
    [data-background="c-newdark"] .nav.nav-pills .nav-item:hover, [data-background="c-newdark"] .nav.nav-pills .nav-item.open {
      background: #313b3d; }
    @media (max-width: 543px) {
      [data-background="c-newdark"] .nav.nav-pills .nav-item {
        display: block;
        float: none; } }
  [data-background="c-newdark"] .nav.nav-pills .nav-link {
    border: 0;
    background: #313b3d;
    color: #ffffff;
    position: relative;
    padding: 10px 20px;
    font-size: 14px; }
    [data-background="c-newdark"] .nav.nav-pills .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills .nav-link.active {
      background-color: #303f9f;
      color: #fff;
      font-weight: normal; }
    [data-background="c-newdark"] .nav.nav-pills .nav-link.disabled {
      cursor: none;
      pointer-events: none;
      opacity: 0.5; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-rounded .nav-link {
    border-radius: 12px; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-light .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-light .nav-link.active {
    background-color: #ffffff; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-dark .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-dark .nav-link.active {
    background-color: #424242; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-grey .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-grey .nav-link.active {
    background-color: #63666a; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-sgbc .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-sgbc .nav-link.active {
    background-color: #63666a; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-default .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-default .nav-link.active {
    background-color: #212121; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-primary .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-primary .nav-link.active {
    background-color: #303f9f; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-secondary .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-secondary .nav-link.active {
    background-color: #7b1fa2; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-info .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-info .nav-link.active {
    background-color: #0288d1; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-success .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-success .nav-link.active {
    background-color: #388e3c; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-warning .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-warning .nav-link.active {
    background-color: #ffa000; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-danger .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-danger .nav-link.active {
    background-color: #d32f2f; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-green .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-green .nav-link.active {
    background-color: #5d7e3d; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-blue-1 .nav-link.active {
    background-color: #027ec1; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-blue-2 .nav-link.active {
    background-color: #2bbed3; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-1 .nav-link.active {
    background-color: #439b58; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-2 .nav-link.active {
    background-color: #21401c; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-orange-1 .nav-link.active {
    background-color: #ffab1a; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-pink-1 .nav-link.active {
    background-color: #ec5a71; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-yellow-1 .nav-link.active {
    background-color: #ffd351; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-blue-1-darken .nav-link.active {
    background-color: #014d75; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-1-darken .nav-link.active {
    background-color: #2c663a; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-green-2-darken .nav-link.active {
    background-color: #060b05; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-orange-1-darken .nav-link.active {
    background-color: #cd8100; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-lighttile-darken .nav-link.active {
    background-color: #40a9ab; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-cyder-pink-1-darken .nav-link.active {
    background-color: #e01a39; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-newdark .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-newdark .nav-link.active {
    background-color: #313b3d; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-lightblue .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-lightblue .nav-link.active {
    background-color: #78adc9; }
  [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-lighttile .nav-link:hover, [data-background="c-newdark"] .nav.nav-pills.nav-pills-c-lighttile .nav-link.active {
    background-color: #6fc7c9; }
