@import 'colors';
@import 'palettes/default';
@import 'animations';

//fonts
$font-family: 'Roboto', sans-serif;
$monospace-font-family: 'Inconsolata', monospace;
$segoe: 'Segoe UI', Helvetica, Arial, sans-serif;

//z-index
$zindex-navbar-fixed: 1030;
$zindex-navbar: $zindex-navbar-fixed;
$zindex-left-sidebar-placeholder: $zindex-navbar - 2;
$zindex-left-sidebar-backdrop: $zindex-navbar - 2;
$zindex-left-sidebar: $zindex-navbar - 1;
$zindex-right-sidebar-backdrop: $zindex-navbar + 5;
$zindex-right-sidebar: $zindex-navbar + 6;

//navbar
$navbar-height: 70px;

//navbar
$top-navigation-height: 50px;

//left sidebar
$left-sidebar-width: 220px;
$left-sidebar-section-title-height: 50px;
$left-sidebar-item-height: 45px;
$left-sidebar-collapsed-width: 80px;
$left-sidebar-collapsed-item-height: $navbar-height;
$right-sidebar-width: 220px;

//logo
$logo-width: $left-sidebar-width;

//global variables
$side-margin: 20px;
$side-margin-top: 0px;
$side-margin-left: 8px;
$margin-left: 20px;
$margin-right: 20px;
$margin-bottom: 20px;
$border-radius: 12px;

//responsive breakpoint
$breakpoint: 768px;

//transform
$default-transform: transform 0.25s ease;
$alt-transform: transform 0.25s ease-in-out;

// SGBC Custom Variable
$dashboard-table-last-column-margin-left: 10px;
$dashboard-table-first-column-font-weight: normal;

$green: #4cc64c;
$red: #ed8a8a;
$darkGrey: #76777b;
$lightGreen: #78be20;
$solidGreen: #317446;
$neonGreen: #bed431;

$cardBoxShadow: 0 12px 15px 0 rgba(0, 0, 0, 0.1), 0 17px 50px 0 rgba(0, 0, 0, 0.1);
