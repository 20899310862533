.navbar .logo {
  height: 70px; }
  .navbar .logo i {
    font-size: 1.75rem;
    margin-right: 15px;
    margin-left: 5px; }
  .navbar .logo .title {
    text-transform: uppercase;
    font-size: 1.75rem;
    font-weight: 300; }

[data-logo="light"] .navbar .logo {
  color: #424242; }

[data-logo="dark"] .navbar .logo {
  color: #ffffff; }

[data-logo="primary"] .navbar .logo {
  color: #ffffff; }

[data-logo="secondary"] .navbar .logo {
  color: #ffffff; }

[data-logo="info"] .navbar .logo {
  color: #ffffff; }

[data-logo="success"] .navbar .logo {
  color: #ffffff; }

[data-logo="warning"] .navbar .logo {
  color: #ffffff; }

[data-logo="danger"] .navbar .logo {
  color: #ffffff; }

[data-logo="green"] .navbar .logo {
  color: #ffffff; }
