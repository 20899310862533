.breadcrumb {
  border-radius: 0;
  white-space: nowrap;
  display: flex;
  height: 50px;
  line-height: 50px;
  padding: 0 10px 0 0;
  margin: 0;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row; }
  .breadcrumb > li {
    padding: 0; }
    .breadcrumb > li:before {
      padding: 0 10px; }
  .breadcrumb.icon-home > li:first-child:before {
    font-family: "simple-line-icons";
    content: "\e069"; }
  .breadcrumb.icon-grid > li:first-child:before {
    font-family: "simple-line-icons";
    content: "\e06a"; }
  .breadcrumb.icon-angle-right > li + li:before {
    font-family: FontAwesome;
    content: "\f105"; }
  .breadcrumb.icon-angle-double-right > li + li:before {
    font-family: FontAwesome;
    content: "\f101"; }

[data-background="light"] .breadcrumb {
  color: #424242;
  background-color: #ffffff; }
  [data-background="light"] .breadcrumb li {
    color: #424242; }
    [data-background="light"] .breadcrumb li a {
      color: #424242; }
  [data-background="light"] .breadcrumb.transparent, [data-background="light"] .breadcrumb-transparent, [data-background="light"] .breadcrumb.no-bg, [data-background="light"] .breadcrumb-no-bg {
    background: transparent; }
  [data-background="light"] .breadcrumb.breadcrumb-light {
    background: #ffffff;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-light li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-light li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-dark {
    background: #424242;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-dark li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-dark li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-grey {
    background: #63666a;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-grey li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-grey li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-sgbc {
    background: #63666a;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-sgbc li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-sgbc li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-default {
    background: #212121;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-default li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-default li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-primary {
    background: #303f9f;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-primary li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-primary li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-secondary {
    background: #7b1fa2;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-secondary li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-secondary li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-info {
    background: #0288d1;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-info li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-info li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-success {
    background: #388e3c;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-success li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-success li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-warning {
    background: #ffa000;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-warning li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-warning li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-danger {
    background: #d32f2f;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-danger li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-danger li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-green {
    background: #5d7e3d;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-green li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-green li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-1 {
    background: #027ec1;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-1 li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-1 li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-2 {
    background: #2bbed3;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-2 li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-2 li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-green-1 {
    background: #439b58;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-green-1 li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-green-1 li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-green-2 {
    background: #21401c;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-green-2 li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-green-2 li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-orange-1 {
    background: #ffab1a;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-orange-1 li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-orange-1 li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-pink-1 {
    background: #ec5a71;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-pink-1 li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-pink-1 li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-yellow-1 {
    background: #ffd351;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-yellow-1 li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-yellow-1 li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-1-darken {
    background: #014d75;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-1-darken li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-blue-1-darken li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-green-1-darken {
    background: #2c663a;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-green-1-darken li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-green-1-darken li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-green-2-darken {
    background: #060b05;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-green-2-darken li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-green-2-darken li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-orange-1-darken {
    background: #cd8100;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-orange-1-darken li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-orange-1-darken li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-c-lighttile-darken {
    background: #40a9ab;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-c-lighttile-darken li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-c-lighttile-darken li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-cyder-pink-1-darken {
    background: #e01a39;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-cyder-pink-1-darken li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-cyder-pink-1-darken li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-c-newdark {
    background: #313b3d;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-c-newdark li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-c-newdark li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-c-lightblue {
    background: #78adc9;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-c-lightblue li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-c-lightblue li a {
        color: #fff; }
  [data-background="light"] .breadcrumb.breadcrumb-c-lighttile {
    background: #6fc7c9;
    color: #fff; }
    [data-background="light"] .breadcrumb.breadcrumb-c-lighttile li {
      color: #fff; }
      [data-background="light"] .breadcrumb.breadcrumb-c-lighttile li a {
        color: #fff; }

[data-background="dark"] .breadcrumb {
  color: #ffffff;
  background-color: #424242; }
  [data-background="dark"] .breadcrumb li {
    color: #ffffff; }
    [data-background="dark"] .breadcrumb li a {
      color: #ffffff; }
  [data-background="dark"] .breadcrumb.transparent, [data-background="dark"] .breadcrumb-transparent, [data-background="dark"] .breadcrumb.no-bg, [data-background="dark"] .breadcrumb-no-bg {
    background: transparent; }
  [data-background="dark"] .breadcrumb.breadcrumb-light {
    background: #ffffff;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-light li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-light li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-dark {
    background: #424242;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-dark li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-dark li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-grey {
    background: #63666a;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-grey li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-grey li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-sgbc {
    background: #63666a;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-sgbc li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-sgbc li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-default {
    background: #212121;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-default li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-default li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-primary {
    background: #303f9f;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-primary li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-primary li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-secondary {
    background: #7b1fa2;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-secondary li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-secondary li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-info {
    background: #0288d1;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-info li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-info li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-success {
    background: #388e3c;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-success li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-success li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-warning {
    background: #ffa000;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-warning li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-warning li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-danger {
    background: #d32f2f;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-danger li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-danger li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-green {
    background: #5d7e3d;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-green li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-green li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-1 {
    background: #027ec1;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-1 li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-1 li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-2 {
    background: #2bbed3;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-2 li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-2 li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-1 {
    background: #439b58;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-1 li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-1 li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-2 {
    background: #21401c;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-2 li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-2 li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-orange-1 {
    background: #ffab1a;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-orange-1 li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-orange-1 li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-pink-1 {
    background: #ec5a71;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-pink-1 li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-pink-1 li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-yellow-1 {
    background: #ffd351;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-yellow-1 li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-yellow-1 li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-1-darken {
    background: #014d75;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-1-darken li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-blue-1-darken li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-1-darken {
    background: #2c663a;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-1-darken li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-1-darken li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-2-darken {
    background: #060b05;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-2-darken li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-green-2-darken li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-orange-1-darken {
    background: #cd8100;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-orange-1-darken li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-orange-1-darken li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-c-lighttile-darken {
    background: #40a9ab;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-c-lighttile-darken li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-c-lighttile-darken li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-cyder-pink-1-darken {
    background: #e01a39;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-cyder-pink-1-darken li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-cyder-pink-1-darken li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-c-newdark {
    background: #313b3d;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-c-newdark li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-c-newdark li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-c-lightblue {
    background: #78adc9;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-c-lightblue li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-c-lightblue li a {
        color: #fff; }
  [data-background="dark"] .breadcrumb.breadcrumb-c-lighttile {
    background: #6fc7c9;
    color: #fff; }
    [data-background="dark"] .breadcrumb.breadcrumb-c-lighttile li {
      color: #fff; }
      [data-background="dark"] .breadcrumb.breadcrumb-c-lighttile li a {
        color: #fff; }

[data-background="primary"] .breadcrumb {
  color: #ffffff;
  background-color: #303f9f; }
  [data-background="primary"] .breadcrumb li {
    color: #ffffff; }
    [data-background="primary"] .breadcrumb li a {
      color: #ffffff; }
  [data-background="primary"] .breadcrumb.transparent, [data-background="primary"] .breadcrumb-transparent, [data-background="primary"] .breadcrumb.no-bg, [data-background="primary"] .breadcrumb-no-bg {
    background: transparent; }
  [data-background="primary"] .breadcrumb.breadcrumb-light {
    background: #ffffff;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-light li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-light li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-dark {
    background: #424242;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-dark li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-dark li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-grey {
    background: #63666a;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-grey li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-grey li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-sgbc {
    background: #63666a;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-sgbc li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-sgbc li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-default {
    background: #212121;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-default li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-default li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-primary {
    background: #303f9f;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-primary li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-primary li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-secondary {
    background: #7b1fa2;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-secondary li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-secondary li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-info {
    background: #0288d1;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-info li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-info li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-success {
    background: #388e3c;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-success li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-success li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-warning {
    background: #ffa000;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-warning li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-warning li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-danger {
    background: #d32f2f;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-danger li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-danger li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-green {
    background: #5d7e3d;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-green li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-green li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-1 {
    background: #027ec1;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-1 li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-1 li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-2 {
    background: #2bbed3;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-2 li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-2 li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-1 {
    background: #439b58;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-1 li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-1 li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-2 {
    background: #21401c;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-2 li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-2 li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-orange-1 {
    background: #ffab1a;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-orange-1 li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-orange-1 li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-pink-1 {
    background: #ec5a71;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-pink-1 li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-pink-1 li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-yellow-1 {
    background: #ffd351;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-yellow-1 li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-yellow-1 li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-1-darken {
    background: #014d75;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-1-darken li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-blue-1-darken li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-1-darken {
    background: #2c663a;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-1-darken li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-1-darken li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-2-darken {
    background: #060b05;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-2-darken li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-green-2-darken li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-orange-1-darken {
    background: #cd8100;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-orange-1-darken li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-orange-1-darken li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-c-lighttile-darken {
    background: #40a9ab;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-c-lighttile-darken li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-c-lighttile-darken li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-cyder-pink-1-darken {
    background: #e01a39;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-cyder-pink-1-darken li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-cyder-pink-1-darken li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-c-newdark {
    background: #313b3d;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-c-newdark li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-c-newdark li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-c-lightblue {
    background: #78adc9;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-c-lightblue li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-c-lightblue li a {
        color: #fff; }
  [data-background="primary"] .breadcrumb.breadcrumb-c-lighttile {
    background: #6fc7c9;
    color: #fff; }
    [data-background="primary"] .breadcrumb.breadcrumb-c-lighttile li {
      color: #fff; }
      [data-background="primary"] .breadcrumb.breadcrumb-c-lighttile li a {
        color: #fff; }
