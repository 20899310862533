.page-widget {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  font-size: 900; }
  .page-widget .row {
    margin-bottom: 0px; }
  .page-widget .lastrow {
    margin-bottom: 0; }
  .page-widget .title {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
    color: #616161; }
  .page-widget .description {
    margin-bottom: 0px;
    font-size: 0.875rem;
    font-weight: normal;
    color: #616161; }
  .page-widget .dashboard-widget .right-icons > i {
    color: #616161;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 1.125rem; }

.sgbcdashboarwidget-label {
  font-size: 16px; }

.empty-page-widget .number-label-danger {
  color: #d32f2f;
  font-weight: 400;
  font-size: 30px; }

.empty-page-widget .number-label-info {
  color: #0288d1;
  font-weight: 400;
  font-size: 30px; }

.empty-page-widget .number-label-success {
  color: #388e3c;
  font-weight: 400;
  font-size: 30px; }

.empty-page-widget .number-label-warning {
  color: #ffa000;
  font-weight: 400;
  font-size: 30px; }
