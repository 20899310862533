a,
.btn {
  font-family: "Roboto", sans-serif; }
  a:hover, a:focus, a:active, a.focus, a.active,
  .btn:hover,
  .btn:focus,
  .btn:active,
  .btn.focus,
  .btn.active {
    cursor: pointer;
    outline: none !important;
    text-decoration: none !important;
    box-shadow: none !important; }

.blockquote.blockquote-light:not(.blockquote-reverse) {
  border-left: 0.25rem solid #ffffff;
  padding-left: 20px; }

.blockquote.blockquote-light.blockquote-reverse {
  border-right: 0.25rem solid #ffffff;
  padding-right: 20px; }

.blockquote.blockquote-dark:not(.blockquote-reverse) {
  border-left: 0.25rem solid #424242;
  padding-left: 20px; }

.blockquote.blockquote-dark.blockquote-reverse {
  border-right: 0.25rem solid #424242;
  padding-right: 20px; }

.blockquote.blockquote-grey:not(.blockquote-reverse) {
  border-left: 0.25rem solid #63666a;
  padding-left: 20px; }

.blockquote.blockquote-grey.blockquote-reverse {
  border-right: 0.25rem solid #63666a;
  padding-right: 20px; }

.blockquote.blockquote-sgbc:not(.blockquote-reverse) {
  border-left: 0.25rem solid #63666a;
  padding-left: 20px; }

.blockquote.blockquote-sgbc.blockquote-reverse {
  border-right: 0.25rem solid #63666a;
  padding-right: 20px; }

.blockquote.blockquote-default:not(.blockquote-reverse) {
  border-left: 0.25rem solid #212121;
  padding-left: 20px; }

.blockquote.blockquote-default.blockquote-reverse {
  border-right: 0.25rem solid #212121;
  padding-right: 20px; }

.blockquote.blockquote-primary:not(.blockquote-reverse) {
  border-left: 0.25rem solid #303f9f;
  padding-left: 20px; }

.blockquote.blockquote-primary.blockquote-reverse {
  border-right: 0.25rem solid #303f9f;
  padding-right: 20px; }

.blockquote.blockquote-secondary:not(.blockquote-reverse) {
  border-left: 0.25rem solid #7b1fa2;
  padding-left: 20px; }

.blockquote.blockquote-secondary.blockquote-reverse {
  border-right: 0.25rem solid #7b1fa2;
  padding-right: 20px; }

.blockquote.blockquote-info:not(.blockquote-reverse) {
  border-left: 0.25rem solid #0288d1;
  padding-left: 20px; }

.blockquote.blockquote-info.blockquote-reverse {
  border-right: 0.25rem solid #0288d1;
  padding-right: 20px; }

.blockquote.blockquote-success:not(.blockquote-reverse) {
  border-left: 0.25rem solid #388e3c;
  padding-left: 20px; }

.blockquote.blockquote-success.blockquote-reverse {
  border-right: 0.25rem solid #388e3c;
  padding-right: 20px; }

.blockquote.blockquote-warning:not(.blockquote-reverse) {
  border-left: 0.25rem solid #ffa000;
  padding-left: 20px; }

.blockquote.blockquote-warning.blockquote-reverse {
  border-right: 0.25rem solid #ffa000;
  padding-right: 20px; }

.blockquote.blockquote-danger:not(.blockquote-reverse) {
  border-left: 0.25rem solid #d32f2f;
  padding-left: 20px; }

.blockquote.blockquote-danger.blockquote-reverse {
  border-right: 0.25rem solid #d32f2f;
  padding-right: 20px; }

.blockquote.blockquote-green:not(.blockquote-reverse) {
  border-left: 0.25rem solid #5d7e3d;
  padding-left: 20px; }

.blockquote.blockquote-green.blockquote-reverse {
  border-right: 0.25rem solid #5d7e3d;
  padding-right: 20px; }

.blockquote.blockquote-cyder-blue-1:not(.blockquote-reverse) {
  border-left: 0.25rem solid #027ec1;
  padding-left: 20px; }

.blockquote.blockquote-cyder-blue-1.blockquote-reverse {
  border-right: 0.25rem solid #027ec1;
  padding-right: 20px; }

.blockquote.blockquote-cyder-blue-2:not(.blockquote-reverse) {
  border-left: 0.25rem solid #2bbed3;
  padding-left: 20px; }

.blockquote.blockquote-cyder-blue-2.blockquote-reverse {
  border-right: 0.25rem solid #2bbed3;
  padding-right: 20px; }

.blockquote.blockquote-cyder-green-1:not(.blockquote-reverse) {
  border-left: 0.25rem solid #439b58;
  padding-left: 20px; }

.blockquote.blockquote-cyder-green-1.blockquote-reverse {
  border-right: 0.25rem solid #439b58;
  padding-right: 20px; }

.blockquote.blockquote-cyder-green-2:not(.blockquote-reverse) {
  border-left: 0.25rem solid #21401c;
  padding-left: 20px; }

.blockquote.blockquote-cyder-green-2.blockquote-reverse {
  border-right: 0.25rem solid #21401c;
  padding-right: 20px; }

.blockquote.blockquote-cyder-orange-1:not(.blockquote-reverse) {
  border-left: 0.25rem solid #ffab1a;
  padding-left: 20px; }

.blockquote.blockquote-cyder-orange-1.blockquote-reverse {
  border-right: 0.25rem solid #ffab1a;
  padding-right: 20px; }

.blockquote.blockquote-cyder-pink-1:not(.blockquote-reverse) {
  border-left: 0.25rem solid #ec5a71;
  padding-left: 20px; }

.blockquote.blockquote-cyder-pink-1.blockquote-reverse {
  border-right: 0.25rem solid #ec5a71;
  padding-right: 20px; }

.blockquote.blockquote-cyder-yellow-1:not(.blockquote-reverse) {
  border-left: 0.25rem solid #ffd351;
  padding-left: 20px; }

.blockquote.blockquote-cyder-yellow-1.blockquote-reverse {
  border-right: 0.25rem solid #ffd351;
  padding-right: 20px; }

.blockquote.blockquote-cyder-blue-1-darken:not(.blockquote-reverse) {
  border-left: 0.25rem solid #014d75;
  padding-left: 20px; }

.blockquote.blockquote-cyder-blue-1-darken.blockquote-reverse {
  border-right: 0.25rem solid #014d75;
  padding-right: 20px; }

.blockquote.blockquote-cyder-green-1-darken:not(.blockquote-reverse) {
  border-left: 0.25rem solid #2c663a;
  padding-left: 20px; }

.blockquote.blockquote-cyder-green-1-darken.blockquote-reverse {
  border-right: 0.25rem solid #2c663a;
  padding-right: 20px; }

.blockquote.blockquote-cyder-green-2-darken:not(.blockquote-reverse) {
  border-left: 0.25rem solid #060b05;
  padding-left: 20px; }

.blockquote.blockquote-cyder-green-2-darken.blockquote-reverse {
  border-right: 0.25rem solid #060b05;
  padding-right: 20px; }

.blockquote.blockquote-cyder-orange-1-darken:not(.blockquote-reverse) {
  border-left: 0.25rem solid #cd8100;
  padding-left: 20px; }

.blockquote.blockquote-cyder-orange-1-darken.blockquote-reverse {
  border-right: 0.25rem solid #cd8100;
  padding-right: 20px; }

.blockquote.blockquote-c-lighttile-darken:not(.blockquote-reverse) {
  border-left: 0.25rem solid #40a9ab;
  padding-left: 20px; }

.blockquote.blockquote-c-lighttile-darken.blockquote-reverse {
  border-right: 0.25rem solid #40a9ab;
  padding-right: 20px; }

.blockquote.blockquote-cyder-pink-1-darken:not(.blockquote-reverse) {
  border-left: 0.25rem solid #e01a39;
  padding-left: 20px; }

.blockquote.blockquote-cyder-pink-1-darken.blockquote-reverse {
  border-right: 0.25rem solid #e01a39;
  padding-right: 20px; }

.blockquote.blockquote-c-newdark:not(.blockquote-reverse) {
  border-left: 0.25rem solid #313b3d;
  padding-left: 20px; }

.blockquote.blockquote-c-newdark.blockquote-reverse {
  border-right: 0.25rem solid #313b3d;
  padding-right: 20px; }

.blockquote.blockquote-c-lightblue:not(.blockquote-reverse) {
  border-left: 0.25rem solid #78adc9;
  padding-left: 20px; }

.blockquote.blockquote-c-lightblue.blockquote-reverse {
  border-right: 0.25rem solid #78adc9;
  padding-right: 20px; }

.blockquote.blockquote-c-lighttile:not(.blockquote-reverse) {
  border-left: 0.25rem solid #6fc7c9;
  padding-left: 20px; }

.blockquote.blockquote-c-lighttile.blockquote-reverse {
  border-right: 0.25rem solid #6fc7c9;
  padding-right: 20px; }

[data-background='light'] pre,
[data-background='light'] code {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  background: #f7f7f7;
  color: #d32f2f; }

[data-background='dark'] pre,
[data-background='dark'] code {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  background: #4a4a4a;
  color: #ffa000; }

[data-background='primary'] pre,
[data-background='primary'] code {
  font-family: "Inconsolata", monospace;
  font-size: 0.875rem;
  background: #3444ab;
  color: #ffa000; }
