.sgbcdashboarwidget {
  width: 100%;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #ffffff;
  border-radius: 4px;
  -webkit-box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  font-size: 900; }
  .sgbcdashboarwidget .row {
    margin-bottom: 0px; }
  .sgbcdashboarwidget .lastrow {
    margin-bottom: 0; }
  .sgbcdashboarwidget .title {
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
    color: #616161; }
  .sgbcdashboarwidget .description {
    margin-bottom: 0px;
    font-size: 0.875rem;
    font-weight: normal;
    color: #616161; }
  .sgbcdashboarwidget .dashboard-widget .right-icons > i {
    color: #616161;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 1.125rem; }

.sgbcdashboarwidget-label {
  font-size: 16px; }

.sgbcdashboarwidget-header-undefined {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  background-color: #439b58; }

.sgbcdashboarwidget-header-yellow {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  background-color: #FDBF31; }

.sgbcdashboarwidget-header-blue {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  background-color: #34C0D5; }

.sgbcdashboarwidget-header-icon {
  padding-left: 10px !important;
  padding-top: 10px !important;
  font-size: 20px !important;
  line-height: 20px !important; }
