.cyder-form {
  background-color: rgba(240, 240, 240, 0.95);
  padding-top: 10px;
  padding-right: 40px;
  padding-bottom: 20px;
  padding-left: 40px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .cyder-form h3 {
    font-size: 1.0625rem;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: normal; }
  .cyder-form description {
    margin-bottom: 20px;
    font-size: 0.9375rem; }
  .cyder-form label {
    font-weight: normal;
    margin-bottom: 10px; }
  .cyder-form input-group {
    margin-bottom: 20px; }
  .cyder-form textarea {
    height: 120px; }
