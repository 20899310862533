.card--hasShadow,
.exploreHome-mostPopularRow .nav-button,
.flatpickr-calendar {
    -webkit-box-shadow: 0 0 2px 0 rgba(46, 62, 72, 0.12), 0 2px 4px 0 rgba(46, 62, 72, 0.12);
    box-shadow: 0 0 2px 0 rgba(46, 62, 72, 0.12), 0 2px 4px 0 rgba(46, 62, 72, 0.12);
}
.exploreHome-eventCard {
    border: 0;
    height: 356px;
    margin-bottom: 8px;
    padding: 0;
    /* width: 330px; */
}
.card {
    position: static;
}
.card {
    background: #fff;
    background-clip: padding-box;
    background-size: cover;
    border-radius: 8px;
    box-sizing: border-box;
    display: block;
    white-space: normal;
}
.exploreHome-eventCard .eventCard-image {
    background-position: 50%;
    background-size: cover;
    border-radius: 8px 8px 0 0;
    height: 175px;
    position: relative;
}
.span--100 {
    width: 100% !important;
}
.exploreHome-eventCard .eventCard-content {
    padding-bottom: 16px;
    padding-right: 24px;
}
.exploreHome-eventCard .eventCard-content .eventCard-dateTime {
    line-height: 1em;
    padding-top: 16px;
}

.exploreHome-eventCard .event-image {
    height: 260px;
    overflow-y: hidden;
}

.exploreHome-eventCard .event-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.notification--read,
.notification--read svg,
.text--caption,
.text--caption svg,
.text--secondary,
.text--secondary svg {
    fill: rgba(46, 62, 72, 0.6);
    stroke: transparent;
}
.notification--read,
.text--caption,
.text--secondary {
    color: rgba(46, 62, 72, 0.6);
}
.text--ellipsisOneLine {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.padding--left {
    padding-left: 18px;
}

.exploreHome-eventCard .eventCard-content .eventCard-title {
    /* height: 2.9em; */
    margin-top: 16px;
    line-height: 1 !important;
}
.text--sectionTitle {
    font-size: 20px;
    line-height: 1.45;
}
.text--big,
.text--big svg,
.text--display,
.text--display2,
.text--display2 svg,
.text--display3,
.text--display3 svg,
.text--display svg,
.text--pageTitle,
.text--pageTitle svg,
.text--sectionTitle,
.text--sectionTitle svg,
big,
big svg {
    fill: #2e3e48;
    stroke: transparent;
}
.text--big,
.text--display,
.text--display2,
.text--display3,
.text--pageTitle,
.text--sectionTitle,
big {
    color: #2e3e48;
    font-weight: 600;
    line-height: 1.1;
}
.text--ellipsisThreeLines,
.text--ellipsisTwoLines {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text--ellipsisTwoLines {
    -webkit-line-clamp: 2;
    line-height: 1.45 !important;
    max-height: 2.8em;
    white-space: normal;
}
.text--ellipsisTwoLines {
    -webkit-line-clamp: 3;
    line-height: 1.45 !important;
    max-height: 3.8em;
    white-space: normal;
}
