.alert p:last-child {
  margin-bottom: 0; }

.alert.alert-light {
  color: #fff;
  background-color: #ffffff;
  border-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-light .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-light .close {
    outline: none; }
  .alert.alert-light.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-light.alert-outline .close {
      color: #ffffff;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-light.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #ffffff;
    color: #ffffff;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-light.alert-bordered .close {
      color: #ffffff;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-dark {
  color: #fff;
  background-color: #424242;
  border-color: #424242;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-dark .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-dark .close {
    outline: none; }
  .alert.alert-dark.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #424242;
    color: #424242;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-dark.alert-outline .close {
      color: #424242;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-dark.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #424242;
    color: #424242;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-dark.alert-bordered .close {
      color: #424242;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-grey {
  color: #fff;
  background-color: #63666a;
  border-color: #63666a;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-grey .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-grey .close {
    outline: none; }
  .alert.alert-grey.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #63666a;
    color: #63666a;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-grey.alert-outline .close {
      color: #63666a;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-grey.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #63666a;
    color: #63666a;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-grey.alert-bordered .close {
      color: #63666a;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-sgbc {
  color: #fff;
  background-color: #63666a;
  border-color: #63666a;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-sgbc .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-sgbc .close {
    outline: none; }
  .alert.alert-sgbc.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #63666a;
    color: #63666a;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-sgbc.alert-outline .close {
      color: #63666a;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-sgbc.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #63666a;
    color: #63666a;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-sgbc.alert-bordered .close {
      color: #63666a;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-default {
  color: #fff;
  background-color: #212121;
  border-color: #212121;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-default .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-default .close {
    outline: none; }
  .alert.alert-default.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #212121;
    color: #212121;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-default.alert-outline .close {
      color: #212121;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-default.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #212121;
    color: #212121;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-default.alert-bordered .close {
      color: #212121;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-primary {
  color: #fff;
  background-color: #303f9f;
  border-color: #303f9f;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-primary .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-primary .close {
    outline: none; }
  .alert.alert-primary.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #303f9f;
    color: #303f9f;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-primary.alert-outline .close {
      color: #303f9f;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-primary.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #303f9f;
    color: #303f9f;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-primary.alert-bordered .close {
      color: #303f9f;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-secondary {
  color: #fff;
  background-color: #7b1fa2;
  border-color: #7b1fa2;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-secondary .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-secondary .close {
    outline: none; }
  .alert.alert-secondary.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #7b1fa2;
    color: #7b1fa2;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-secondary.alert-outline .close {
      color: #7b1fa2;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-secondary.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #7b1fa2;
    color: #7b1fa2;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-secondary.alert-bordered .close {
      color: #7b1fa2;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-info {
  color: #fff;
  background-color: #0288d1;
  border-color: #0288d1;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-info .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-info .close {
    outline: none; }
  .alert.alert-info.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #0288d1;
    color: #0288d1;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-info.alert-outline .close {
      color: #0288d1;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-info.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #0288d1;
    color: #0288d1;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-info.alert-bordered .close {
      color: #0288d1;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-success {
  color: #fff;
  background-color: #388e3c;
  border-color: #388e3c;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-success .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-success .close {
    outline: none; }
  .alert.alert-success.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #388e3c;
    color: #388e3c;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-success.alert-outline .close {
      color: #388e3c;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-success.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #388e3c;
    color: #388e3c;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-success.alert-bordered .close {
      color: #388e3c;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-warning {
  color: #fff;
  background-color: #ffa000;
  border-color: #ffa000;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-warning .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-warning .close {
    outline: none; }
  .alert.alert-warning.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #ffa000;
    color: #ffa000;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-warning.alert-outline .close {
      color: #ffa000;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-warning.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #ffa000;
    color: #ffa000;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-warning.alert-bordered .close {
      color: #ffa000;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-danger {
  color: #fff;
  background-color: #d32f2f;
  border-color: #d32f2f;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-danger .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-danger .close {
    outline: none; }
  .alert.alert-danger.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #d32f2f;
    color: #d32f2f;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-danger.alert-outline .close {
      color: #d32f2f;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-danger.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #d32f2f;
    color: #d32f2f;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-danger.alert-bordered .close {
      color: #d32f2f;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-green {
  color: #fff;
  background-color: #5d7e3d;
  border-color: #5d7e3d;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-green .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-green .close {
    outline: none; }
  .alert.alert-green.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #5d7e3d;
    color: #5d7e3d;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-green.alert-outline .close {
      color: #5d7e3d;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-green.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #5d7e3d;
    color: #5d7e3d;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-green.alert-bordered .close {
      color: #5d7e3d;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-blue-1 {
  color: #fff;
  background-color: #027ec1;
  border-color: #027ec1;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-blue-1 .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-blue-1 .close {
    outline: none; }
  .alert.alert-cyder-blue-1.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #027ec1;
    color: #027ec1;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-blue-1.alert-outline .close {
      color: #027ec1;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-blue-1.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #027ec1;
    color: #027ec1;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-blue-1.alert-bordered .close {
      color: #027ec1;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-blue-2 {
  color: #fff;
  background-color: #2bbed3;
  border-color: #2bbed3;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-blue-2 .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-blue-2 .close {
    outline: none; }
  .alert.alert-cyder-blue-2.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #2bbed3;
    color: #2bbed3;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-blue-2.alert-outline .close {
      color: #2bbed3;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-blue-2.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #2bbed3;
    color: #2bbed3;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-blue-2.alert-bordered .close {
      color: #2bbed3;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-green-1 {
  color: #fff;
  background-color: #439b58;
  border-color: #439b58;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-green-1 .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-green-1 .close {
    outline: none; }
  .alert.alert-cyder-green-1.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #439b58;
    color: #439b58;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-1.alert-outline .close {
      color: #439b58;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-green-1.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #439b58;
    color: #439b58;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-1.alert-bordered .close {
      color: #439b58;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-green-2 {
  color: #fff;
  background-color: #21401c;
  border-color: #21401c;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-green-2 .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-green-2 .close {
    outline: none; }
  .alert.alert-cyder-green-2.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #21401c;
    color: #21401c;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-2.alert-outline .close {
      color: #21401c;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-green-2.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #21401c;
    color: #21401c;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-2.alert-bordered .close {
      color: #21401c;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-orange-1 {
  color: #fff;
  background-color: #ffab1a;
  border-color: #ffab1a;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-orange-1 .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-orange-1 .close {
    outline: none; }
  .alert.alert-cyder-orange-1.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #ffab1a;
    color: #ffab1a;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-orange-1.alert-outline .close {
      color: #ffab1a;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-orange-1.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #ffab1a;
    color: #ffab1a;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-orange-1.alert-bordered .close {
      color: #ffab1a;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-pink-1 {
  color: #fff;
  background-color: #ec5a71;
  border-color: #ec5a71;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-pink-1 .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-pink-1 .close {
    outline: none; }
  .alert.alert-cyder-pink-1.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #ec5a71;
    color: #ec5a71;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-pink-1.alert-outline .close {
      color: #ec5a71;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-pink-1.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #ec5a71;
    color: #ec5a71;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-pink-1.alert-bordered .close {
      color: #ec5a71;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-yellow-1 {
  color: #fff;
  background-color: #ffd351;
  border-color: #ffd351;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-yellow-1 .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-yellow-1 .close {
    outline: none; }
  .alert.alert-cyder-yellow-1.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #ffd351;
    color: #ffd351;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-yellow-1.alert-outline .close {
      color: #ffd351;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-yellow-1.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #ffd351;
    color: #ffd351;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-yellow-1.alert-bordered .close {
      color: #ffd351;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-blue-1-darken {
  color: #fff;
  background-color: #014d75;
  border-color: #014d75;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-blue-1-darken .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-blue-1-darken .close {
    outline: none; }
  .alert.alert-cyder-blue-1-darken.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #014d75;
    color: #014d75;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-blue-1-darken.alert-outline .close {
      color: #014d75;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-blue-1-darken.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #014d75;
    color: #014d75;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-blue-1-darken.alert-bordered .close {
      color: #014d75;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-green-1-darken {
  color: #fff;
  background-color: #2c663a;
  border-color: #2c663a;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-green-1-darken .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-green-1-darken .close {
    outline: none; }
  .alert.alert-cyder-green-1-darken.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #2c663a;
    color: #2c663a;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-1-darken.alert-outline .close {
      color: #2c663a;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-green-1-darken.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #2c663a;
    color: #2c663a;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-1-darken.alert-bordered .close {
      color: #2c663a;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-green-2-darken {
  color: #fff;
  background-color: #060b05;
  border-color: #060b05;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-green-2-darken .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-green-2-darken .close {
    outline: none; }
  .alert.alert-cyder-green-2-darken.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #060b05;
    color: #060b05;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-2-darken.alert-outline .close {
      color: #060b05;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-green-2-darken.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #060b05;
    color: #060b05;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-green-2-darken.alert-bordered .close {
      color: #060b05;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-orange-1-darken {
  color: #fff;
  background-color: #cd8100;
  border-color: #cd8100;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-orange-1-darken .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-orange-1-darken .close {
    outline: none; }
  .alert.alert-cyder-orange-1-darken.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #cd8100;
    color: #cd8100;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-orange-1-darken.alert-outline .close {
      color: #cd8100;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-orange-1-darken.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #cd8100;
    color: #cd8100;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-orange-1-darken.alert-bordered .close {
      color: #cd8100;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-c-lighttile-darken {
  color: #fff;
  background-color: #40a9ab;
  border-color: #40a9ab;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-c-lighttile-darken .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-c-lighttile-darken .close {
    outline: none; }
  .alert.alert-c-lighttile-darken.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #40a9ab;
    color: #40a9ab;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-lighttile-darken.alert-outline .close {
      color: #40a9ab;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-c-lighttile-darken.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #40a9ab;
    color: #40a9ab;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-lighttile-darken.alert-bordered .close {
      color: #40a9ab;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-cyder-pink-1-darken {
  color: #fff;
  background-color: #e01a39;
  border-color: #e01a39;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-cyder-pink-1-darken .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-cyder-pink-1-darken .close {
    outline: none; }
  .alert.alert-cyder-pink-1-darken.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #e01a39;
    color: #e01a39;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-pink-1-darken.alert-outline .close {
      color: #e01a39;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-cyder-pink-1-darken.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #e01a39;
    color: #e01a39;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-cyder-pink-1-darken.alert-bordered .close {
      color: #e01a39;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-c-newdark {
  color: #fff;
  background-color: #313b3d;
  border-color: #313b3d;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-c-newdark .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-c-newdark .close {
    outline: none; }
  .alert.alert-c-newdark.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #313b3d;
    color: #313b3d;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-newdark.alert-outline .close {
      color: #313b3d;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-c-newdark.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #313b3d;
    color: #313b3d;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-newdark.alert-bordered .close {
      color: #313b3d;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-c-lightblue {
  color: #fff;
  background-color: #78adc9;
  border-color: #78adc9;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-c-lightblue .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-c-lightblue .close {
    outline: none; }
  .alert.alert-c-lightblue.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #78adc9;
    color: #78adc9;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-lightblue.alert-outline .close {
      color: #78adc9;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-c-lightblue.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #78adc9;
    color: #78adc9;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-lightblue.alert-bordered .close {
      color: #78adc9;
      text-shadow: none;
      opacity: 0.7; }

.alert.alert-c-lighttile {
  color: #fff;
  background-color: #6fc7c9;
  border-color: #6fc7c9;
  border-radius: 5px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .alert.alert-c-lighttile .alert-heading {
    margin-bottom: 10px; }
  .alert.alert-c-lighttile .close {
    outline: none; }
  .alert.alert-c-lighttile.alert-outline {
    background-color: transparent;
    border-style: solid;
    border-width: 1px;
    border-color: #6fc7c9;
    color: #6fc7c9;
    border-radius: 5px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-lighttile.alert-outline .close {
      color: #6fc7c9;
      text-shadow: none;
      opacity: 0.7; }
  .alert.alert-c-lighttile.alert-bordered {
    background-color: transparent;
    border: 0;
    border-left: 5px solid #6fc7c9;
    color: #6fc7c9;
    border-radius: 0;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .alert.alert-c-lighttile.alert-bordered .close {
      color: #6fc7c9;
      text-shadow: none;
      opacity: 0.7; }
