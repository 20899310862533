.vertical-center-aligned-icon-danger {
  vertical-align: middle;
  display: inline-flex;
  color: #d32f2f; }

.vertical-center-aligned-icon-info {
  vertical-align: middle;
  display: inline-flex;
  color: #0288d1; }

.vertical-center-aligned-icon-success {
  vertical-align: middle;
  display: inline-flex;
  color: #388e3c; }

.vertical-center-aligned-icon-warning {
  vertical-align: middle;
  display: inline-flex;
  color: #ffa000; }
