.navbar.navbar-1 {
  min-height: 0;
  height: 70px;
  border: none;
  width: 100%;
  font-size: 14px;
  font-weight: 900; }
  .navbar.navbar-1 .nav .nav-item {
    position: relative; }
    .navbar.navbar-1 .nav .nav-item .nav-link {
      font-weight: normal;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 14px;
      font-weight: 900; }
      .navbar.navbar-1 .nav .nav-item .nav-link.nav-link-badge {
        position: relative; }
        .navbar.navbar-1 .nav .nav-item .nav-link.nav-link-badge .badge {
          position: absolute;
          top: 0;
          right: 0; }
      .navbar.navbar-1 .nav .nav-item .nav-link.nav-link-avatar img {
        max-width: 40px;
        border-style: solid;
        border-width: 2px;
        border-color: #fff;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        position: absolute;
        top: -2px;
        left: -15px;
        z-index: 1; }
      .navbar.navbar-1 .nav .nav-item .nav-link.nav-link-avatar .badge {
        border: 0;
        box-shadow: none;
        position: absolute;
        top: -5px;
        right: 5px;
        z-index: 2; }
    .navbar.navbar-1 .nav .nav-item.nav-item-dropdown .navbar-dropdown {
      z-index: 1039;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      width: 280px;
      position: absolute;
      top: 100%;
      left: 0;
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
      transform: translate3d(0, 20px, 0);
      transition: all 0.35s ease; }
      .navbar.navbar-1 .nav .nav-item.nav-item-dropdown .navbar-dropdown .dropdown-title {
        width: 100%;
        font-weight: normal;
        padding: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 900; }
      .navbar.navbar-1 .nav .nav-item.nav-item-dropdown .navbar-dropdown .dropdown-header {
        width: 100%;
        font-weight: normal;
        padding: 10px;
        font-size: 14px;
        font-weight: 900; }
      .navbar.navbar-1 .nav .nav-item.nav-item-dropdown .navbar-dropdown .dropdown-item {
        width: 100%;
        font-weight: normal;
        padding: 5px 10px; }
    .navbar.navbar-1 .nav .nav-item.nav-item-dropdown:hover .navbar-dropdown {
      visibility: visible;
      pointer-events: auto;
      opacity: 1;
      transform: translate3d(0, 0, 0); }
  .navbar.navbar-1 .nav.nav-toggle-layout-1 {
    padding-left: 30px; }
  .navbar.navbar-1 .nav.nav-toggle-layout-2 {
    padding-right: 20px; }
  .navbar.navbar-1 .navbar-search-form {
    flex: 0 0 240px;
    margin-left: 20px;
    margin-right: 60px; }
    .navbar.navbar-1 .navbar-search-form .form-group {
      margin: 0 0 3px 0; }
      .navbar.navbar-1 .navbar-search-form .form-group .input-group {
        margin: 0; }

[data-navbar='light'] .navbar.navbar-1 {
  background: #ffffff;
  color: #424242; }
  [data-navbar='light'] .navbar.navbar-1 .nav-link {
    color: #424242; }
  [data-navbar='light'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #424242;
    background-color: #f0f0f0;
    border-color: #f0f0f0;
    cursor: pointer; }
  [data-navbar='light'] .navbar.navbar-1 .input-group .form-control {
    color: #424242;
    background-color: #f0f0f0;
    border-color: #f0f0f0; }
  [data-navbar='light'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #424242; }
  [data-navbar='light'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #424242; }
  [data-navbar='light'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #424242; }
  [data-navbar='light'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #424242; }
  [data-navbar='light'] .navbar.navbar-1 .navbar-dropdown {
    background: #ffffff;
    opacity: 0.96 !important;
    color: #424242; }
    [data-navbar='light'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #ffffff;
      color: #424242;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='light'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #ffffff;
      opacity: 0.8 !important;
      color: #424242; }
      [data-navbar='light'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #424242; }
      [data-navbar='light'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #f7f7f7;
        color: #424242; }

[data-navbar='dark'] .navbar.navbar-1 {
  background: #424242;
  color: #ffffff; }
  [data-navbar='dark'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='dark'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #515151;
    border-color: #515151;
    cursor: pointer; }
  [data-navbar='dark'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #515151;
    border-color: #515151; }
  [data-navbar='dark'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='dark'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='dark'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='dark'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='dark'] .navbar.navbar-1 .navbar-dropdown {
    background: #424242;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='dark'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #424242;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='dark'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #424242;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='dark'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='dark'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #4a4a4a;
        color: #ffffff; }

[data-navbar='primary'] .navbar.navbar-1 {
  background: #303f9f;
  color: #ffffff; }
  [data-navbar='primary'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='primary'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #3748b7;
    border-color: #3748b7;
    cursor: pointer; }
  [data-navbar='primary'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #3748b7;
    border-color: #3748b7; }
  [data-navbar='primary'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='primary'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='primary'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='primary'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='primary'] .navbar.navbar-1 .navbar-dropdown {
    background: #303f9f;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='primary'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #303f9f;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='primary'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #303f9f;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='primary'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='primary'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #3444ab;
        color: #ffffff; }

[data-navbar='secondary'] .navbar.navbar-1 {
  background: #7b1fa2;
  color: #ffffff; }
  [data-navbar='secondary'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='secondary'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #8f24bc;
    border-color: #8f24bc;
    cursor: pointer; }
  [data-navbar='secondary'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #8f24bc;
    border-color: #8f24bc; }
  [data-navbar='secondary'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='secondary'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='secondary'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='secondary'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='secondary'] .navbar.navbar-1 .navbar-dropdown {
    background: #7b1fa2;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='secondary'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #7b1fa2;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='secondary'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #7b1fa2;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='secondary'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='secondary'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #8521af;
        color: #ffffff; }

[data-navbar='info'] .navbar.navbar-1 {
  background: #0288d1;
  color: #ffffff; }
  [data-navbar='info'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='info'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #029cef;
    border-color: #029cef;
    cursor: pointer; }
  [data-navbar='info'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #029cef;
    border-color: #029cef; }
  [data-navbar='info'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='info'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='info'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='info'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='info'] .navbar.navbar-1 .navbar-dropdown {
    background: #0288d1;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='info'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #0288d1;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='info'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #0288d1;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='info'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='info'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #0292e0;
        color: #ffffff; }

[data-navbar='success'] .navbar.navbar-1 {
  background: #388e3c;
  color: #ffffff; }
  [data-navbar='success'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='success'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #41a445;
    border-color: #41a445;
    cursor: pointer; }
  [data-navbar='success'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #41a445;
    border-color: #41a445; }
  [data-navbar='success'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='success'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='success'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='success'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='success'] .navbar.navbar-1 .navbar-dropdown {
    background: #388e3c;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='success'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #388e3c;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='success'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #388e3c;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='success'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='success'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #3c9941;
        color: #ffffff; }

[data-navbar='warning'] .navbar.navbar-1 {
  background: #ffa000;
  color: #ffffff; }
  [data-navbar='warning'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='warning'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #ffab1f;
    border-color: #ffab1f;
    cursor: pointer; }
  [data-navbar='warning'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #ffab1f;
    border-color: #ffab1f; }
  [data-navbar='warning'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='warning'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='warning'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='warning'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='warning'] .navbar.navbar-1 .navbar-dropdown {
    background: #ffa000;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='warning'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #ffa000;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='warning'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #ffa000;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='warning'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='warning'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #ffa60f;
        color: #ffffff; }

[data-navbar='danger'] .navbar.navbar-1 {
  background: #d32f2f;
  color: #ffffff; }
  [data-navbar='danger'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='danger'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #d84848;
    border-color: #d84848;
    cursor: pointer; }
  [data-navbar='danger'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #d84848;
    border-color: #d84848; }
  [data-navbar='danger'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='danger'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='danger'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='danger'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='danger'] .navbar.navbar-1 .navbar-dropdown {
    background: #d32f2f;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='danger'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #d32f2f;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='danger'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #d32f2f;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='danger'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='danger'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #d63c3c;
        color: #ffffff; }

[data-navbar='green'] .navbar.navbar-1 {
  background: #5d7e3d;
  color: #ffffff; }
  [data-navbar='green'] .navbar.navbar-1 .nav-link {
    color: #ffffff; }
  [data-navbar='green'] .navbar.navbar-1 .input-group .input-group-addon {
    color: #ffffff;
    background-color: #6c9347;
    border-color: #6c9347;
    cursor: pointer; }
  [data-navbar='green'] .navbar.navbar-1 .input-group .form-control {
    color: #ffffff;
    background-color: #6c9347;
    border-color: #6c9347; }
  [data-navbar='green'] .navbar.navbar-1 .navbar-search .form-control ::-webkit-input-placeholder {
    color: #ffffff; }
  [data-navbar='green'] .navbar.navbar-1 .navbar-search .form-control :-moz-placeholder {
    color: #ffffff; }
  [data-navbar='green'] .navbar.navbar-1 .navbar-search .form-control ::-moz-placeholder {
    color: #ffffff; }
  [data-navbar='green'] .navbar.navbar-1 .navbar-search .form-control :-ms-input-placeholder {
    color: #ffffff; }
  [data-navbar='green'] .navbar.navbar-1 .navbar-dropdown {
    background: #5d7e3d;
    opacity: 0.96 !important;
    color: #ffffff; }
    [data-navbar='green'] .navbar.navbar-1 .navbar-dropdown .dropdown-title {
      background: #5d7e3d;
      color: #ffffff;
      font-size: 14px;
      font-weight: 900; }
    [data-navbar='green'] .navbar.navbar-1 .navbar-dropdown .dropdown-item {
      background: #5d7e3d;
      opacity: 0.8 !important;
      color: #ffffff; }
      [data-navbar='green'] .navbar.navbar-1 .navbar-dropdown .dropdown-item a {
        color: #ffffff; }
      [data-navbar='green'] .navbar.navbar-1 .navbar-dropdown .dropdown-item:hover {
        background: #658842;
        color: #ffffff; }
