.modal-dialog {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .modal-dialog .modal-header {
    padding: 10px 20px; }
    .modal-dialog .modal-header .modal-title {
      font-size: 1rem;
      font-weight: normal;
      text-transform: uppercase; }
  .modal-dialog .modal-footer {
    text-align: left;
    padding: 10px 20px; }

[data-background="light"] .modal .modal-dialog .modal-content {
  background-color: #ffffff;
  color: #424242; }
  [data-background="light"] .modal .modal-dialog .modal-content .modal-header,
  [data-background="light"] .modal .modal-dialog .modal-content .modal-body,
  [data-background="light"] .modal .modal-dialog .modal-content .modal-footer {
    background-color: #ffffff;
    border-color: #e0e0e0;
    color: #424242; }

[data-background="dark"] .modal .modal-dialog .modal-content {
  background-color: #424242;
  color: #ffffff; }
  [data-background="dark"] .modal .modal-dialog .modal-content .modal-header,
  [data-background="dark"] .modal .modal-dialog .modal-content .modal-body,
  [data-background="dark"] .modal .modal-dialog .modal-content .modal-footer {
    background-color: #424242;
    border-color: #616161;
    color: #ffffff; }

[data-background="primary"] .modal .modal-dialog .modal-content {
  background-color: #303f9f;
  color: #ffffff; }
  [data-background="primary"] .modal .modal-dialog .modal-content .modal-header,
  [data-background="primary"] .modal .modal-dialog .modal-content .modal-body,
  [data-background="primary"] .modal .modal-dialog .modal-content .modal-footer {
    background-color: #303f9f;
    border-color: #4557c7;
    color: #ffffff; }

.modal-light .modal .modal-dialog .modal-content .modal-header {
  background-color: #ffffff;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-dark .modal .modal-dialog .modal-content .modal-header {
  background-color: #424242;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-grey .modal .modal-dialog .modal-content .modal-header {
  background-color: #63666a;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-sgbc .modal .modal-dialog .modal-content .modal-header {
  background-color: #63666a;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-default .modal .modal-dialog .modal-content .modal-header {
  background-color: #212121;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-primary .modal .modal-dialog .modal-content .modal-header {
  background-color: #303f9f;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-secondary .modal .modal-dialog .modal-content .modal-header {
  background-color: #7b1fa2;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-info .modal .modal-dialog .modal-content .modal-header {
  background-color: #0288d1;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-success .modal .modal-dialog .modal-content .modal-header {
  background-color: #388e3c;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-warning .modal .modal-dialog .modal-content .modal-header {
  background-color: #ffa000;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-danger .modal .modal-dialog .modal-content .modal-header {
  background-color: #d32f2f;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-green .modal .modal-dialog .modal-content .modal-header {
  background-color: #5d7e3d;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-blue-1 .modal .modal-dialog .modal-content .modal-header {
  background-color: #027ec1;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-blue-2 .modal .modal-dialog .modal-content .modal-header {
  background-color: #2bbed3;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-green-1 .modal .modal-dialog .modal-content .modal-header {
  background-color: #439b58;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-green-2 .modal .modal-dialog .modal-content .modal-header {
  background-color: #21401c;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-orange-1 .modal .modal-dialog .modal-content .modal-header {
  background-color: #ffab1a;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-pink-1 .modal .modal-dialog .modal-content .modal-header {
  background-color: #ec5a71;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-yellow-1 .modal .modal-dialog .modal-content .modal-header {
  background-color: #ffd351;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-blue-1-darken .modal .modal-dialog .modal-content .modal-header {
  background-color: #014d75;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-green-1-darken .modal .modal-dialog .modal-content .modal-header {
  background-color: #2c663a;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-green-2-darken .modal .modal-dialog .modal-content .modal-header {
  background-color: #060b05;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-orange-1-darken .modal .modal-dialog .modal-content .modal-header {
  background-color: #cd8100;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-c-lighttile-darken .modal .modal-dialog .modal-content .modal-header {
  background-color: #40a9ab;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-cyder-pink-1-darken .modal .modal-dialog .modal-content .modal-header {
  background-color: #e01a39;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-c-newdark .modal .modal-dialog .modal-content .modal-header {
  background-color: #313b3d;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-c-lightblue .modal .modal-dialog .modal-content .modal-header {
  background-color: #78adc9;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }

.modal-c-lighttile .modal .modal-dialog .modal-content .modal-header {
  background-color: #6fc7c9;
  color: #fff;
  border-bottom: none;
  padding: 15px 20px; }
