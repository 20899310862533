[data-background="light"] .pagination .page-item a {
  color: #424242;
  border-color: #e0e0e0;
  background-color: #fff; }

[data-background="light"] .pagination .page-item:hover a, [data-background="light"] .pagination .page-item.active a {
  color: #fff;
  border-color: #d32f2f;
  background-color: #d32f2f; }

[data-background="light"] .pagination.pagination-light .page-item:hover a, [data-background="light"] .pagination.pagination-light .page-item.active a {
  color: #fff;
  border-color: #ffffff;
  background-color: #ffffff; }

[data-background="light"] .pagination.pagination-dark .page-item:hover a, [data-background="light"] .pagination.pagination-dark .page-item.active a {
  color: #fff;
  border-color: #424242;
  background-color: #424242; }

[data-background="light"] .pagination.pagination-grey .page-item:hover a, [data-background="light"] .pagination.pagination-grey .page-item.active a {
  color: #fff;
  border-color: #63666a;
  background-color: #63666a; }

[data-background="light"] .pagination.pagination-sgbc .page-item:hover a, [data-background="light"] .pagination.pagination-sgbc .page-item.active a {
  color: #fff;
  border-color: #63666a;
  background-color: #63666a; }

[data-background="light"] .pagination.pagination-default .page-item:hover a, [data-background="light"] .pagination.pagination-default .page-item.active a {
  color: #fff;
  border-color: #212121;
  background-color: #212121; }

[data-background="light"] .pagination.pagination-primary .page-item:hover a, [data-background="light"] .pagination.pagination-primary .page-item.active a {
  color: #fff;
  border-color: #303f9f;
  background-color: #303f9f; }

[data-background="light"] .pagination.pagination-secondary .page-item:hover a, [data-background="light"] .pagination.pagination-secondary .page-item.active a {
  color: #fff;
  border-color: #7b1fa2;
  background-color: #7b1fa2; }

[data-background="light"] .pagination.pagination-info .page-item:hover a, [data-background="light"] .pagination.pagination-info .page-item.active a {
  color: #fff;
  border-color: #0288d1;
  background-color: #0288d1; }

[data-background="light"] .pagination.pagination-success .page-item:hover a, [data-background="light"] .pagination.pagination-success .page-item.active a {
  color: #fff;
  border-color: #388e3c;
  background-color: #388e3c; }

[data-background="light"] .pagination.pagination-warning .page-item:hover a, [data-background="light"] .pagination.pagination-warning .page-item.active a {
  color: #fff;
  border-color: #ffa000;
  background-color: #ffa000; }

[data-background="light"] .pagination.pagination-danger .page-item:hover a, [data-background="light"] .pagination.pagination-danger .page-item.active a {
  color: #fff;
  border-color: #d32f2f;
  background-color: #d32f2f; }

[data-background="light"] .pagination.pagination-green .page-item:hover a, [data-background="light"] .pagination.pagination-green .page-item.active a {
  color: #fff;
  border-color: #5d7e3d;
  background-color: #5d7e3d; }

[data-background="light"] .pagination.pagination-cyder-blue-1 .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-blue-1 .page-item.active a {
  color: #fff;
  border-color: #027ec1;
  background-color: #027ec1; }

[data-background="light"] .pagination.pagination-cyder-blue-2 .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-blue-2 .page-item.active a {
  color: #fff;
  border-color: #2bbed3;
  background-color: #2bbed3; }

[data-background="light"] .pagination.pagination-cyder-green-1 .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-green-1 .page-item.active a {
  color: #fff;
  border-color: #439b58;
  background-color: #439b58; }

[data-background="light"] .pagination.pagination-cyder-green-2 .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-green-2 .page-item.active a {
  color: #fff;
  border-color: #21401c;
  background-color: #21401c; }

[data-background="light"] .pagination.pagination-cyder-orange-1 .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-orange-1 .page-item.active a {
  color: #fff;
  border-color: #ffab1a;
  background-color: #ffab1a; }

[data-background="light"] .pagination.pagination-cyder-pink-1 .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-pink-1 .page-item.active a {
  color: #fff;
  border-color: #ec5a71;
  background-color: #ec5a71; }

[data-background="light"] .pagination.pagination-cyder-yellow-1 .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-yellow-1 .page-item.active a {
  color: #fff;
  border-color: #ffd351;
  background-color: #ffd351; }

[data-background="light"] .pagination.pagination-cyder-blue-1-darken .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-blue-1-darken .page-item.active a {
  color: #fff;
  border-color: #014d75;
  background-color: #014d75; }

[data-background="light"] .pagination.pagination-cyder-green-1-darken .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-green-1-darken .page-item.active a {
  color: #fff;
  border-color: #2c663a;
  background-color: #2c663a; }

[data-background="light"] .pagination.pagination-cyder-green-2-darken .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-green-2-darken .page-item.active a {
  color: #fff;
  border-color: #060b05;
  background-color: #060b05; }

[data-background="light"] .pagination.pagination-cyder-orange-1-darken .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-orange-1-darken .page-item.active a {
  color: #fff;
  border-color: #cd8100;
  background-color: #cd8100; }

[data-background="light"] .pagination.pagination-c-lighttile-darken .page-item:hover a, [data-background="light"] .pagination.pagination-c-lighttile-darken .page-item.active a {
  color: #fff;
  border-color: #40a9ab;
  background-color: #40a9ab; }

[data-background="light"] .pagination.pagination-cyder-pink-1-darken .page-item:hover a, [data-background="light"] .pagination.pagination-cyder-pink-1-darken .page-item.active a {
  color: #fff;
  border-color: #e01a39;
  background-color: #e01a39; }

[data-background="light"] .pagination.pagination-c-newdark .page-item:hover a, [data-background="light"] .pagination.pagination-c-newdark .page-item.active a {
  color: #fff;
  border-color: #313b3d;
  background-color: #313b3d; }

[data-background="light"] .pagination.pagination-c-lightblue .page-item:hover a, [data-background="light"] .pagination.pagination-c-lightblue .page-item.active a {
  color: #fff;
  border-color: #78adc9;
  background-color: #78adc9; }

[data-background="light"] .pagination.pagination-c-lighttile .page-item:hover a, [data-background="light"] .pagination.pagination-c-lighttile .page-item.active a {
  color: #fff;
  border-color: #6fc7c9;
  background-color: #6fc7c9; }

[data-background="dark"] .pagination .page-item a {
  color: #ffffff;
  border-color: #666666;
  background-color: #616161; }

[data-background="dark"] .pagination .page-item:hover a, [data-background="dark"] .pagination .page-item.active a {
  color: #fff;
  border-color: #ffa000;
  background-color: #ffa000; }

[data-background="dark"] .pagination.pagination-light .page-item:hover a, [data-background="dark"] .pagination.pagination-light .page-item.active a {
  color: #fff;
  border-color: #ffffff;
  background-color: #ffffff; }

[data-background="dark"] .pagination.pagination-dark .page-item:hover a, [data-background="dark"] .pagination.pagination-dark .page-item.active a {
  color: #fff;
  border-color: #424242;
  background-color: #424242; }

[data-background="dark"] .pagination.pagination-grey .page-item:hover a, [data-background="dark"] .pagination.pagination-grey .page-item.active a {
  color: #fff;
  border-color: #63666a;
  background-color: #63666a; }

[data-background="dark"] .pagination.pagination-sgbc .page-item:hover a, [data-background="dark"] .pagination.pagination-sgbc .page-item.active a {
  color: #fff;
  border-color: #63666a;
  background-color: #63666a; }

[data-background="dark"] .pagination.pagination-default .page-item:hover a, [data-background="dark"] .pagination.pagination-default .page-item.active a {
  color: #fff;
  border-color: #212121;
  background-color: #212121; }

[data-background="dark"] .pagination.pagination-primary .page-item:hover a, [data-background="dark"] .pagination.pagination-primary .page-item.active a {
  color: #fff;
  border-color: #303f9f;
  background-color: #303f9f; }

[data-background="dark"] .pagination.pagination-secondary .page-item:hover a, [data-background="dark"] .pagination.pagination-secondary .page-item.active a {
  color: #fff;
  border-color: #7b1fa2;
  background-color: #7b1fa2; }

[data-background="dark"] .pagination.pagination-info .page-item:hover a, [data-background="dark"] .pagination.pagination-info .page-item.active a {
  color: #fff;
  border-color: #0288d1;
  background-color: #0288d1; }

[data-background="dark"] .pagination.pagination-success .page-item:hover a, [data-background="dark"] .pagination.pagination-success .page-item.active a {
  color: #fff;
  border-color: #388e3c;
  background-color: #388e3c; }

[data-background="dark"] .pagination.pagination-warning .page-item:hover a, [data-background="dark"] .pagination.pagination-warning .page-item.active a {
  color: #fff;
  border-color: #ffa000;
  background-color: #ffa000; }

[data-background="dark"] .pagination.pagination-danger .page-item:hover a, [data-background="dark"] .pagination.pagination-danger .page-item.active a {
  color: #fff;
  border-color: #d32f2f;
  background-color: #d32f2f; }

[data-background="dark"] .pagination.pagination-green .page-item:hover a, [data-background="dark"] .pagination.pagination-green .page-item.active a {
  color: #fff;
  border-color: #5d7e3d;
  background-color: #5d7e3d; }

[data-background="dark"] .pagination.pagination-cyder-blue-1 .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-blue-1 .page-item.active a {
  color: #fff;
  border-color: #027ec1;
  background-color: #027ec1; }

[data-background="dark"] .pagination.pagination-cyder-blue-2 .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-blue-2 .page-item.active a {
  color: #fff;
  border-color: #2bbed3;
  background-color: #2bbed3; }

[data-background="dark"] .pagination.pagination-cyder-green-1 .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-green-1 .page-item.active a {
  color: #fff;
  border-color: #439b58;
  background-color: #439b58; }

[data-background="dark"] .pagination.pagination-cyder-green-2 .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-green-2 .page-item.active a {
  color: #fff;
  border-color: #21401c;
  background-color: #21401c; }

[data-background="dark"] .pagination.pagination-cyder-orange-1 .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-orange-1 .page-item.active a {
  color: #fff;
  border-color: #ffab1a;
  background-color: #ffab1a; }

[data-background="dark"] .pagination.pagination-cyder-pink-1 .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-pink-1 .page-item.active a {
  color: #fff;
  border-color: #ec5a71;
  background-color: #ec5a71; }

[data-background="dark"] .pagination.pagination-cyder-yellow-1 .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-yellow-1 .page-item.active a {
  color: #fff;
  border-color: #ffd351;
  background-color: #ffd351; }

[data-background="dark"] .pagination.pagination-cyder-blue-1-darken .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-blue-1-darken .page-item.active a {
  color: #fff;
  border-color: #014d75;
  background-color: #014d75; }

[data-background="dark"] .pagination.pagination-cyder-green-1-darken .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-green-1-darken .page-item.active a {
  color: #fff;
  border-color: #2c663a;
  background-color: #2c663a; }

[data-background="dark"] .pagination.pagination-cyder-green-2-darken .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-green-2-darken .page-item.active a {
  color: #fff;
  border-color: #060b05;
  background-color: #060b05; }

[data-background="dark"] .pagination.pagination-cyder-orange-1-darken .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-orange-1-darken .page-item.active a {
  color: #fff;
  border-color: #cd8100;
  background-color: #cd8100; }

[data-background="dark"] .pagination.pagination-c-lighttile-darken .page-item:hover a, [data-background="dark"] .pagination.pagination-c-lighttile-darken .page-item.active a {
  color: #fff;
  border-color: #40a9ab;
  background-color: #40a9ab; }

[data-background="dark"] .pagination.pagination-cyder-pink-1-darken .page-item:hover a, [data-background="dark"] .pagination.pagination-cyder-pink-1-darken .page-item.active a {
  color: #fff;
  border-color: #e01a39;
  background-color: #e01a39; }

[data-background="dark"] .pagination.pagination-c-newdark .page-item:hover a, [data-background="dark"] .pagination.pagination-c-newdark .page-item.active a {
  color: #fff;
  border-color: #313b3d;
  background-color: #313b3d; }

[data-background="dark"] .pagination.pagination-c-lightblue .page-item:hover a, [data-background="dark"] .pagination.pagination-c-lightblue .page-item.active a {
  color: #fff;
  border-color: #78adc9;
  background-color: #78adc9; }

[data-background="dark"] .pagination.pagination-c-lighttile .page-item:hover a, [data-background="dark"] .pagination.pagination-c-lighttile .page-item.active a {
  color: #fff;
  border-color: #6fc7c9;
  background-color: #6fc7c9; }

[data-background="primary"] .pagination .page-item a {
  color: #ffffff;
  border-color: #4d5ec9;
  background-color: #4557c7; }

[data-background="primary"] .pagination .page-item:hover a, [data-background="primary"] .pagination .page-item.active a {
  color: #fff;
  border-color: #ffa000;
  background-color: #ffa000; }

[data-background="primary"] .pagination.pagination-light .page-item:hover a, [data-background="primary"] .pagination.pagination-light .page-item.active a {
  color: #fff;
  border-color: #ffffff;
  background-color: #ffffff; }

[data-background="primary"] .pagination.pagination-dark .page-item:hover a, [data-background="primary"] .pagination.pagination-dark .page-item.active a {
  color: #fff;
  border-color: #424242;
  background-color: #424242; }

[data-background="primary"] .pagination.pagination-grey .page-item:hover a, [data-background="primary"] .pagination.pagination-grey .page-item.active a {
  color: #fff;
  border-color: #63666a;
  background-color: #63666a; }

[data-background="primary"] .pagination.pagination-sgbc .page-item:hover a, [data-background="primary"] .pagination.pagination-sgbc .page-item.active a {
  color: #fff;
  border-color: #63666a;
  background-color: #63666a; }

[data-background="primary"] .pagination.pagination-default .page-item:hover a, [data-background="primary"] .pagination.pagination-default .page-item.active a {
  color: #fff;
  border-color: #212121;
  background-color: #212121; }

[data-background="primary"] .pagination.pagination-primary .page-item:hover a, [data-background="primary"] .pagination.pagination-primary .page-item.active a {
  color: #fff;
  border-color: #303f9f;
  background-color: #303f9f; }

[data-background="primary"] .pagination.pagination-secondary .page-item:hover a, [data-background="primary"] .pagination.pagination-secondary .page-item.active a {
  color: #fff;
  border-color: #7b1fa2;
  background-color: #7b1fa2; }

[data-background="primary"] .pagination.pagination-info .page-item:hover a, [data-background="primary"] .pagination.pagination-info .page-item.active a {
  color: #fff;
  border-color: #0288d1;
  background-color: #0288d1; }

[data-background="primary"] .pagination.pagination-success .page-item:hover a, [data-background="primary"] .pagination.pagination-success .page-item.active a {
  color: #fff;
  border-color: #388e3c;
  background-color: #388e3c; }

[data-background="primary"] .pagination.pagination-warning .page-item:hover a, [data-background="primary"] .pagination.pagination-warning .page-item.active a {
  color: #fff;
  border-color: #ffa000;
  background-color: #ffa000; }

[data-background="primary"] .pagination.pagination-danger .page-item:hover a, [data-background="primary"] .pagination.pagination-danger .page-item.active a {
  color: #fff;
  border-color: #d32f2f;
  background-color: #d32f2f; }

[data-background="primary"] .pagination.pagination-green .page-item:hover a, [data-background="primary"] .pagination.pagination-green .page-item.active a {
  color: #fff;
  border-color: #5d7e3d;
  background-color: #5d7e3d; }

[data-background="primary"] .pagination.pagination-cyder-blue-1 .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-blue-1 .page-item.active a {
  color: #fff;
  border-color: #027ec1;
  background-color: #027ec1; }

[data-background="primary"] .pagination.pagination-cyder-blue-2 .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-blue-2 .page-item.active a {
  color: #fff;
  border-color: #2bbed3;
  background-color: #2bbed3; }

[data-background="primary"] .pagination.pagination-cyder-green-1 .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-green-1 .page-item.active a {
  color: #fff;
  border-color: #439b58;
  background-color: #439b58; }

[data-background="primary"] .pagination.pagination-cyder-green-2 .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-green-2 .page-item.active a {
  color: #fff;
  border-color: #21401c;
  background-color: #21401c; }

[data-background="primary"] .pagination.pagination-cyder-orange-1 .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-orange-1 .page-item.active a {
  color: #fff;
  border-color: #ffab1a;
  background-color: #ffab1a; }

[data-background="primary"] .pagination.pagination-cyder-pink-1 .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-pink-1 .page-item.active a {
  color: #fff;
  border-color: #ec5a71;
  background-color: #ec5a71; }

[data-background="primary"] .pagination.pagination-cyder-yellow-1 .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-yellow-1 .page-item.active a {
  color: #fff;
  border-color: #ffd351;
  background-color: #ffd351; }

[data-background="primary"] .pagination.pagination-cyder-blue-1-darken .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-blue-1-darken .page-item.active a {
  color: #fff;
  border-color: #014d75;
  background-color: #014d75; }

[data-background="primary"] .pagination.pagination-cyder-green-1-darken .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-green-1-darken .page-item.active a {
  color: #fff;
  border-color: #2c663a;
  background-color: #2c663a; }

[data-background="primary"] .pagination.pagination-cyder-green-2-darken .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-green-2-darken .page-item.active a {
  color: #fff;
  border-color: #060b05;
  background-color: #060b05; }

[data-background="primary"] .pagination.pagination-cyder-orange-1-darken .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-orange-1-darken .page-item.active a {
  color: #fff;
  border-color: #cd8100;
  background-color: #cd8100; }

[data-background="primary"] .pagination.pagination-c-lighttile-darken .page-item:hover a, [data-background="primary"] .pagination.pagination-c-lighttile-darken .page-item.active a {
  color: #fff;
  border-color: #40a9ab;
  background-color: #40a9ab; }

[data-background="primary"] .pagination.pagination-cyder-pink-1-darken .page-item:hover a, [data-background="primary"] .pagination.pagination-cyder-pink-1-darken .page-item.active a {
  color: #fff;
  border-color: #e01a39;
  background-color: #e01a39; }

[data-background="primary"] .pagination.pagination-c-newdark .page-item:hover a, [data-background="primary"] .pagination.pagination-c-newdark .page-item.active a {
  color: #fff;
  border-color: #313b3d;
  background-color: #313b3d; }

[data-background="primary"] .pagination.pagination-c-lightblue .page-item:hover a, [data-background="primary"] .pagination.pagination-c-lightblue .page-item.active a {
  color: #fff;
  border-color: #78adc9;
  background-color: #78adc9; }

[data-background="primary"] .pagination.pagination-c-lighttile .page-item:hover a, [data-background="primary"] .pagination.pagination-c-lighttile .page-item.active a {
  color: #fff;
  border-color: #6fc7c9;
  background-color: #6fc7c9; }
