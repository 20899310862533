.jumbotron.jumbotron-1 {
  padding: 0px 10px;
  padding-left: 0px; }
  .jumbotron.jumbotron-1 .title {
    font-size: 1.5rem;
    font-weight: normal; }
  .jumbotron.jumbotron-1 .subtitle {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #616161; }
  .jumbotron.jumbotron-1 .jumbotron-container-col {
    padding-left: 10px !important; }
  .jumbotron.jumbotron-1 .jumbotron-container-fluid {
    padding-left: 0px !important;
    color: #5b832d  !important; }
  .jumbotron.jumbotron-1 .jumbotron-actions .btn {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .jumbotron.jumbotron-1 .jumbotron-actions .btn:hover {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

[data-background="light"] .jumbotron.jumbotron-1 {
  background-color: #ffffff;
  color: #424242;
  color: #63666A; }
