.icon-widget-6 {
  padding: 10px; }
  .icon-widget-6 i {
    display: inline-flex;
    vertical-align: middle;
    font-size: 50px; }
  .icon-widget-6 .text {
    white-space: nowrap;
    font-size: 0.875rem; }
  .icon-widget-6 .number {
    font-size: 1.5rem; }
