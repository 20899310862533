.ion-icon {
  display: inline-block;
  font-size: 16px; }
  .ion-icon.ion-icon-1x {
    font-size: 16px; }
  .ion-icon.ion-icon-2x {
    font-size: 28px; }
  .ion-icon.ion-icon-3x {
    font-size: 40px; }

.ionicons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }
  .ionicons .icon {
    text-align: center;
    width: 150px;
    min-height: 100px;
    padding: 20px 0;
    margin-bottom: 20px; }
    .ionicons .icon i {
      margin: 0 5px; }
    .ionicons .icon .icons {
      display: block;
      margin-bottom: 20px; }
    .ionicons .icon .icon-class {
      display: block;
      font-size: 13px;
      font-family: "Roboto", sans-serif; }

[data-background="light"] .ionicons .icon {
  color: #424242; }
  [data-background="light"] .ionicons .icon i,
  [data-background="light"] .ionicons .icon .icon-class {
    color: #424242; }

[data-background="dark"] .ionicons .icon {
  color: #ffffff; }
  [data-background="dark"] .ionicons .icon i,
  [data-background="dark"] .ionicons .icon .icon-class {
    color: #ffffff; }

[data-background="primary"] .ionicons .icon {
  color: #ffffff; }
  [data-background="primary"] .ionicons .icon i,
  [data-background="primary"] .ionicons .icon .icon-class {
    color: #ffffff; }
