/*!
 * https://github.com/YouCanBookMe/react-datetime
			background-color: map-get($value, 'background-color');
			border-color: map-get($value, 'border-color');
			color: map-get($value, 'color');
 */
[data-background="light"] .rdt {
  position: relative;
  width: 100%; }
  [data-background="light"] .rdt input {
    width: 100%; }

[data-background="light"] .rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background-color: #ffffff;
  color: #424242;
  border: 1px solid #e0e0e0; }

[data-background="light"] .rdtOpen .rdtPicker {
  display: block; }

[data-background="light"] .rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

[data-background="light"] .rdtPicker .rdtTimeToggle {
  text-align: center; }

[data-background="light"] .rdtPicker table {
  width: 100%;
  margin: 0; }

[data-background="light"] .rdtPicker td,
[data-background="light"] .rdtPicker th {
  text-align: center;
  height: 28px; }

[data-background="light"] .rdtPicker td {
  cursor: pointer; }

[data-background="light"] .rdtPicker td.rdtDay:hover,
[data-background="light"] .rdtPicker td.rdtHour:hover,
[data-background="light"] .rdtPicker td.rdtMinute:hover,
[data-background="light"] .rdtPicker td.rdtSecond:hover,
[data-background="light"] .rdtPicker .rdtTimeToggle:hover {
  background-color: #e8e8e8;
  cursor: pointer; }

[data-background="light"] .rdtPicker td.rdtOld,
[data-background="light"] .rdtPicker td.rdtNew {
  color: #424242; }

[data-background="light"] .rdtPicker td.rdtToday {
  position: relative; }

[data-background="light"] .rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #e0e0e0;
  border-top-color: #e0e0e0;
  position: absolute;
  bottom: 4px;
  right: 4px; }

[data-background="light"] .rdtPicker td.rdtActive,
[data-background="light"] .rdtPicker td.rdtActive:hover {
  background-color: #d32f2f;
  color: #fff; }

[data-background="light"] .rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #e0e0e0; }

[data-background="light"] .rdtPicker td.rdtDisabled,
[data-background="light"] .rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #f7f7f7;
  cursor: not-allowed; }

[data-background="light"] .rdtPicker td span.rdtOld {
  color: #424242; }

[data-background="light"] .rdtPicker td span.rdtDisabled,
[data-background="light"] .rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #424242;
  cursor: not-allowed; }

[data-background="light"] .rdtPicker th {
  border-bottom: 1px solid #e0e0e0; }

[data-background="light"] .rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

[data-background="light"] .rdtPicker th.rdtSwitch {
  width: 100px; }

[data-background="light"] .rdtPicker th.rdtNext,
[data-background="light"] .rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

[data-background="light"] .rdtPrev span,
[data-background="light"] .rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

[data-background="light"] .rdtPicker th.rdtDisabled,
[data-background="light"] .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #424242;
  cursor: not-allowed; }

[data-background="light"] .rdtPicker thead tr:first-child th {
  cursor: pointer; }

[data-background="light"] .rdtPicker thead tr:first-child th:hover {
  background: #ffffff; }

[data-background="light"] .rdtPicker tfoot {
  border-top: 1px solid #e0e0e0; }

[data-background="light"] .rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

[data-background="light"] .rdtPicker button:hover {
  background-color: #ffffff; }

[data-background="light"] .rdtPicker thead button {
  width: 100%;
  height: 100%; }

[data-background="light"] td.rdtMonth,
[data-background="light"] td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

[data-background="light"] td.rdtMonth:hover,
[data-background="light"] td.rdtYear:hover {
  background: #ffffff; }

[data-background="light"] .rdtCounters {
  display: inline-block; }

[data-background="light"] .rdtCounters > div {
  float: left; }

[data-background="light"] .rdtCounter {
  height: 100px; }

[data-background="light"] .rdtCounter {
  width: 40px; }

[data-background="light"] .rdtCounterSeparator {
  line-height: 100px; }

[data-background="light"] .rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

[data-background="light"] .rdtCounter .rdtBtn:hover {
  background: #ffffff; }

[data-background="light"] .rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

[data-background="light"] .rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

[data-background="light"] .rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%; }

[data-background="dark"] .rdt {
  position: relative;
  width: 100%; }
  [data-background="dark"] .rdt input {
    width: 100%; }

[data-background="dark"] .rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background-color: #424242;
  color: #ffffff;
  border: 1px solid #616161; }

[data-background="dark"] .rdtOpen .rdtPicker {
  display: block; }

[data-background="dark"] .rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

[data-background="dark"] .rdtPicker .rdtTimeToggle {
  text-align: center; }

[data-background="dark"] .rdtPicker table {
  width: 100%;
  margin: 0; }

[data-background="dark"] .rdtPicker td,
[data-background="dark"] .rdtPicker th {
  text-align: center;
  height: 28px; }

[data-background="dark"] .rdtPicker td {
  cursor: pointer; }

[data-background="dark"] .rdtPicker td.rdtDay:hover,
[data-background="dark"] .rdtPicker td.rdtHour:hover,
[data-background="dark"] .rdtPicker td.rdtMinute:hover,
[data-background="dark"] .rdtPicker td.rdtSecond:hover,
[data-background="dark"] .rdtPicker .rdtTimeToggle:hover {
  background-color: #595959;
  cursor: pointer; }

[data-background="dark"] .rdtPicker td.rdtOld,
[data-background="dark"] .rdtPicker td.rdtNew {
  color: #ffffff; }

[data-background="dark"] .rdtPicker td.rdtToday {
  position: relative; }

[data-background="dark"] .rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #616161;
  border-top-color: #616161;
  position: absolute;
  bottom: 4px;
  right: 4px; }

[data-background="dark"] .rdtPicker td.rdtActive,
[data-background="dark"] .rdtPicker td.rdtActive:hover {
  background-color: #ffa000;
  color: #fff; }

[data-background="dark"] .rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #616161; }

[data-background="dark"] .rdtPicker td.rdtDisabled,
[data-background="dark"] .rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #4a4a4a;
  cursor: not-allowed; }

[data-background="dark"] .rdtPicker td span.rdtOld {
  color: #ffffff; }

[data-background="dark"] .rdtPicker td span.rdtDisabled,
[data-background="dark"] .rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #ffffff;
  cursor: not-allowed; }

[data-background="dark"] .rdtPicker th {
  border-bottom: 1px solid #616161; }

[data-background="dark"] .rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

[data-background="dark"] .rdtPicker th.rdtSwitch {
  width: 100px; }

[data-background="dark"] .rdtPicker th.rdtNext,
[data-background="dark"] .rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

[data-background="dark"] .rdtPrev span,
[data-background="dark"] .rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

[data-background="dark"] .rdtPicker th.rdtDisabled,
[data-background="dark"] .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #ffffff;
  cursor: not-allowed; }

[data-background="dark"] .rdtPicker thead tr:first-child th {
  cursor: pointer; }

[data-background="dark"] .rdtPicker thead tr:first-child th:hover {
  background: #424242; }

[data-background="dark"] .rdtPicker tfoot {
  border-top: 1px solid #616161; }

[data-background="dark"] .rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

[data-background="dark"] .rdtPicker button:hover {
  background-color: #424242; }

[data-background="dark"] .rdtPicker thead button {
  width: 100%;
  height: 100%; }

[data-background="dark"] td.rdtMonth,
[data-background="dark"] td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

[data-background="dark"] td.rdtMonth:hover,
[data-background="dark"] td.rdtYear:hover {
  background: #424242; }

[data-background="dark"] .rdtCounters {
  display: inline-block; }

[data-background="dark"] .rdtCounters > div {
  float: left; }

[data-background="dark"] .rdtCounter {
  height: 100px; }

[data-background="dark"] .rdtCounter {
  width: 40px; }

[data-background="dark"] .rdtCounterSeparator {
  line-height: 100px; }

[data-background="dark"] .rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

[data-background="dark"] .rdtCounter .rdtBtn:hover {
  background: #424242; }

[data-background="dark"] .rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

[data-background="dark"] .rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

[data-background="dark"] .rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%; }

[data-background="primary"] .rdt {
  position: relative;
  width: 100%; }
  [data-background="primary"] .rdt input {
    width: 100%; }

[data-background="primary"] .rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background-color: #303f9f;
  color: #ffffff;
  border: 1px solid #4557c7; }

[data-background="primary"] .rdtOpen .rdtPicker {
  display: block; }

[data-background="primary"] .rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

[data-background="primary"] .rdtPicker .rdtTimeToggle {
  text-align: center; }

[data-background="primary"] .rdtPicker table {
  width: 100%;
  margin: 0; }

[data-background="primary"] .rdtPicker td,
[data-background="primary"] .rdtPicker th {
  text-align: center;
  height: 28px; }

[data-background="primary"] .rdtPicker td {
  cursor: pointer; }

[data-background="primary"] .rdtPicker td.rdtDay:hover,
[data-background="primary"] .rdtPicker td.rdtHour:hover,
[data-background="primary"] .rdtPicker td.rdtMinute:hover,
[data-background="primary"] .rdtPicker td.rdtSecond:hover,
[data-background="primary"] .rdtPicker .rdtTimeToggle:hover {
  background-color: #3b4dc2;
  cursor: pointer; }

[data-background="primary"] .rdtPicker td.rdtOld,
[data-background="primary"] .rdtPicker td.rdtNew {
  color: #ffffff; }

[data-background="primary"] .rdtPicker td.rdtToday {
  position: relative; }

[data-background="primary"] .rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #4557c7;
  border-top-color: #4557c7;
  position: absolute;
  bottom: 4px;
  right: 4px; }

[data-background="primary"] .rdtPicker td.rdtActive,
[data-background="primary"] .rdtPicker td.rdtActive:hover {
  background-color: #ffa000;
  color: #fff; }

[data-background="primary"] .rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #4557c7; }

[data-background="primary"] .rdtPicker td.rdtDisabled,
[data-background="primary"] .rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #3444ab;
  cursor: not-allowed; }

[data-background="primary"] .rdtPicker td span.rdtOld {
  color: #ffffff; }

[data-background="primary"] .rdtPicker td span.rdtDisabled,
[data-background="primary"] .rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #ffffff;
  cursor: not-allowed; }

[data-background="primary"] .rdtPicker th {
  border-bottom: 1px solid #4557c7; }

[data-background="primary"] .rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none; }

[data-background="primary"] .rdtPicker th.rdtSwitch {
  width: 100px; }

[data-background="primary"] .rdtPicker th.rdtNext,
[data-background="primary"] .rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

[data-background="primary"] .rdtPrev span,
[data-background="primary"] .rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

[data-background="primary"] .rdtPicker th.rdtDisabled,
[data-background="primary"] .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #ffffff;
  cursor: not-allowed; }

[data-background="primary"] .rdtPicker thead tr:first-child th {
  cursor: pointer; }

[data-background="primary"] .rdtPicker thead tr:first-child th:hover {
  background: #303f9f; }

[data-background="primary"] .rdtPicker tfoot {
  border-top: 1px solid #4557c7; }

[data-background="primary"] .rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

[data-background="primary"] .rdtPicker button:hover {
  background-color: #303f9f; }

[data-background="primary"] .rdtPicker thead button {
  width: 100%;
  height: 100%; }

[data-background="primary"] td.rdtMonth,
[data-background="primary"] td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

[data-background="primary"] td.rdtMonth:hover,
[data-background="primary"] td.rdtYear:hover {
  background: #303f9f; }

[data-background="primary"] .rdtCounters {
  display: inline-block; }

[data-background="primary"] .rdtCounters > div {
  float: left; }

[data-background="primary"] .rdtCounter {
  height: 100px; }

[data-background="primary"] .rdtCounter {
  width: 40px; }

[data-background="primary"] .rdtCounterSeparator {
  line-height: 100px; }

[data-background="primary"] .rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

[data-background="primary"] .rdtCounter .rdtBtn:hover {
  background: #303f9f; }

[data-background="primary"] .rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

[data-background="primary"] .rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

[data-background="primary"] .rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

.rdtPickerOpenUpwards .rdtPicker {
  top: auto;
  bottom: 100%; }
