// Gradients

// Horizontal gradient, from left to right
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-x($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(
    to right,
    $start-color $start-percent,
    $end-color $end-percent
  );
  background-repeat: repeat-x;
}

// Vertical gradient, from top to bottom
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-y($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: linear-gradient(
    to bottom,
    $start-color $start-percent,
    $end-color $end-percent
  );
  background-repeat: repeat-x;
}

@mixin gradient-directional($start-color: #555, $end-color: #333, $deg: 45deg) {
  background-image: linear-gradient($deg, $start-color, $end-color);
  background-repeat: repeat-x;
}
@mixin gradient-x-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: linear-gradient(
    to right,
    $start-color,
    $mid-color $color-stop,
    $end-color
  );
  background-repeat: no-repeat;
}
@mixin gradient-y-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: linear-gradient(
    $start-color,
    $mid-color $color-stop,
    $end-color
  );
  background-repeat: no-repeat;
}
@mixin gradient-radial($inner-color: #555, $outer-color: #333) {
  background-image: radial-gradient(circle, $inner-color, $outer-color);
  background-repeat: no-repeat;
}
@mixin gradient-striped($color: rgba(255,255,255,0.15), $angle: 45deg) {
  background-image: linear-gradient(
    $angle,
    $color 25%,
    transparent 25%,
    transparent 50%,
    $color 50%,
    $color 75%,
    transparent 75%,
    transparent
  );
}
