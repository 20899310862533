.field-header {
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 700 !important; }

.field-sub-header {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 700 !important;
  text-decoration: underline; }

.field-label {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: normal; }
