/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(/fonts/material-icons.woff) format("woff"), url(/fonts/material-icons.woff2) format("woff2"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

.material-design-icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap; }
  .material-design-icons .icon {
    text-align: center;
    width: 150px;
    min-height: 100px;
    padding: 20px 0;
    margin-bottom: 20px; }
    .material-design-icons .icon i {
      margin: 0 5px; }
    .material-design-icons .icon .icons {
      display: block;
      margin-bottom: 20px; }
    .material-design-icons .icon .icon-class {
      display: block;
      font-size: 13px; }

.material-icons {
  font-size: 16px;
  line-height: 16px; }
  .material-icons.material-icons-1x {
    font-size: 16px;
    line-height: 16px; }
  .material-icons.material-icons-2x {
    font-size: 28px;
    line-height: 28px; }
  .material-icons.material-icons-3x {
    font-size: 40px;
    line-height: 40px; }

[data-background='light'] .material-design-icons-icons .icon {
  color: #424242; }
  [data-background='light'] .material-design-icons-icons .icon i,
  [data-background='light'] .material-design-icons-icons .icon .icon-class {
    color: #424242; }

[data-background='dark'] .material-design-icons-icons .icon {
  color: #ffffff; }
  [data-background='dark'] .material-design-icons-icons .icon i,
  [data-background='dark'] .material-design-icons-icons .icon .icon-class {
    color: #ffffff; }

[data-background='primary'] .material-design-icons-icons .icon {
  color: #ffffff; }
  [data-background='primary'] .material-design-icons-icons .icon i,
  [data-background='primary'] .material-design-icons-icons .icon .icon-class {
    color: #ffffff; }
