img.rounded {
  border-radius: 12px; }

.fx-demo {
  margin: 0 20px 20px 0;
  float: left;
  height: 200px;
  width: 300px;
  overflow: hidden; }
  .fx-demo > div {
    height: 200px;
    width: 100%;
    overflow: hidden;
    display: block; }
    .fx-demo > div img {
      width: 100%; }

.hover-fx-transition, .zoom-in-1 > img, .zoom-in-2 > img, .zoom-out-1 > img, .zoom-out-2 > img, .zoom-out-3 > img, .slide-right > img, .slide-left > img, .slide-up > img, .slide-down > img, .rotate-1 > img, .rotate-2 > img, .blur-1 > img, .grayscale-1 > img, .sepia-1 > img {
  transition: 0.3s ease-in-out; }

.linear-transition, .opacity-1 > img, .opacity-2 > img {
  transition: 0.2s linear; }

.zoom-in-1 {
  overflow: hidden; }
  .zoom-in-1 > img {
    transform: scale(1); }
  .zoom-in-1:hover img {
    transform: scale(1.3); }

.zoom-in-2 > img {
  min-width: 300px;
  height: auto; }

.zoom-in-2:hover img {
  min-width: 350px; }

.zoom-out-1 > img {
  transform: scale(1.5); }

.zoom-out-1:hover img {
  transform: scale(1); }

.zoom-out-2 > img {
  min-width: 400px;
  height: auto; }

.zoom-out-2:hover img {
  min-width: 300px; }

.zoom-out-3 > img {
  transform: scale(1.1); }

.zoom-out-3:hover img {
  transform: scale(1); }

.slide-right > img {
  transform: scale(1.5) translate3d(30px, 0, 0); }

.slide-right:hover img {
  transform: scale(1.5) translate3d(0px, 0, 0); }

.slide-left > img {
  transform: scale(1.5) translate3d(-30px, 0, 0); }

.slide-left:hover img {
  transform: scale(1.5) translate3d(0px, 0, 0); }

.slide-up > img {
  transform: scale(1.5) translate3d(0, 30px, 0); }

.slide-up:hover img {
  transform: scale(1.5) translate3d(0, 0px, 0); }

.slide-down > img {
  transform: scale(1.5) translate3d(0, -30px, 0); }

.slide-down:hover img {
  transform: scale(1.5) translate3d(0, 0px, 0); }

.rotate-1 {
  overflow: hidden; }
  .rotate-1 > img {
    transform: rotate(15deg) scale(1.4); }
  .rotate-1:hover img {
    transform: rotate(0) scale(1); }

.rotate-2 > img {
  transform: rotate(-15deg) scale(1.4); }

.rotate-2:hover img {
  transform: rotate(0) scale(1); }

.rotate-3 > img {
  transform: rotate(360deg) scale(1.5);
  transition: 0.3s linear; }

.rotate-3:hover img {
  transform: rotate(0) scale(1); }

.rotate-4 > img {
  transform: rotate(720deg) scale(1.5);
  transition: 0.3s linear; }

.rotate-4:hover img {
  transform: rotate(0) scale(1); }

.blur-1 > img {
  -webkit-filter: blur(3px);
  filter: blur(3px); }

.blur-1:hover img {
  -webkit-filter: blur(0);
  filter: blur(0); }

.grayscale-1 > img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }

.grayscale-1:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0); }

.sepia-1 > img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%); }

.sepia-1:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0); }

.opacity-1 > img {
  opacity: 1; }

.opacity-1:hover img {
  opacity: 0.5; }

.opacity-2 > img {
  opacity: 0.5; }

.opacity-2:hover img {
  opacity: 1; }

[data-background="light"] img.img-thumbnail {
  background-color: #e0e0e0;
  border-color: #e0e0e0; }

[data-background="dark"] img.img-thumbnail {
  background-color: #616161;
  border-color: #616161; }

[data-background="primary"] img.img-thumbnail {
  background-color: #4557c7;
  border-color: #4557c7; }
