a.cooltip {
  outline: none; }
  a.cooltip strong {
    line-height: 30px; }
  a.cooltip:hover {
    text-decoration: none; }
  a.cooltip span {
    z-index: 10;
    display: none;
    padding: 14px 20px;
    margin-top: -125px;
    margin-left: -40px;
    width: 300px;
    line-height: 16px; }
  a.cooltip:hover span {
    display: inline;
    position: absolute;
    border: 2px solid #FFF;
    color: #EEE;
    background: #333 url(/assets/images/css-cooltip-gradient-bg.png) repeat-x 0 0; }

.callout {
  z-index: 20;
  position: absolute;
  border: 0;
  top: 106px;
  left: 20px; }

/*CSS3 extras*/
a.cooltip span {
  border-radius: 2px;
  box-shadow: 0px 0px 8px 4px #666;
  /*opacity: 0.8;*/ }
