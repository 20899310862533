.user-widget-1 {
  padding: 20px 0; }

.nav-tabs-vertical-resources {
  display: flex;
  flex-direction: row;
  width: 100%; }
  .nav-tabs-vertical-resources .nav-vertical {
    flex: 0 0 20px; }
  .nav-tabs-vertical-resources .nav-tabs,
  .nav-tabs-vertical-resources .tab-content {
    display: flex;
    flex-direction: column; }
  .nav-tabs-vertical-resources .tab-content {
    padding: 10px 20px;
    width: 100%; }
  .nav-tabs-vertical-resources .nav-tabs {
    margin: 0;
    border: 0; }
  .nav-tabs-vertical-resources .nav-item {
    margin: 0;
    border: 0;
    width: 100%; }
  .nav-tabs-vertical-resources .nav-link {
    border: 0;
    position: relative;
    width: 100%;
    padding: 10px 15px; }
  .nav-tabs-vertical-resources .nav-link:after {
    content: "";
    display: none;
    visibility: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -3px;
    height: calc(100%);
    width: 3px;
    background-color: #303f9f;
    z-index: 2; }
  .nav-tabs-vertical-resources .nav-link.active:after,
  .nav-tabs-vertical-resources .nav-link:hover:after {
    display: block;
    visibility: visible; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-transparent .nav-link {
    background: #ffffff;
    color: #212121; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-transparent .nav-link.active,
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-transparent .nav-link:hover {
    background: #ffffff;
    color: #212121; }
  .nav-tabs-vertical-resources .nav-link {
    background: #ffffff;
    color: #212121; }
  .nav-tabs-vertical-resources .nav-link.active,
  .nav-tabs-vertical-resources .nav-link:hover {
    background: #ffffff;
    color: #212121; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-light .nav-link:after {
    background-color: #ffffff; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-dark .nav-link:after {
    background-color: #212121; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-default .nav-link:after {
    background-color: #212121; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-primary .nav-link:after {
    background-color: #303f9f; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-secondary .nav-link:after {
    background-color: #7b1fa2; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-info .nav-link:after {
    background-color: #0288d1; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-success .nav-link:after {
    background-color: #388e3c; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-warning .nav-link:after {
    background-color: #ffa000; }
  .nav-tabs-vertical-resources .nav-tabs-vertical-resources.nav-tabs-danger .nav-link:after {
    background-color: #d32f2f; }
