@import "../variables";

[data-layout="home"] {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  background: map-get($material-design-colors, "grey-50");
  position: relative;
}
