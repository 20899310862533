.dashboard-table {
  font-size: 16px; }
  .dashboard-table td {
    font-size: 14px; }
  .dashboard-table fa-caret-up {
    color: #388e3c; }
  .dashboard-table variation-up {
    color: #388e3c; }
  .dashboard-table fa-caret-down {
    color: #d32f2f; }
  .dashboard-table variation-down {
    color: #d32f2f; }
  .dashboard-table .number-label-danger {
    color: #d32f2f;
    font-weight: 400;
    font-size: 30px; }
  .dashboard-table .number-label-info {
    color: #0288d1;
    font-weight: 400;
    font-size: 30px; }
  .dashboard-table .number-label-success {
    color: #388e3c;
    font-weight: 400;
    font-size: 30px; }
  .dashboard-table .number-label-warning {
    color: #ffa000;
    font-weight: 400;
    font-size: 30px; }

[data-dashboard-table="one-column-with-download"] .dashboard-table td:nth-child(1) {
  font-weight: normal;
  width: 90%; }

[data-dashboard-table="one-column-with-download"] .dashboard-table td:nth-child(2) span {
  margin-left: 10px; }

[data-dashboard-table="one-column-with-download"] .dashboard-table td:nth-child(2) .variation {
  font-size: 0.85rem; }

[data-dashboard-table="two-column"] .dashboard-table td:nth-child(1) {
  font-weight: bolder; }

[data-dashboard-table="two-column"] .dashboard-table td:nth-child(2) span {
  margin-left: 10px; }

[data-dashboard-table="two-column"] .dashboard-table td:nth-child(2) .variation {
  font-size: 0.85rem; }

[data-dashboard-table="event-list"] .dashboard-table td:nth-child(1) {
  font-weight: bolder;
  width: 130px;
  font-size: 16px; }

[data-dashboard-table="event-list"] .dashboard-table td:nth-child(2) span {
  margin-left: 10px; }

[data-dashboard-table="event-list"] .dashboard-table td:nth-child(2) .variation {
  font-size: 0.85rem; }

[data-dashboard-table="three-column-80-10-10"] .dashboard-table td:nth-child(1) {
  font-weight: normal;
  width: 80%; }

[data-dashboard-table="three-column-80-10-10"] .dashboard-table td:nth-child(3) span {
  margin-left: 10px; }

[data-dashboard-table="three-column-80-10-10"] .dashboard-table td:nth-child(3) .variation {
  font-size: 0.85rem; }

[data-dashboard-table="three-column"] .dashboard-table td:nth-child(1) {
  font-weight: bolder; }

[data-dashboard-table="three-column"] .dashboard-table td:nth-child(3) span {
  margin-left: 10px; }

[data-dashboard-table="three-column"] .dashboard-table td:nth-child(3) .variation {
  font-size: 0.85rem; }

[data-dashboard-table="five-column"] .dashboard-table td:nth-child(1) {
  font-weight: bolder; }

[data-dashboard-table="five-column"] .dashboard-table td:nth-child(5) span {
  margin-left: 10px; }

[data-dashboard-table="five-column"] .dashboard-table td:nth-child(5) .variation {
  font-size: 0.85rem; }

[data-dashboard-table="five-column"] .dashboard-table td:nth-child(5) .variation {
  font-size: 0.85rem; }

[data-dashboard-table="resource-library"] .dashboard-table td:nth-child(1) {
  width: 10%;
  font-weight: bolder; }

[data-dashboard-table="resource-library"] .dashboard-table td:nth-child(2) {
  width: 80%; }

[data-dashboard-table="resource-library"] .dashboard-table td:nth-child(3) {
  width: 10%; }

[data-dashboard-table="resource-library"] .dashboard-table td:nth-child(4) {
  text-align: right; }
  [data-dashboard-table="resource-library"] .dashboard-table td:nth-child(4) span {
    margin-left: 10px; }
  [data-dashboard-table="resource-library"] .dashboard-table td:nth-child(4) .variation {
    font-size: 0.85rem; }
