[data-controller="notifications"][data-view="tooltips"] .tooltips-demo .btn {
  margin-right: 5px;
  margin-bottom: 10px; }

.tooltip {
  transition: opacity 0.3s ease;
  opacity: 0; }
  .tooltip.in {
    opacity: 1; }

[data-background="light"] .tooltip .tooltip-inner {
  border-radius: 5px;
  background-color: #424242;
  color: #ffffff; }

[data-background="light"] .tooltip.tooltip-top .tooltip-inner::before, [data-background="light"] .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #424242; }

[data-background="light"] .tooltip.tooltip-right .tooltip-inner::before, [data-background="light"] .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #424242; }

[data-background="light"] .tooltip.tooltip-bottom .tooltip-inner::before, [data-background="light"] .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #424242; }

[data-background="light"] .tooltip.tooltip-left .tooltip-inner::before, [data-background="light"] .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #424242; }

[data-background="dark"] .tooltip .tooltip-inner {
  border-radius: 5px;
  background-color: #ffffff;
  color: #424242; }

[data-background="dark"] .tooltip.tooltip-top .tooltip-inner::before, [data-background="dark"] .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #ffffff; }

[data-background="dark"] .tooltip.tooltip-right .tooltip-inner::before, [data-background="dark"] .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #ffffff; }

[data-background="dark"] .tooltip.tooltip-bottom .tooltip-inner::before, [data-background="dark"] .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #ffffff; }

[data-background="dark"] .tooltip.tooltip-left .tooltip-inner::before, [data-background="dark"] .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #ffffff; }

[data-background="primary"] .tooltip .tooltip-inner {
  border-radius: 5px;
  background-color: #ffffff;
  color: #303f9f; }

[data-background="primary"] .tooltip.tooltip-top .tooltip-inner::before, [data-background="primary"] .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #ffffff; }

[data-background="primary"] .tooltip.tooltip-right .tooltip-inner::before, [data-background="primary"] .tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #ffffff; }

[data-background="primary"] .tooltip.tooltip-bottom .tooltip-inner::before, [data-background="primary"] .tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #ffffff; }

[data-background="primary"] .tooltip.tooltip-left .tooltip-inner::before, [data-background="primary"] .tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #ffffff; }

.tooltip .tooltip-inner {
  border-radius: 5px; }

.tooltip .tooltip-light .tooltip-inner {
  border-radius: 5px;
  background-color: #ffffff;
  color: #fff; }

.tooltip .tooltip-light.tooltip-top .tooltip-inner::before, .tooltip .tooltip-light.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #ffffff; }

.tooltip .tooltip-light.tooltip-right .tooltip-inner::before, .tooltip .tooltip-light.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #ffffff; }

.tooltip .tooltip-light.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-light.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #ffffff; }

.tooltip .tooltip-light.tooltip-left .tooltip-inner::before, .tooltip .tooltip-light.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #ffffff; }

.tooltip .tooltip-dark .tooltip-inner {
  border-radius: 5px;
  background-color: #424242;
  color: #fff; }

.tooltip .tooltip-dark.tooltip-top .tooltip-inner::before, .tooltip .tooltip-dark.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #424242; }

.tooltip .tooltip-dark.tooltip-right .tooltip-inner::before, .tooltip .tooltip-dark.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #424242; }

.tooltip .tooltip-dark.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-dark.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #424242; }

.tooltip .tooltip-dark.tooltip-left .tooltip-inner::before, .tooltip .tooltip-dark.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #424242; }

.tooltip .tooltip-grey .tooltip-inner {
  border-radius: 5px;
  background-color: #63666a;
  color: #fff; }

.tooltip .tooltip-grey.tooltip-top .tooltip-inner::before, .tooltip .tooltip-grey.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #63666a; }

.tooltip .tooltip-grey.tooltip-right .tooltip-inner::before, .tooltip .tooltip-grey.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #63666a; }

.tooltip .tooltip-grey.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-grey.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #63666a; }

.tooltip .tooltip-grey.tooltip-left .tooltip-inner::before, .tooltip .tooltip-grey.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #63666a; }

.tooltip .tooltip-sgbc .tooltip-inner {
  border-radius: 5px;
  background-color: #63666a;
  color: #fff; }

.tooltip .tooltip-sgbc.tooltip-top .tooltip-inner::before, .tooltip .tooltip-sgbc.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #63666a; }

.tooltip .tooltip-sgbc.tooltip-right .tooltip-inner::before, .tooltip .tooltip-sgbc.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #63666a; }

.tooltip .tooltip-sgbc.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-sgbc.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #63666a; }

.tooltip .tooltip-sgbc.tooltip-left .tooltip-inner::before, .tooltip .tooltip-sgbc.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #63666a; }

.tooltip .tooltip-default .tooltip-inner {
  border-radius: 5px;
  background-color: #212121;
  color: #fff; }

.tooltip .tooltip-default.tooltip-top .tooltip-inner::before, .tooltip .tooltip-default.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #212121; }

.tooltip .tooltip-default.tooltip-right .tooltip-inner::before, .tooltip .tooltip-default.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #212121; }

.tooltip .tooltip-default.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-default.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #212121; }

.tooltip .tooltip-default.tooltip-left .tooltip-inner::before, .tooltip .tooltip-default.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #212121; }

.tooltip .tooltip-primary .tooltip-inner {
  border-radius: 5px;
  background-color: #303f9f;
  color: #fff; }

.tooltip .tooltip-primary.tooltip-top .tooltip-inner::before, .tooltip .tooltip-primary.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #303f9f; }

.tooltip .tooltip-primary.tooltip-right .tooltip-inner::before, .tooltip .tooltip-primary.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #303f9f; }

.tooltip .tooltip-primary.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-primary.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #303f9f; }

.tooltip .tooltip-primary.tooltip-left .tooltip-inner::before, .tooltip .tooltip-primary.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #303f9f; }

.tooltip .tooltip-secondary .tooltip-inner {
  border-radius: 5px;
  background-color: #7b1fa2;
  color: #fff; }

.tooltip .tooltip-secondary.tooltip-top .tooltip-inner::before, .tooltip .tooltip-secondary.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #7b1fa2; }

.tooltip .tooltip-secondary.tooltip-right .tooltip-inner::before, .tooltip .tooltip-secondary.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #7b1fa2; }

.tooltip .tooltip-secondary.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-secondary.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #7b1fa2; }

.tooltip .tooltip-secondary.tooltip-left .tooltip-inner::before, .tooltip .tooltip-secondary.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #7b1fa2; }

.tooltip .tooltip-info .tooltip-inner {
  border-radius: 5px;
  background-color: #0288d1;
  color: #fff; }

.tooltip .tooltip-info.tooltip-top .tooltip-inner::before, .tooltip .tooltip-info.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #0288d1; }

.tooltip .tooltip-info.tooltip-right .tooltip-inner::before, .tooltip .tooltip-info.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #0288d1; }

.tooltip .tooltip-info.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-info.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #0288d1; }

.tooltip .tooltip-info.tooltip-left .tooltip-inner::before, .tooltip .tooltip-info.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #0288d1; }

.tooltip .tooltip-success .tooltip-inner {
  border-radius: 5px;
  background-color: #388e3c;
  color: #fff; }

.tooltip .tooltip-success.tooltip-top .tooltip-inner::before, .tooltip .tooltip-success.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #388e3c; }

.tooltip .tooltip-success.tooltip-right .tooltip-inner::before, .tooltip .tooltip-success.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #388e3c; }

.tooltip .tooltip-success.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-success.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #388e3c; }

.tooltip .tooltip-success.tooltip-left .tooltip-inner::before, .tooltip .tooltip-success.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #388e3c; }

.tooltip .tooltip-warning .tooltip-inner {
  border-radius: 5px;
  background-color: #ffa000;
  color: #fff; }

.tooltip .tooltip-warning.tooltip-top .tooltip-inner::before, .tooltip .tooltip-warning.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #ffa000; }

.tooltip .tooltip-warning.tooltip-right .tooltip-inner::before, .tooltip .tooltip-warning.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #ffa000; }

.tooltip .tooltip-warning.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-warning.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #ffa000; }

.tooltip .tooltip-warning.tooltip-left .tooltip-inner::before, .tooltip .tooltip-warning.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #ffa000; }

.tooltip .tooltip-danger .tooltip-inner {
  border-radius: 5px;
  background-color: #d32f2f;
  color: #fff; }

.tooltip .tooltip-danger.tooltip-top .tooltip-inner::before, .tooltip .tooltip-danger.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #d32f2f; }

.tooltip .tooltip-danger.tooltip-right .tooltip-inner::before, .tooltip .tooltip-danger.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #d32f2f; }

.tooltip .tooltip-danger.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-danger.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #d32f2f; }

.tooltip .tooltip-danger.tooltip-left .tooltip-inner::before, .tooltip .tooltip-danger.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #d32f2f; }

.tooltip .tooltip-green .tooltip-inner {
  border-radius: 5px;
  background-color: #5d7e3d;
  color: #fff; }

.tooltip .tooltip-green.tooltip-top .tooltip-inner::before, .tooltip .tooltip-green.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #5d7e3d; }

.tooltip .tooltip-green.tooltip-right .tooltip-inner::before, .tooltip .tooltip-green.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #5d7e3d; }

.tooltip .tooltip-green.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-green.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #5d7e3d; }

.tooltip .tooltip-green.tooltip-left .tooltip-inner::before, .tooltip .tooltip-green.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #5d7e3d; }

.tooltip .tooltip-cyder-blue-1 .tooltip-inner {
  border-radius: 5px;
  background-color: #027ec1;
  color: #fff; }

.tooltip .tooltip-cyder-blue-1.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #027ec1; }

.tooltip .tooltip-cyder-blue-1.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #027ec1; }

.tooltip .tooltip-cyder-blue-1.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #027ec1; }

.tooltip .tooltip-cyder-blue-1.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #027ec1; }

.tooltip .tooltip-cyder-blue-2 .tooltip-inner {
  border-radius: 5px;
  background-color: #2bbed3;
  color: #fff; }

.tooltip .tooltip-cyder-blue-2.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-blue-2.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #2bbed3; }

.tooltip .tooltip-cyder-blue-2.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-blue-2.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #2bbed3; }

.tooltip .tooltip-cyder-blue-2.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-blue-2.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #2bbed3; }

.tooltip .tooltip-cyder-blue-2.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-blue-2.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #2bbed3; }

.tooltip .tooltip-cyder-green-1 .tooltip-inner {
  border-radius: 5px;
  background-color: #439b58;
  color: #fff; }

.tooltip .tooltip-cyder-green-1.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-green-1.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #439b58; }

.tooltip .tooltip-cyder-green-1.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-green-1.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #439b58; }

.tooltip .tooltip-cyder-green-1.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-green-1.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #439b58; }

.tooltip .tooltip-cyder-green-1.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-green-1.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #439b58; }

.tooltip .tooltip-cyder-green-2 .tooltip-inner {
  border-radius: 5px;
  background-color: #21401c;
  color: #fff; }

.tooltip .tooltip-cyder-green-2.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-green-2.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #21401c; }

.tooltip .tooltip-cyder-green-2.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-green-2.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #21401c; }

.tooltip .tooltip-cyder-green-2.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-green-2.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #21401c; }

.tooltip .tooltip-cyder-green-2.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-green-2.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #21401c; }

.tooltip .tooltip-cyder-orange-1 .tooltip-inner {
  border-radius: 5px;
  background-color: #ffab1a;
  color: #fff; }

.tooltip .tooltip-cyder-orange-1.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #ffab1a; }

.tooltip .tooltip-cyder-orange-1.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #ffab1a; }

.tooltip .tooltip-cyder-orange-1.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #ffab1a; }

.tooltip .tooltip-cyder-orange-1.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #ffab1a; }

.tooltip .tooltip-cyder-pink-1 .tooltip-inner {
  border-radius: 5px;
  background-color: #ec5a71;
  color: #fff; }

.tooltip .tooltip-cyder-pink-1.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #ec5a71; }

.tooltip .tooltip-cyder-pink-1.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #ec5a71; }

.tooltip .tooltip-cyder-pink-1.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #ec5a71; }

.tooltip .tooltip-cyder-pink-1.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #ec5a71; }

.tooltip .tooltip-cyder-yellow-1 .tooltip-inner {
  border-radius: 5px;
  background-color: #ffd351;
  color: #fff; }

.tooltip .tooltip-cyder-yellow-1.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-yellow-1.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #ffd351; }

.tooltip .tooltip-cyder-yellow-1.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-yellow-1.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #ffd351; }

.tooltip .tooltip-cyder-yellow-1.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-yellow-1.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #ffd351; }

.tooltip .tooltip-cyder-yellow-1.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-yellow-1.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #ffd351; }

.tooltip .tooltip-cyder-blue-1-darken .tooltip-inner {
  border-radius: 5px;
  background-color: #014d75;
  color: #fff; }

.tooltip .tooltip-cyder-blue-1-darken.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1-darken.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #014d75; }

.tooltip .tooltip-cyder-blue-1-darken.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1-darken.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #014d75; }

.tooltip .tooltip-cyder-blue-1-darken.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1-darken.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #014d75; }

.tooltip .tooltip-cyder-blue-1-darken.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-blue-1-darken.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #014d75; }

.tooltip .tooltip-cyder-green-1-darken .tooltip-inner {
  border-radius: 5px;
  background-color: #2c663a;
  color: #fff; }

.tooltip .tooltip-cyder-green-1-darken.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-green-1-darken.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #2c663a; }

.tooltip .tooltip-cyder-green-1-darken.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-green-1-darken.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #2c663a; }

.tooltip .tooltip-cyder-green-1-darken.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-green-1-darken.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #2c663a; }

.tooltip .tooltip-cyder-green-1-darken.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-green-1-darken.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #2c663a; }

.tooltip .tooltip-cyder-green-2-darken .tooltip-inner {
  border-radius: 5px;
  background-color: #060b05;
  color: #fff; }

.tooltip .tooltip-cyder-green-2-darken.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-green-2-darken.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #060b05; }

.tooltip .tooltip-cyder-green-2-darken.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-green-2-darken.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #060b05; }

.tooltip .tooltip-cyder-green-2-darken.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-green-2-darken.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #060b05; }

.tooltip .tooltip-cyder-green-2-darken.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-green-2-darken.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #060b05; }

.tooltip .tooltip-cyder-orange-1-darken .tooltip-inner {
  border-radius: 5px;
  background-color: #cd8100;
  color: #fff; }

.tooltip .tooltip-cyder-orange-1-darken.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1-darken.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #cd8100; }

.tooltip .tooltip-cyder-orange-1-darken.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1-darken.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #cd8100; }

.tooltip .tooltip-cyder-orange-1-darken.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1-darken.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #cd8100; }

.tooltip .tooltip-cyder-orange-1-darken.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-orange-1-darken.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #cd8100; }

.tooltip .tooltip-c-lighttile-darken .tooltip-inner {
  border-radius: 5px;
  background-color: #40a9ab;
  color: #fff; }

.tooltip .tooltip-c-lighttile-darken.tooltip-top .tooltip-inner::before, .tooltip .tooltip-c-lighttile-darken.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #40a9ab; }

.tooltip .tooltip-c-lighttile-darken.tooltip-right .tooltip-inner::before, .tooltip .tooltip-c-lighttile-darken.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #40a9ab; }

.tooltip .tooltip-c-lighttile-darken.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-c-lighttile-darken.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #40a9ab; }

.tooltip .tooltip-c-lighttile-darken.tooltip-left .tooltip-inner::before, .tooltip .tooltip-c-lighttile-darken.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #40a9ab; }

.tooltip .tooltip-cyder-pink-1-darken .tooltip-inner {
  border-radius: 5px;
  background-color: #e01a39;
  color: #fff; }

.tooltip .tooltip-cyder-pink-1-darken.tooltip-top .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1-darken.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #e01a39; }

.tooltip .tooltip-cyder-pink-1-darken.tooltip-right .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1-darken.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #e01a39; }

.tooltip .tooltip-cyder-pink-1-darken.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1-darken.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #e01a39; }

.tooltip .tooltip-cyder-pink-1-darken.tooltip-left .tooltip-inner::before, .tooltip .tooltip-cyder-pink-1-darken.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #e01a39; }

.tooltip .tooltip-c-newdark .tooltip-inner {
  border-radius: 5px;
  background-color: #313b3d;
  color: #fff; }

.tooltip .tooltip-c-newdark.tooltip-top .tooltip-inner::before, .tooltip .tooltip-c-newdark.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #313b3d; }

.tooltip .tooltip-c-newdark.tooltip-right .tooltip-inner::before, .tooltip .tooltip-c-newdark.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #313b3d; }

.tooltip .tooltip-c-newdark.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-c-newdark.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #313b3d; }

.tooltip .tooltip-c-newdark.tooltip-left .tooltip-inner::before, .tooltip .tooltip-c-newdark.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #313b3d; }

.tooltip .tooltip-c-lightblue .tooltip-inner {
  border-radius: 5px;
  background-color: #78adc9;
  color: #fff; }

.tooltip .tooltip-c-lightblue.tooltip-top .tooltip-inner::before, .tooltip .tooltip-c-lightblue.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #78adc9; }

.tooltip .tooltip-c-lightblue.tooltip-right .tooltip-inner::before, .tooltip .tooltip-c-lightblue.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #78adc9; }

.tooltip .tooltip-c-lightblue.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-c-lightblue.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #78adc9; }

.tooltip .tooltip-c-lightblue.tooltip-left .tooltip-inner::before, .tooltip .tooltip-c-lightblue.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #78adc9; }

.tooltip .tooltip-c-lighttile .tooltip-inner {
  border-radius: 5px;
  background-color: #6fc7c9;
  color: #fff; }

.tooltip .tooltip-c-lighttile.tooltip-top .tooltip-inner::before, .tooltip .tooltip-c-lighttile.bs-tether-element-attached-bottom .tooltip-inner::before {
  border-top-color: #6fc7c9; }

.tooltip .tooltip-c-lighttile.tooltip-right .tooltip-inner::before, .tooltip .tooltip-c-lighttile.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: #6fc7c9; }

.tooltip .tooltip-c-lighttile.tooltip-bottom .tooltip-inner::before, .tooltip .tooltip-c-lighttile.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: #6fc7c9; }

.tooltip .tooltip-c-lighttile.tooltip-left .tooltip-inner::before, .tooltip .tooltip-c-lighttile.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: #6fc7c9; }
