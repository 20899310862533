.ReactTable {
  border: none; }
  .ReactTable .rt-thead.-header {
    -webkit-box-shadow: none;
    border-bottom: 2px solid #eeeeee;
    box-shadow: none;
    font-weight: bold; }
  .ReactTable .rt-thead .rt-tr {
    text-align: left; }
  .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: none; }
  .ReactTable .rt-tbody .rt-td {
    border-right: none; }
  .ReactTable .rt-thead .rt-th.-sort-desc.rt-no-sort-ind, .ReactTable .rt-thead .rt-td.-sort-desc.rt-no-sort-ind, .ReactTable .rt-thead .rt-th.-sort-asc.rt-no-sort-ind, .ReactTable .rt-thead .rt-td.-sort-asc.rt-no-sort-ind {
    webkit-box-shadow: none;
    box-shadow: none; }
