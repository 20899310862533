.sample-badges .badge {
  margin-right: 10px; }

.badge {
  border-radius: 8px;
  border: 0;
  font-size: 0.75rem;
  text-align: center;
  line-height: 0.8;
  padding: 8px 12px;
  font-weight: normal; }
  .badge.badge-sm {
    font-size: 0.625rem;
    line-height: 0.6; }
  .badge.badge-lg {
    font-size: 0.875rem;
    line-height: 1; }
  .badge.badge-rounded {
    padding: 0;
    height: 24px;
    width: 24px;
    line-height: 24px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle; }
    .badge.badge-rounded.badge-sm {
      height: 18px;
      width: 18px;
      line-height: 18px;
      border-radius: 50%; }
      .badge.badge-rounded.badge-sm.badge-outline {
        line-height: 16px; }
    .badge.badge-rounded.badge-lg {
      height: 30px;
      width: 30px;
      line-height: 30px;
      border-radius: 50%; }
      .badge.badge-rounded.badge-lg.badge-outline {
        line-height: 28px; }
  .badge.badge-light:not(.badge-outline) {
    background-color: #ffffff;
    color: #fff; }
  .badge.badge-outline.badge-light {
    border: 1px solid #ffffff;
    background-color: transparent;
    color: #ffffff; }
  .badge.badge-dark:not(.badge-outline) {
    background-color: #424242;
    color: #fff; }
  .badge.badge-outline.badge-dark {
    border: 1px solid #424242;
    background-color: transparent;
    color: #424242; }
  .badge.badge-grey:not(.badge-outline) {
    background-color: #63666a;
    color: #fff; }
  .badge.badge-outline.badge-grey {
    border: 1px solid #63666a;
    background-color: transparent;
    color: #63666a; }
  .badge.badge-sgbc:not(.badge-outline) {
    background-color: #63666a;
    color: #fff; }
  .badge.badge-outline.badge-sgbc {
    border: 1px solid #63666a;
    background-color: transparent;
    color: #63666a; }
  .badge.badge-default:not(.badge-outline) {
    background-color: #212121;
    color: #fff; }
  .badge.badge-outline.badge-default {
    border: 1px solid #212121;
    background-color: transparent;
    color: #212121; }
  .badge.badge-primary:not(.badge-outline) {
    background-color: #303f9f;
    color: #fff; }
  .badge.badge-outline.badge-primary {
    border: 1px solid #303f9f;
    background-color: transparent;
    color: #303f9f; }
  .badge.badge-secondary:not(.badge-outline) {
    background-color: #7b1fa2;
    color: #fff; }
  .badge.badge-outline.badge-secondary {
    border: 1px solid #7b1fa2;
    background-color: transparent;
    color: #7b1fa2; }
  .badge.badge-info:not(.badge-outline) {
    background-color: #0288d1;
    color: #fff; }
  .badge.badge-outline.badge-info {
    border: 1px solid #0288d1;
    background-color: transparent;
    color: #0288d1; }
  .badge.badge-success:not(.badge-outline) {
    background-color: #388e3c;
    color: #fff; }
  .badge.badge-outline.badge-success {
    border: 1px solid #388e3c;
    background-color: transparent;
    color: #388e3c; }
  .badge.badge-warning:not(.badge-outline) {
    background-color: #ffa000;
    color: #fff; }
  .badge.badge-outline.badge-warning {
    border: 1px solid #ffa000;
    background-color: transparent;
    color: #ffa000; }
  .badge.badge-danger:not(.badge-outline) {
    background-color: #d32f2f;
    color: #fff; }
  .badge.badge-outline.badge-danger {
    border: 1px solid #d32f2f;
    background-color: transparent;
    color: #d32f2f; }
  .badge.badge-green:not(.badge-outline) {
    background-color: #5d7e3d;
    color: #fff; }
  .badge.badge-outline.badge-green {
    border: 1px solid #5d7e3d;
    background-color: transparent;
    color: #5d7e3d; }
  .badge.badge-cyder-blue-1:not(.badge-outline) {
    background-color: #027ec1;
    color: #fff; }
  .badge.badge-outline.badge-cyder-blue-1 {
    border: 1px solid #027ec1;
    background-color: transparent;
    color: #027ec1; }
  .badge.badge-cyder-blue-2:not(.badge-outline) {
    background-color: #2bbed3;
    color: #fff; }
  .badge.badge-outline.badge-cyder-blue-2 {
    border: 1px solid #2bbed3;
    background-color: transparent;
    color: #2bbed3; }
  .badge.badge-cyder-green-1:not(.badge-outline) {
    background-color: #439b58;
    color: #fff; }
  .badge.badge-outline.badge-cyder-green-1 {
    border: 1px solid #439b58;
    background-color: transparent;
    color: #439b58; }
  .badge.badge-cyder-green-2:not(.badge-outline) {
    background-color: #21401c;
    color: #fff; }
  .badge.badge-outline.badge-cyder-green-2 {
    border: 1px solid #21401c;
    background-color: transparent;
    color: #21401c; }
  .badge.badge-cyder-orange-1:not(.badge-outline) {
    background-color: #ffab1a;
    color: #fff; }
  .badge.badge-outline.badge-cyder-orange-1 {
    border: 1px solid #ffab1a;
    background-color: transparent;
    color: #ffab1a; }
  .badge.badge-cyder-pink-1:not(.badge-outline) {
    background-color: #ec5a71;
    color: #fff; }
  .badge.badge-outline.badge-cyder-pink-1 {
    border: 1px solid #ec5a71;
    background-color: transparent;
    color: #ec5a71; }
  .badge.badge-cyder-yellow-1:not(.badge-outline) {
    background-color: #ffd351;
    color: #fff; }
  .badge.badge-outline.badge-cyder-yellow-1 {
    border: 1px solid #ffd351;
    background-color: transparent;
    color: #ffd351; }
  .badge.badge-cyder-blue-1-darken:not(.badge-outline) {
    background-color: #014d75;
    color: #fff; }
  .badge.badge-outline.badge-cyder-blue-1-darken {
    border: 1px solid #014d75;
    background-color: transparent;
    color: #014d75; }
  .badge.badge-cyder-green-1-darken:not(.badge-outline) {
    background-color: #2c663a;
    color: #fff; }
  .badge.badge-outline.badge-cyder-green-1-darken {
    border: 1px solid #2c663a;
    background-color: transparent;
    color: #2c663a; }
  .badge.badge-cyder-green-2-darken:not(.badge-outline) {
    background-color: #060b05;
    color: #fff; }
  .badge.badge-outline.badge-cyder-green-2-darken {
    border: 1px solid #060b05;
    background-color: transparent;
    color: #060b05; }
  .badge.badge-cyder-orange-1-darken:not(.badge-outline) {
    background-color: #cd8100;
    color: #fff; }
  .badge.badge-outline.badge-cyder-orange-1-darken {
    border: 1px solid #cd8100;
    background-color: transparent;
    color: #cd8100; }
  .badge.badge-c-lighttile-darken:not(.badge-outline) {
    background-color: #40a9ab;
    color: #fff; }
  .badge.badge-outline.badge-c-lighttile-darken {
    border: 1px solid #40a9ab;
    background-color: transparent;
    color: #40a9ab; }
  .badge.badge-cyder-pink-1-darken:not(.badge-outline) {
    background-color: #e01a39;
    color: #fff; }
  .badge.badge-outline.badge-cyder-pink-1-darken {
    border: 1px solid #e01a39;
    background-color: transparent;
    color: #e01a39; }
  .badge.badge-c-newdark:not(.badge-outline) {
    background-color: #313b3d;
    color: #fff; }
  .badge.badge-outline.badge-c-newdark {
    border: 1px solid #313b3d;
    background-color: transparent;
    color: #313b3d; }
  .badge.badge-c-lightblue:not(.badge-outline) {
    background-color: #78adc9;
    color: #fff; }
  .badge.badge-outline.badge-c-lightblue {
    border: 1px solid #78adc9;
    background-color: transparent;
    color: #78adc9; }
  .badge.badge-c-lighttile:not(.badge-outline) {
    background-color: #6fc7c9;
    color: #fff; }
  .badge.badge-outline.badge-c-lighttile {
    border: 1px solid #6fc7c9;
    background-color: transparent;
    color: #6fc7c9; }
