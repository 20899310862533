.demo-buttons .btn {
  margin-right: 5px;
  margin-bottom: 10px; }

.btn {
  font-weight: normal; }
  .btn.btn-icon {
    vertical-align: middle;
    display: inline-flex; }
    .btn.btn-icon i {
      margin-right: 5px; }
  .btn.btn-sm {
    font-size: 0.75rem; }
  .btn.btn-light, .btn.btn-light.dropdown-toggle {
    background: #ffffff;
    color: #fff;
    border-color: #ffffff;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-light:hover, .btn.btn-light:active, .btn.btn-light:focus, .btn.btn-light:visited, .btn.btn-light.focus, .btn.btn-light.dropdown-toggle:hover, .btn.btn-light.dropdown-toggle:active, .btn.btn-light.dropdown-toggle:focus, .btn.btn-light.dropdown-toggle:visited, .btn.btn-light.dropdown-toggle.focus {
      color: #fff;
      background: #ebebeb;
      border-color: #ebebeb; }
    .btn.btn-light.btn-raised, .btn.btn-light.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-light.btn-rounded, .btn.btn-light.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-light.btn-outline, .btn.btn-light.dropdown-toggle.btn-outline {
      background: none;
      color: #ffffff;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-light.btn-outline:hover, .btn.btn-light.btn-outline:active, .btn.btn-light.btn-outline:focus, .btn.btn-light.btn-outline:visited, .btn.btn-light.dropdown-toggle.btn-outline:hover, .btn.btn-light.dropdown-toggle.btn-outline:active, .btn.btn-light.dropdown-toggle.btn-outline:focus, .btn.btn-light.dropdown-toggle.btn-outline:visited {
        color: #ebebeb;
        border-color: #ebebeb; }
      .btn.btn-light.btn-outline.btn-rounded, .btn.btn-light.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-light.btn-flat, .btn.btn-light.dropdown-toggle.btn-flat {
      color: #ffffff; }
      .btn.btn-light.btn-flat:hover, .btn.btn-light.btn-flat:active, .btn.btn-light.btn-flat:focus, .btn.btn-light.btn-flat:visited, .btn.btn-light.dropdown-toggle.btn-flat:hover, .btn.btn-light.dropdown-toggle.btn-flat:active, .btn.btn-light.dropdown-toggle.btn-flat:focus, .btn.btn-light.dropdown-toggle.btn-flat:visited {
        color: #ebebeb; }
    .btn.btn-light.btn-circle, .btn.btn-light.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-light.btn-circle.btn-outline, .btn.btn-light.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-light.btn-circle i, .btn.btn-light.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-light.btn-circle.btn-xs, .btn.btn-light.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-light.btn-circle.btn-sm, .btn.btn-light.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-light.btn-circle.btn-lg, .btn.btn-light.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-dark, .btn.btn-dark.dropdown-toggle {
    background: #424242;
    color: #fff;
    border-color: #424242;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-dark:hover, .btn.btn-dark:active, .btn.btn-dark:focus, .btn.btn-dark:visited, .btn.btn-dark.focus, .btn.btn-dark.dropdown-toggle:hover, .btn.btn-dark.dropdown-toggle:active, .btn.btn-dark.dropdown-toggle:focus, .btn.btn-dark.dropdown-toggle:visited, .btn.btn-dark.dropdown-toggle.focus {
      color: #fff;
      background: #2e2e2e;
      border-color: #2e2e2e; }
    .btn.btn-dark.btn-raised, .btn.btn-dark.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-dark.btn-rounded, .btn.btn-dark.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-dark.btn-outline, .btn.btn-dark.dropdown-toggle.btn-outline {
      background: none;
      color: #424242;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-dark.btn-outline:hover, .btn.btn-dark.btn-outline:active, .btn.btn-dark.btn-outline:focus, .btn.btn-dark.btn-outline:visited, .btn.btn-dark.dropdown-toggle.btn-outline:hover, .btn.btn-dark.dropdown-toggle.btn-outline:active, .btn.btn-dark.dropdown-toggle.btn-outline:focus, .btn.btn-dark.dropdown-toggle.btn-outline:visited {
        color: #2e2e2e;
        border-color: #2e2e2e; }
      .btn.btn-dark.btn-outline.btn-rounded, .btn.btn-dark.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-dark.btn-flat, .btn.btn-dark.dropdown-toggle.btn-flat {
      color: #424242; }
      .btn.btn-dark.btn-flat:hover, .btn.btn-dark.btn-flat:active, .btn.btn-dark.btn-flat:focus, .btn.btn-dark.btn-flat:visited, .btn.btn-dark.dropdown-toggle.btn-flat:hover, .btn.btn-dark.dropdown-toggle.btn-flat:active, .btn.btn-dark.dropdown-toggle.btn-flat:focus, .btn.btn-dark.dropdown-toggle.btn-flat:visited {
        color: #2e2e2e; }
    .btn.btn-dark.btn-circle, .btn.btn-dark.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-dark.btn-circle.btn-outline, .btn.btn-dark.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-dark.btn-circle i, .btn.btn-dark.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-dark.btn-circle.btn-xs, .btn.btn-dark.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-dark.btn-circle.btn-sm, .btn.btn-dark.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-dark.btn-circle.btn-lg, .btn.btn-dark.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-grey, .btn.btn-grey.dropdown-toggle {
    background: #63666a;
    color: #fff;
    border-color: #63666a;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-grey:hover, .btn.btn-grey:active, .btn.btn-grey:focus, .btn.btn-grey:visited, .btn.btn-grey.focus, .btn.btn-grey.dropdown-toggle:hover, .btn.btn-grey.dropdown-toggle:active, .btn.btn-grey.dropdown-toggle:focus, .btn.btn-grey.dropdown-toggle:visited, .btn.btn-grey.dropdown-toggle.focus {
      color: #fff;
      background: #4f5255;
      border-color: #4f5255; }
    .btn.btn-grey.btn-raised, .btn.btn-grey.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-grey.btn-rounded, .btn.btn-grey.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-grey.btn-outline, .btn.btn-grey.dropdown-toggle.btn-outline {
      background: none;
      color: #63666a;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-grey.btn-outline:hover, .btn.btn-grey.btn-outline:active, .btn.btn-grey.btn-outline:focus, .btn.btn-grey.btn-outline:visited, .btn.btn-grey.dropdown-toggle.btn-outline:hover, .btn.btn-grey.dropdown-toggle.btn-outline:active, .btn.btn-grey.dropdown-toggle.btn-outline:focus, .btn.btn-grey.dropdown-toggle.btn-outline:visited {
        color: #4f5255;
        border-color: #4f5255; }
      .btn.btn-grey.btn-outline.btn-rounded, .btn.btn-grey.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-grey.btn-flat, .btn.btn-grey.dropdown-toggle.btn-flat {
      color: #63666a; }
      .btn.btn-grey.btn-flat:hover, .btn.btn-grey.btn-flat:active, .btn.btn-grey.btn-flat:focus, .btn.btn-grey.btn-flat:visited, .btn.btn-grey.dropdown-toggle.btn-flat:hover, .btn.btn-grey.dropdown-toggle.btn-flat:active, .btn.btn-grey.dropdown-toggle.btn-flat:focus, .btn.btn-grey.dropdown-toggle.btn-flat:visited {
        color: #4f5255; }
    .btn.btn-grey.btn-circle, .btn.btn-grey.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-grey.btn-circle.btn-outline, .btn.btn-grey.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-grey.btn-circle i, .btn.btn-grey.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-grey.btn-circle.btn-xs, .btn.btn-grey.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-grey.btn-circle.btn-sm, .btn.btn-grey.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-grey.btn-circle.btn-lg, .btn.btn-grey.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-sgbc, .btn.btn-sgbc.dropdown-toggle {
    background: #63666a;
    color: #fff;
    border-color: #63666a;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-sgbc:hover, .btn.btn-sgbc:active, .btn.btn-sgbc:focus, .btn.btn-sgbc:visited, .btn.btn-sgbc.focus, .btn.btn-sgbc.dropdown-toggle:hover, .btn.btn-sgbc.dropdown-toggle:active, .btn.btn-sgbc.dropdown-toggle:focus, .btn.btn-sgbc.dropdown-toggle:visited, .btn.btn-sgbc.dropdown-toggle.focus {
      color: #fff;
      background: #4f5255;
      border-color: #4f5255; }
    .btn.btn-sgbc.btn-raised, .btn.btn-sgbc.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-sgbc.btn-rounded, .btn.btn-sgbc.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-sgbc.btn-outline, .btn.btn-sgbc.dropdown-toggle.btn-outline {
      background: none;
      color: #63666a;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-sgbc.btn-outline:hover, .btn.btn-sgbc.btn-outline:active, .btn.btn-sgbc.btn-outline:focus, .btn.btn-sgbc.btn-outline:visited, .btn.btn-sgbc.dropdown-toggle.btn-outline:hover, .btn.btn-sgbc.dropdown-toggle.btn-outline:active, .btn.btn-sgbc.dropdown-toggle.btn-outline:focus, .btn.btn-sgbc.dropdown-toggle.btn-outline:visited {
        color: #4f5255;
        border-color: #4f5255; }
      .btn.btn-sgbc.btn-outline.btn-rounded, .btn.btn-sgbc.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-sgbc.btn-flat, .btn.btn-sgbc.dropdown-toggle.btn-flat {
      color: #63666a; }
      .btn.btn-sgbc.btn-flat:hover, .btn.btn-sgbc.btn-flat:active, .btn.btn-sgbc.btn-flat:focus, .btn.btn-sgbc.btn-flat:visited, .btn.btn-sgbc.dropdown-toggle.btn-flat:hover, .btn.btn-sgbc.dropdown-toggle.btn-flat:active, .btn.btn-sgbc.dropdown-toggle.btn-flat:focus, .btn.btn-sgbc.dropdown-toggle.btn-flat:visited {
        color: #4f5255; }
    .btn.btn-sgbc.btn-circle, .btn.btn-sgbc.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-sgbc.btn-circle.btn-outline, .btn.btn-sgbc.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-sgbc.btn-circle i, .btn.btn-sgbc.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-sgbc.btn-circle.btn-xs, .btn.btn-sgbc.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-sgbc.btn-circle.btn-sm, .btn.btn-sgbc.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-sgbc.btn-circle.btn-lg, .btn.btn-sgbc.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-default, .btn.btn-default.dropdown-toggle {
    background: #212121;
    color: #fff;
    border-color: #212121;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-default:hover, .btn.btn-default:active, .btn.btn-default:focus, .btn.btn-default:visited, .btn.btn-default.focus, .btn.btn-default.dropdown-toggle:hover, .btn.btn-default.dropdown-toggle:active, .btn.btn-default.dropdown-toggle:focus, .btn.btn-default.dropdown-toggle:visited, .btn.btn-default.dropdown-toggle.focus {
      color: #fff;
      background: #0d0d0d;
      border-color: #0d0d0d; }
    .btn.btn-default.btn-raised, .btn.btn-default.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-default.btn-rounded, .btn.btn-default.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-default.btn-outline, .btn.btn-default.dropdown-toggle.btn-outline {
      background: none;
      color: #212121;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-default.btn-outline:hover, .btn.btn-default.btn-outline:active, .btn.btn-default.btn-outline:focus, .btn.btn-default.btn-outline:visited, .btn.btn-default.dropdown-toggle.btn-outline:hover, .btn.btn-default.dropdown-toggle.btn-outline:active, .btn.btn-default.dropdown-toggle.btn-outline:focus, .btn.btn-default.dropdown-toggle.btn-outline:visited {
        color: #0d0d0d;
        border-color: #0d0d0d; }
      .btn.btn-default.btn-outline.btn-rounded, .btn.btn-default.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-default.btn-flat, .btn.btn-default.dropdown-toggle.btn-flat {
      color: #212121; }
      .btn.btn-default.btn-flat:hover, .btn.btn-default.btn-flat:active, .btn.btn-default.btn-flat:focus, .btn.btn-default.btn-flat:visited, .btn.btn-default.dropdown-toggle.btn-flat:hover, .btn.btn-default.dropdown-toggle.btn-flat:active, .btn.btn-default.dropdown-toggle.btn-flat:focus, .btn.btn-default.dropdown-toggle.btn-flat:visited {
        color: #0d0d0d; }
    .btn.btn-default.btn-circle, .btn.btn-default.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-default.btn-circle.btn-outline, .btn.btn-default.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-default.btn-circle i, .btn.btn-default.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-default.btn-circle.btn-xs, .btn.btn-default.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-default.btn-circle.btn-sm, .btn.btn-default.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-default.btn-circle.btn-lg, .btn.btn-default.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-primary, .btn.btn-primary.dropdown-toggle {
    background: #303f9f;
    color: #fff;
    border-color: #303f9f;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus, .btn.btn-primary:visited, .btn.btn-primary.focus, .btn.btn-primary.dropdown-toggle:hover, .btn.btn-primary.dropdown-toggle:active, .btn.btn-primary.dropdown-toggle:focus, .btn.btn-primary.dropdown-toggle:visited, .btn.btn-primary.dropdown-toggle.focus {
      color: #fff;
      background: #273380;
      border-color: #273380; }
    .btn.btn-primary.btn-raised, .btn.btn-primary.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-primary.btn-rounded, .btn.btn-primary.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-primary.btn-outline, .btn.btn-primary.dropdown-toggle.btn-outline {
      background: none;
      color: #303f9f;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-primary.btn-outline:hover, .btn.btn-primary.btn-outline:active, .btn.btn-primary.btn-outline:focus, .btn.btn-primary.btn-outline:visited, .btn.btn-primary.dropdown-toggle.btn-outline:hover, .btn.btn-primary.dropdown-toggle.btn-outline:active, .btn.btn-primary.dropdown-toggle.btn-outline:focus, .btn.btn-primary.dropdown-toggle.btn-outline:visited {
        color: #273380;
        border-color: #273380; }
      .btn.btn-primary.btn-outline.btn-rounded, .btn.btn-primary.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-primary.btn-flat, .btn.btn-primary.dropdown-toggle.btn-flat {
      color: #303f9f; }
      .btn.btn-primary.btn-flat:hover, .btn.btn-primary.btn-flat:active, .btn.btn-primary.btn-flat:focus, .btn.btn-primary.btn-flat:visited, .btn.btn-primary.dropdown-toggle.btn-flat:hover, .btn.btn-primary.dropdown-toggle.btn-flat:active, .btn.btn-primary.dropdown-toggle.btn-flat:focus, .btn.btn-primary.dropdown-toggle.btn-flat:visited {
        color: #273380; }
    .btn.btn-primary.btn-circle, .btn.btn-primary.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-primary.btn-circle.btn-outline, .btn.btn-primary.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-primary.btn-circle i, .btn.btn-primary.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-primary.btn-circle.btn-xs, .btn.btn-primary.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-primary.btn-circle.btn-sm, .btn.btn-primary.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-primary.btn-circle.btn-lg, .btn.btn-primary.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-secondary, .btn.btn-secondary.dropdown-toggle {
    background: #7b1fa2;
    color: #fff;
    border-color: #7b1fa2;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-secondary:hover, .btn.btn-secondary:active, .btn.btn-secondary:focus, .btn.btn-secondary:visited, .btn.btn-secondary.focus, .btn.btn-secondary.dropdown-toggle:hover, .btn.btn-secondary.dropdown-toggle:active, .btn.btn-secondary.dropdown-toggle:focus, .btn.btn-secondary.dropdown-toggle:visited, .btn.btn-secondary.dropdown-toggle.focus {
      color: #fff;
      background: #611880;
      border-color: #611880; }
    .btn.btn-secondary.btn-raised, .btn.btn-secondary.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-secondary.btn-rounded, .btn.btn-secondary.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-secondary.btn-outline, .btn.btn-secondary.dropdown-toggle.btn-outline {
      background: none;
      color: #7b1fa2;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-secondary.btn-outline:hover, .btn.btn-secondary.btn-outline:active, .btn.btn-secondary.btn-outline:focus, .btn.btn-secondary.btn-outline:visited, .btn.btn-secondary.dropdown-toggle.btn-outline:hover, .btn.btn-secondary.dropdown-toggle.btn-outline:active, .btn.btn-secondary.dropdown-toggle.btn-outline:focus, .btn.btn-secondary.dropdown-toggle.btn-outline:visited {
        color: #611880;
        border-color: #611880; }
      .btn.btn-secondary.btn-outline.btn-rounded, .btn.btn-secondary.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-secondary.btn-flat, .btn.btn-secondary.dropdown-toggle.btn-flat {
      color: #7b1fa2; }
      .btn.btn-secondary.btn-flat:hover, .btn.btn-secondary.btn-flat:active, .btn.btn-secondary.btn-flat:focus, .btn.btn-secondary.btn-flat:visited, .btn.btn-secondary.dropdown-toggle.btn-flat:hover, .btn.btn-secondary.dropdown-toggle.btn-flat:active, .btn.btn-secondary.dropdown-toggle.btn-flat:focus, .btn.btn-secondary.dropdown-toggle.btn-flat:visited {
        color: #611880; }
    .btn.btn-secondary.btn-circle, .btn.btn-secondary.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-secondary.btn-circle.btn-outline, .btn.btn-secondary.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-secondary.btn-circle i, .btn.btn-secondary.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-secondary.btn-circle.btn-xs, .btn.btn-secondary.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-secondary.btn-circle.btn-sm, .btn.btn-secondary.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-secondary.btn-circle.btn-lg, .btn.btn-secondary.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-info, .btn.btn-info.dropdown-toggle {
    background: #0288d1;
    color: #fff;
    border-color: #0288d1;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-info:hover, .btn.btn-info:active, .btn.btn-info:focus, .btn.btn-info:visited, .btn.btn-info.focus, .btn.btn-info.dropdown-toggle:hover, .btn.btn-info.dropdown-toggle:active, .btn.btn-info.dropdown-toggle:focus, .btn.btn-info.dropdown-toggle:visited, .btn.btn-info.dropdown-toggle.focus {
      color: #fff;
      background: #026ea9;
      border-color: #026ea9; }
    .btn.btn-info.btn-raised, .btn.btn-info.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-info.btn-rounded, .btn.btn-info.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-info.btn-outline, .btn.btn-info.dropdown-toggle.btn-outline {
      background: none;
      color: #0288d1;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-info.btn-outline:hover, .btn.btn-info.btn-outline:active, .btn.btn-info.btn-outline:focus, .btn.btn-info.btn-outline:visited, .btn.btn-info.dropdown-toggle.btn-outline:hover, .btn.btn-info.dropdown-toggle.btn-outline:active, .btn.btn-info.dropdown-toggle.btn-outline:focus, .btn.btn-info.dropdown-toggle.btn-outline:visited {
        color: #026ea9;
        border-color: #026ea9; }
      .btn.btn-info.btn-outline.btn-rounded, .btn.btn-info.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-info.btn-flat, .btn.btn-info.dropdown-toggle.btn-flat {
      color: #0288d1; }
      .btn.btn-info.btn-flat:hover, .btn.btn-info.btn-flat:active, .btn.btn-info.btn-flat:focus, .btn.btn-info.btn-flat:visited, .btn.btn-info.dropdown-toggle.btn-flat:hover, .btn.btn-info.dropdown-toggle.btn-flat:active, .btn.btn-info.dropdown-toggle.btn-flat:focus, .btn.btn-info.dropdown-toggle.btn-flat:visited {
        color: #026ea9; }
    .btn.btn-info.btn-circle, .btn.btn-info.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-info.btn-circle.btn-outline, .btn.btn-info.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-info.btn-circle i, .btn.btn-info.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-info.btn-circle.btn-xs, .btn.btn-info.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-info.btn-circle.btn-sm, .btn.btn-info.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-info.btn-circle.btn-lg, .btn.btn-info.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-success, .btn.btn-success.dropdown-toggle {
    background: #388e3c;
    color: #fff;
    border-color: #388e3c;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-success:hover, .btn.btn-success:active, .btn.btn-success:focus, .btn.btn-success:visited, .btn.btn-success.focus, .btn.btn-success.dropdown-toggle:hover, .btn.btn-success.dropdown-toggle:active, .btn.btn-success.dropdown-toggle:focus, .btn.btn-success.dropdown-toggle:visited, .btn.btn-success.dropdown-toggle.focus {
      color: #fff;
      background: #2c7130;
      border-color: #2c7130; }
    .btn.btn-success.btn-raised, .btn.btn-success.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-success.btn-rounded, .btn.btn-success.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-success.btn-outline, .btn.btn-success.dropdown-toggle.btn-outline {
      background: none;
      color: #388e3c;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-success.btn-outline:hover, .btn.btn-success.btn-outline:active, .btn.btn-success.btn-outline:focus, .btn.btn-success.btn-outline:visited, .btn.btn-success.dropdown-toggle.btn-outline:hover, .btn.btn-success.dropdown-toggle.btn-outline:active, .btn.btn-success.dropdown-toggle.btn-outline:focus, .btn.btn-success.dropdown-toggle.btn-outline:visited {
        color: #2c7130;
        border-color: #2c7130; }
      .btn.btn-success.btn-outline.btn-rounded, .btn.btn-success.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-success.btn-flat, .btn.btn-success.dropdown-toggle.btn-flat {
      color: #388e3c; }
      .btn.btn-success.btn-flat:hover, .btn.btn-success.btn-flat:active, .btn.btn-success.btn-flat:focus, .btn.btn-success.btn-flat:visited, .btn.btn-success.dropdown-toggle.btn-flat:hover, .btn.btn-success.dropdown-toggle.btn-flat:active, .btn.btn-success.dropdown-toggle.btn-flat:focus, .btn.btn-success.dropdown-toggle.btn-flat:visited {
        color: #2c7130; }
    .btn.btn-success.btn-circle, .btn.btn-success.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-success.btn-circle.btn-outline, .btn.btn-success.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-success.btn-circle i, .btn.btn-success.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-success.btn-circle.btn-xs, .btn.btn-success.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-success.btn-circle.btn-sm, .btn.btn-success.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-success.btn-circle.btn-lg, .btn.btn-success.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-warning, .btn.btn-warning.dropdown-toggle {
    background: #ffa000;
    color: #fff;
    border-color: #ffa000;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-warning:hover, .btn.btn-warning:active, .btn.btn-warning:focus, .btn.btn-warning:visited, .btn.btn-warning.focus, .btn.btn-warning.dropdown-toggle:hover, .btn.btn-warning.dropdown-toggle:active, .btn.btn-warning.dropdown-toggle:focus, .btn.btn-warning.dropdown-toggle:visited, .btn.btn-warning.dropdown-toggle.focus {
      color: #fff;
      background: #d68600;
      border-color: #d68600; }
    .btn.btn-warning.btn-raised, .btn.btn-warning.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-warning.btn-rounded, .btn.btn-warning.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-warning.btn-outline, .btn.btn-warning.dropdown-toggle.btn-outline {
      background: none;
      color: #ffa000;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-warning.btn-outline:hover, .btn.btn-warning.btn-outline:active, .btn.btn-warning.btn-outline:focus, .btn.btn-warning.btn-outline:visited, .btn.btn-warning.dropdown-toggle.btn-outline:hover, .btn.btn-warning.dropdown-toggle.btn-outline:active, .btn.btn-warning.dropdown-toggle.btn-outline:focus, .btn.btn-warning.dropdown-toggle.btn-outline:visited {
        color: #d68600;
        border-color: #d68600; }
      .btn.btn-warning.btn-outline.btn-rounded, .btn.btn-warning.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-warning.btn-flat, .btn.btn-warning.dropdown-toggle.btn-flat {
      color: #ffa000; }
      .btn.btn-warning.btn-flat:hover, .btn.btn-warning.btn-flat:active, .btn.btn-warning.btn-flat:focus, .btn.btn-warning.btn-flat:visited, .btn.btn-warning.dropdown-toggle.btn-flat:hover, .btn.btn-warning.dropdown-toggle.btn-flat:active, .btn.btn-warning.dropdown-toggle.btn-flat:focus, .btn.btn-warning.dropdown-toggle.btn-flat:visited {
        color: #d68600; }
    .btn.btn-warning.btn-circle, .btn.btn-warning.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-warning.btn-circle.btn-outline, .btn.btn-warning.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-warning.btn-circle i, .btn.btn-warning.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-warning.btn-circle.btn-xs, .btn.btn-warning.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-warning.btn-circle.btn-sm, .btn.btn-warning.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-warning.btn-circle.btn-lg, .btn.btn-warning.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-danger, .btn.btn-danger.dropdown-toggle {
    background: #d32f2f;
    color: #fff;
    border-color: #d32f2f;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-danger:hover, .btn.btn-danger:active, .btn.btn-danger:focus, .btn.btn-danger:visited, .btn.btn-danger.focus, .btn.btn-danger.dropdown-toggle:hover, .btn.btn-danger.dropdown-toggle:active, .btn.btn-danger.dropdown-toggle:focus, .btn.btn-danger.dropdown-toggle:visited, .btn.btn-danger.dropdown-toggle.focus {
      color: #fff;
      background: #b32626;
      border-color: #b32626; }
    .btn.btn-danger.btn-raised, .btn.btn-danger.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-danger.btn-rounded, .btn.btn-danger.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-danger.btn-outline, .btn.btn-danger.dropdown-toggle.btn-outline {
      background: none;
      color: #d32f2f;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-danger.btn-outline:hover, .btn.btn-danger.btn-outline:active, .btn.btn-danger.btn-outline:focus, .btn.btn-danger.btn-outline:visited, .btn.btn-danger.dropdown-toggle.btn-outline:hover, .btn.btn-danger.dropdown-toggle.btn-outline:active, .btn.btn-danger.dropdown-toggle.btn-outline:focus, .btn.btn-danger.dropdown-toggle.btn-outline:visited {
        color: #b32626;
        border-color: #b32626; }
      .btn.btn-danger.btn-outline.btn-rounded, .btn.btn-danger.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-danger.btn-flat, .btn.btn-danger.dropdown-toggle.btn-flat {
      color: #d32f2f; }
      .btn.btn-danger.btn-flat:hover, .btn.btn-danger.btn-flat:active, .btn.btn-danger.btn-flat:focus, .btn.btn-danger.btn-flat:visited, .btn.btn-danger.dropdown-toggle.btn-flat:hover, .btn.btn-danger.dropdown-toggle.btn-flat:active, .btn.btn-danger.dropdown-toggle.btn-flat:focus, .btn.btn-danger.dropdown-toggle.btn-flat:visited {
        color: #b32626; }
    .btn.btn-danger.btn-circle, .btn.btn-danger.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-danger.btn-circle.btn-outline, .btn.btn-danger.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-danger.btn-circle i, .btn.btn-danger.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-danger.btn-circle.btn-xs, .btn.btn-danger.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-danger.btn-circle.btn-sm, .btn.btn-danger.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-danger.btn-circle.btn-lg, .btn.btn-danger.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-green, .btn.btn-green.dropdown-toggle {
    background: #5d7e3d;
    color: #fff;
    border-color: #5d7e3d;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-green:hover, .btn.btn-green:active, .btn.btn-green:focus, .btn.btn-green:visited, .btn.btn-green.focus, .btn.btn-green.dropdown-toggle:hover, .btn.btn-green.dropdown-toggle:active, .btn.btn-green.dropdown-toggle:focus, .btn.btn-green.dropdown-toggle:visited, .btn.btn-green.dropdown-toggle.focus {
      color: #fff;
      background: #496330;
      border-color: #496330; }
    .btn.btn-green.btn-raised, .btn.btn-green.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-green.btn-rounded, .btn.btn-green.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-green.btn-outline, .btn.btn-green.dropdown-toggle.btn-outline {
      background: none;
      color: #5d7e3d;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-green.btn-outline:hover, .btn.btn-green.btn-outline:active, .btn.btn-green.btn-outline:focus, .btn.btn-green.btn-outline:visited, .btn.btn-green.dropdown-toggle.btn-outline:hover, .btn.btn-green.dropdown-toggle.btn-outline:active, .btn.btn-green.dropdown-toggle.btn-outline:focus, .btn.btn-green.dropdown-toggle.btn-outline:visited {
        color: #496330;
        border-color: #496330; }
      .btn.btn-green.btn-outline.btn-rounded, .btn.btn-green.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-green.btn-flat, .btn.btn-green.dropdown-toggle.btn-flat {
      color: #5d7e3d; }
      .btn.btn-green.btn-flat:hover, .btn.btn-green.btn-flat:active, .btn.btn-green.btn-flat:focus, .btn.btn-green.btn-flat:visited, .btn.btn-green.dropdown-toggle.btn-flat:hover, .btn.btn-green.dropdown-toggle.btn-flat:active, .btn.btn-green.dropdown-toggle.btn-flat:focus, .btn.btn-green.dropdown-toggle.btn-flat:visited {
        color: #496330; }
    .btn.btn-green.btn-circle, .btn.btn-green.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-green.btn-circle.btn-outline, .btn.btn-green.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-green.btn-circle i, .btn.btn-green.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-green.btn-circle.btn-xs, .btn.btn-green.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-green.btn-circle.btn-sm, .btn.btn-green.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-green.btn-circle.btn-lg, .btn.btn-green.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-blue-1, .btn.btn-cyder-blue-1.dropdown-toggle {
    background: #027ec1;
    color: #fff;
    border-color: #027ec1;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-blue-1:hover, .btn.btn-cyder-blue-1:active, .btn.btn-cyder-blue-1:focus, .btn.btn-cyder-blue-1:visited, .btn.btn-cyder-blue-1.focus, .btn.btn-cyder-blue-1.dropdown-toggle:hover, .btn.btn-cyder-blue-1.dropdown-toggle:active, .btn.btn-cyder-blue-1.dropdown-toggle:focus, .btn.btn-cyder-blue-1.dropdown-toggle:visited, .btn.btn-cyder-blue-1.dropdown-toggle.focus {
      color: #fff;
      background: #026499;
      border-color: #026499; }
    .btn.btn-cyder-blue-1.btn-raised, .btn.btn-cyder-blue-1.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-blue-1.btn-rounded, .btn.btn-cyder-blue-1.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-blue-1.btn-outline, .btn.btn-cyder-blue-1.dropdown-toggle.btn-outline {
      background: none;
      color: #027ec1;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-blue-1.btn-outline:hover, .btn.btn-cyder-blue-1.btn-outline:active, .btn.btn-cyder-blue-1.btn-outline:focus, .btn.btn-cyder-blue-1.btn-outline:visited, .btn.btn-cyder-blue-1.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-blue-1.dropdown-toggle.btn-outline:active, .btn.btn-cyder-blue-1.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-blue-1.dropdown-toggle.btn-outline:visited {
        color: #026499;
        border-color: #026499; }
      .btn.btn-cyder-blue-1.btn-outline.btn-rounded, .btn.btn-cyder-blue-1.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-blue-1.btn-flat, .btn.btn-cyder-blue-1.dropdown-toggle.btn-flat {
      color: #027ec1; }
      .btn.btn-cyder-blue-1.btn-flat:hover, .btn.btn-cyder-blue-1.btn-flat:active, .btn.btn-cyder-blue-1.btn-flat:focus, .btn.btn-cyder-blue-1.btn-flat:visited, .btn.btn-cyder-blue-1.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-blue-1.dropdown-toggle.btn-flat:active, .btn.btn-cyder-blue-1.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-blue-1.dropdown-toggle.btn-flat:visited {
        color: #026499; }
    .btn.btn-cyder-blue-1.btn-circle, .btn.btn-cyder-blue-1.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-blue-1.btn-circle.btn-outline, .btn.btn-cyder-blue-1.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-blue-1.btn-circle i, .btn.btn-cyder-blue-1.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-blue-1.btn-circle.btn-xs, .btn.btn-cyder-blue-1.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-blue-1.btn-circle.btn-sm, .btn.btn-cyder-blue-1.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-blue-1.btn-circle.btn-lg, .btn.btn-cyder-blue-1.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-blue-2, .btn.btn-cyder-blue-2.dropdown-toggle {
    background: #2bbed3;
    color: #fff;
    border-color: #2bbed3;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-blue-2:hover, .btn.btn-cyder-blue-2:active, .btn.btn-cyder-blue-2:focus, .btn.btn-cyder-blue-2:visited, .btn.btn-cyder-blue-2.focus, .btn.btn-cyder-blue-2.dropdown-toggle:hover, .btn.btn-cyder-blue-2.dropdown-toggle:active, .btn.btn-cyder-blue-2.dropdown-toggle:focus, .btn.btn-cyder-blue-2.dropdown-toggle:visited, .btn.btn-cyder-blue-2.dropdown-toggle.focus {
      color: #fff;
      background: #249fb1;
      border-color: #249fb1; }
    .btn.btn-cyder-blue-2.btn-raised, .btn.btn-cyder-blue-2.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-blue-2.btn-rounded, .btn.btn-cyder-blue-2.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-blue-2.btn-outline, .btn.btn-cyder-blue-2.dropdown-toggle.btn-outline {
      background: none;
      color: #2bbed3;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-blue-2.btn-outline:hover, .btn.btn-cyder-blue-2.btn-outline:active, .btn.btn-cyder-blue-2.btn-outline:focus, .btn.btn-cyder-blue-2.btn-outline:visited, .btn.btn-cyder-blue-2.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-blue-2.dropdown-toggle.btn-outline:active, .btn.btn-cyder-blue-2.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-blue-2.dropdown-toggle.btn-outline:visited {
        color: #249fb1;
        border-color: #249fb1; }
      .btn.btn-cyder-blue-2.btn-outline.btn-rounded, .btn.btn-cyder-blue-2.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-blue-2.btn-flat, .btn.btn-cyder-blue-2.dropdown-toggle.btn-flat {
      color: #2bbed3; }
      .btn.btn-cyder-blue-2.btn-flat:hover, .btn.btn-cyder-blue-2.btn-flat:active, .btn.btn-cyder-blue-2.btn-flat:focus, .btn.btn-cyder-blue-2.btn-flat:visited, .btn.btn-cyder-blue-2.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-blue-2.dropdown-toggle.btn-flat:active, .btn.btn-cyder-blue-2.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-blue-2.dropdown-toggle.btn-flat:visited {
        color: #249fb1; }
    .btn.btn-cyder-blue-2.btn-circle, .btn.btn-cyder-blue-2.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-blue-2.btn-circle.btn-outline, .btn.btn-cyder-blue-2.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-blue-2.btn-circle i, .btn.btn-cyder-blue-2.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-blue-2.btn-circle.btn-xs, .btn.btn-cyder-blue-2.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-blue-2.btn-circle.btn-sm, .btn.btn-cyder-blue-2.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-blue-2.btn-circle.btn-lg, .btn.btn-cyder-blue-2.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-green-1, .btn.btn-cyder-green-1.dropdown-toggle {
    background: #439b58;
    color: #fff;
    border-color: #439b58;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-green-1:hover, .btn.btn-cyder-green-1:active, .btn.btn-cyder-green-1:focus, .btn.btn-cyder-green-1:visited, .btn.btn-cyder-green-1.focus, .btn.btn-cyder-green-1.dropdown-toggle:hover, .btn.btn-cyder-green-1.dropdown-toggle:active, .btn.btn-cyder-green-1.dropdown-toggle:focus, .btn.btn-cyder-green-1.dropdown-toggle:visited, .btn.btn-cyder-green-1.dropdown-toggle.focus {
      color: #fff;
      background: #377f48;
      border-color: #377f48; }
    .btn.btn-cyder-green-1.btn-raised, .btn.btn-cyder-green-1.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-green-1.btn-rounded, .btn.btn-cyder-green-1.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-green-1.btn-outline, .btn.btn-cyder-green-1.dropdown-toggle.btn-outline {
      background: none;
      color: #439b58;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-green-1.btn-outline:hover, .btn.btn-cyder-green-1.btn-outline:active, .btn.btn-cyder-green-1.btn-outline:focus, .btn.btn-cyder-green-1.btn-outline:visited, .btn.btn-cyder-green-1.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-green-1.dropdown-toggle.btn-outline:active, .btn.btn-cyder-green-1.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-green-1.dropdown-toggle.btn-outline:visited {
        color: #377f48;
        border-color: #377f48; }
      .btn.btn-cyder-green-1.btn-outline.btn-rounded, .btn.btn-cyder-green-1.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-green-1.btn-flat, .btn.btn-cyder-green-1.dropdown-toggle.btn-flat {
      color: #439b58; }
      .btn.btn-cyder-green-1.btn-flat:hover, .btn.btn-cyder-green-1.btn-flat:active, .btn.btn-cyder-green-1.btn-flat:focus, .btn.btn-cyder-green-1.btn-flat:visited, .btn.btn-cyder-green-1.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-green-1.dropdown-toggle.btn-flat:active, .btn.btn-cyder-green-1.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-green-1.dropdown-toggle.btn-flat:visited {
        color: #377f48; }
    .btn.btn-cyder-green-1.btn-circle, .btn.btn-cyder-green-1.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-green-1.btn-circle.btn-outline, .btn.btn-cyder-green-1.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-green-1.btn-circle i, .btn.btn-cyder-green-1.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-green-1.btn-circle.btn-xs, .btn.btn-cyder-green-1.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-green-1.btn-circle.btn-sm, .btn.btn-cyder-green-1.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-green-1.btn-circle.btn-lg, .btn.btn-cyder-green-1.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-green-2, .btn.btn-cyder-green-2.dropdown-toggle {
    background: #21401c;
    color: #fff;
    border-color: #21401c;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-green-2:hover, .btn.btn-cyder-green-2:active, .btn.btn-cyder-green-2:focus, .btn.btn-cyder-green-2:visited, .btn.btn-cyder-green-2.focus, .btn.btn-cyder-green-2.dropdown-toggle:hover, .btn.btn-cyder-green-2.dropdown-toggle:active, .btn.btn-cyder-green-2.dropdown-toggle:focus, .btn.btn-cyder-green-2.dropdown-toggle:visited, .btn.btn-cyder-green-2.dropdown-toggle.focus {
      color: #fff;
      background: #122410;
      border-color: #122410; }
    .btn.btn-cyder-green-2.btn-raised, .btn.btn-cyder-green-2.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-green-2.btn-rounded, .btn.btn-cyder-green-2.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-green-2.btn-outline, .btn.btn-cyder-green-2.dropdown-toggle.btn-outline {
      background: none;
      color: #21401c;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-green-2.btn-outline:hover, .btn.btn-cyder-green-2.btn-outline:active, .btn.btn-cyder-green-2.btn-outline:focus, .btn.btn-cyder-green-2.btn-outline:visited, .btn.btn-cyder-green-2.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-green-2.dropdown-toggle.btn-outline:active, .btn.btn-cyder-green-2.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-green-2.dropdown-toggle.btn-outline:visited {
        color: #122410;
        border-color: #122410; }
      .btn.btn-cyder-green-2.btn-outline.btn-rounded, .btn.btn-cyder-green-2.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-green-2.btn-flat, .btn.btn-cyder-green-2.dropdown-toggle.btn-flat {
      color: #21401c; }
      .btn.btn-cyder-green-2.btn-flat:hover, .btn.btn-cyder-green-2.btn-flat:active, .btn.btn-cyder-green-2.btn-flat:focus, .btn.btn-cyder-green-2.btn-flat:visited, .btn.btn-cyder-green-2.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-green-2.dropdown-toggle.btn-flat:active, .btn.btn-cyder-green-2.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-green-2.dropdown-toggle.btn-flat:visited {
        color: #122410; }
    .btn.btn-cyder-green-2.btn-circle, .btn.btn-cyder-green-2.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-green-2.btn-circle.btn-outline, .btn.btn-cyder-green-2.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-green-2.btn-circle i, .btn.btn-cyder-green-2.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-green-2.btn-circle.btn-xs, .btn.btn-cyder-green-2.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-green-2.btn-circle.btn-sm, .btn.btn-cyder-green-2.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-green-2.btn-circle.btn-lg, .btn.btn-cyder-green-2.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-orange-1, .btn.btn-cyder-orange-1.dropdown-toggle {
    background: #ffab1a;
    color: #fff;
    border-color: #ffab1a;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-orange-1:hover, .btn.btn-cyder-orange-1:active, .btn.btn-cyder-orange-1:focus, .btn.btn-cyder-orange-1:visited, .btn.btn-cyder-orange-1.focus, .btn.btn-cyder-orange-1.dropdown-toggle:hover, .btn.btn-cyder-orange-1.dropdown-toggle:active, .btn.btn-cyder-orange-1.dropdown-toggle:focus, .btn.btn-cyder-orange-1.dropdown-toggle:visited, .btn.btn-cyder-orange-1.dropdown-toggle.focus {
      color: #fff;
      background: #f09800;
      border-color: #f09800; }
    .btn.btn-cyder-orange-1.btn-raised, .btn.btn-cyder-orange-1.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-orange-1.btn-rounded, .btn.btn-cyder-orange-1.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-orange-1.btn-outline, .btn.btn-cyder-orange-1.dropdown-toggle.btn-outline {
      background: none;
      color: #ffab1a;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-orange-1.btn-outline:hover, .btn.btn-cyder-orange-1.btn-outline:active, .btn.btn-cyder-orange-1.btn-outline:focus, .btn.btn-cyder-orange-1.btn-outline:visited, .btn.btn-cyder-orange-1.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-orange-1.dropdown-toggle.btn-outline:active, .btn.btn-cyder-orange-1.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-orange-1.dropdown-toggle.btn-outline:visited {
        color: #f09800;
        border-color: #f09800; }
      .btn.btn-cyder-orange-1.btn-outline.btn-rounded, .btn.btn-cyder-orange-1.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-orange-1.btn-flat, .btn.btn-cyder-orange-1.dropdown-toggle.btn-flat {
      color: #ffab1a; }
      .btn.btn-cyder-orange-1.btn-flat:hover, .btn.btn-cyder-orange-1.btn-flat:active, .btn.btn-cyder-orange-1.btn-flat:focus, .btn.btn-cyder-orange-1.btn-flat:visited, .btn.btn-cyder-orange-1.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-orange-1.dropdown-toggle.btn-flat:active, .btn.btn-cyder-orange-1.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-orange-1.dropdown-toggle.btn-flat:visited {
        color: #f09800; }
    .btn.btn-cyder-orange-1.btn-circle, .btn.btn-cyder-orange-1.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-orange-1.btn-circle.btn-outline, .btn.btn-cyder-orange-1.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-orange-1.btn-circle i, .btn.btn-cyder-orange-1.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-orange-1.btn-circle.btn-xs, .btn.btn-cyder-orange-1.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-orange-1.btn-circle.btn-sm, .btn.btn-cyder-orange-1.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-orange-1.btn-circle.btn-lg, .btn.btn-cyder-orange-1.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-pink-1, .btn.btn-cyder-pink-1.dropdown-toggle {
    background: #ec5a71;
    color: #fff;
    border-color: #ec5a71;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-pink-1:hover, .btn.btn-cyder-pink-1:active, .btn.btn-cyder-pink-1:focus, .btn.btn-cyder-pink-1:visited, .btn.btn-cyder-pink-1.focus, .btn.btn-cyder-pink-1.dropdown-toggle:hover, .btn.btn-cyder-pink-1.dropdown-toggle:active, .btn.btn-cyder-pink-1.dropdown-toggle:focus, .btn.btn-cyder-pink-1.dropdown-toggle:visited, .btn.btn-cyder-pink-1.dropdown-toggle.focus {
      color: #fff;
      background: #e83552;
      border-color: #e83552; }
    .btn.btn-cyder-pink-1.btn-raised, .btn.btn-cyder-pink-1.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-pink-1.btn-rounded, .btn.btn-cyder-pink-1.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-pink-1.btn-outline, .btn.btn-cyder-pink-1.dropdown-toggle.btn-outline {
      background: none;
      color: #ec5a71;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-pink-1.btn-outline:hover, .btn.btn-cyder-pink-1.btn-outline:active, .btn.btn-cyder-pink-1.btn-outline:focus, .btn.btn-cyder-pink-1.btn-outline:visited, .btn.btn-cyder-pink-1.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-pink-1.dropdown-toggle.btn-outline:active, .btn.btn-cyder-pink-1.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-pink-1.dropdown-toggle.btn-outline:visited {
        color: #e83552;
        border-color: #e83552; }
      .btn.btn-cyder-pink-1.btn-outline.btn-rounded, .btn.btn-cyder-pink-1.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-pink-1.btn-flat, .btn.btn-cyder-pink-1.dropdown-toggle.btn-flat {
      color: #ec5a71; }
      .btn.btn-cyder-pink-1.btn-flat:hover, .btn.btn-cyder-pink-1.btn-flat:active, .btn.btn-cyder-pink-1.btn-flat:focus, .btn.btn-cyder-pink-1.btn-flat:visited, .btn.btn-cyder-pink-1.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-pink-1.dropdown-toggle.btn-flat:active, .btn.btn-cyder-pink-1.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-pink-1.dropdown-toggle.btn-flat:visited {
        color: #e83552; }
    .btn.btn-cyder-pink-1.btn-circle, .btn.btn-cyder-pink-1.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-pink-1.btn-circle.btn-outline, .btn.btn-cyder-pink-1.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-pink-1.btn-circle i, .btn.btn-cyder-pink-1.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-pink-1.btn-circle.btn-xs, .btn.btn-cyder-pink-1.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-pink-1.btn-circle.btn-sm, .btn.btn-cyder-pink-1.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-pink-1.btn-circle.btn-lg, .btn.btn-cyder-pink-1.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-yellow-1, .btn.btn-cyder-yellow-1.dropdown-toggle {
    background: #ffd351;
    color: #fff;
    border-color: #ffd351;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-yellow-1:hover, .btn.btn-cyder-yellow-1:active, .btn.btn-cyder-yellow-1:focus, .btn.btn-cyder-yellow-1:visited, .btn.btn-cyder-yellow-1.focus, .btn.btn-cyder-yellow-1.dropdown-toggle:hover, .btn.btn-cyder-yellow-1.dropdown-toggle:active, .btn.btn-cyder-yellow-1.dropdown-toggle:focus, .btn.btn-cyder-yellow-1.dropdown-toggle:visited, .btn.btn-cyder-yellow-1.dropdown-toggle.focus {
      color: #fff;
      background: #ffc928;
      border-color: #ffc928; }
    .btn.btn-cyder-yellow-1.btn-raised, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-yellow-1.btn-rounded, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-yellow-1.btn-outline, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-outline {
      background: none;
      color: #ffd351;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-yellow-1.btn-outline:hover, .btn.btn-cyder-yellow-1.btn-outline:active, .btn.btn-cyder-yellow-1.btn-outline:focus, .btn.btn-cyder-yellow-1.btn-outline:visited, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-outline:active, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-outline:visited {
        color: #ffc928;
        border-color: #ffc928; }
      .btn.btn-cyder-yellow-1.btn-outline.btn-rounded, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-yellow-1.btn-flat, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-flat {
      color: #ffd351; }
      .btn.btn-cyder-yellow-1.btn-flat:hover, .btn.btn-cyder-yellow-1.btn-flat:active, .btn.btn-cyder-yellow-1.btn-flat:focus, .btn.btn-cyder-yellow-1.btn-flat:visited, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-flat:active, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-flat:visited {
        color: #ffc928; }
    .btn.btn-cyder-yellow-1.btn-circle, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-yellow-1.btn-circle.btn-outline, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-yellow-1.btn-circle i, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-yellow-1.btn-circle.btn-xs, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-yellow-1.btn-circle.btn-sm, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-yellow-1.btn-circle.btn-lg, .btn.btn-cyder-yellow-1.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-blue-1-darken, .btn.btn-cyder-blue-1-darken.dropdown-toggle {
    background: #014d75;
    color: #fff;
    border-color: #014d75;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-blue-1-darken:hover, .btn.btn-cyder-blue-1-darken:active, .btn.btn-cyder-blue-1-darken:focus, .btn.btn-cyder-blue-1-darken:visited, .btn.btn-cyder-blue-1-darken.focus, .btn.btn-cyder-blue-1-darken.dropdown-toggle:hover, .btn.btn-cyder-blue-1-darken.dropdown-toggle:active, .btn.btn-cyder-blue-1-darken.dropdown-toggle:focus, .btn.btn-cyder-blue-1-darken.dropdown-toggle:visited, .btn.btn-cyder-blue-1-darken.dropdown-toggle.focus {
      color: #fff;
      background: #01324d;
      border-color: #01324d; }
    .btn.btn-cyder-blue-1-darken.btn-raised, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-blue-1-darken.btn-rounded, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-blue-1-darken.btn-outline, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-outline {
      background: none;
      color: #014d75;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-blue-1-darken.btn-outline:hover, .btn.btn-cyder-blue-1-darken.btn-outline:active, .btn.btn-cyder-blue-1-darken.btn-outline:focus, .btn.btn-cyder-blue-1-darken.btn-outline:visited, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-outline:active, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-outline:visited {
        color: #01324d;
        border-color: #01324d; }
      .btn.btn-cyder-blue-1-darken.btn-outline.btn-rounded, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-blue-1-darken.btn-flat, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-flat {
      color: #014d75; }
      .btn.btn-cyder-blue-1-darken.btn-flat:hover, .btn.btn-cyder-blue-1-darken.btn-flat:active, .btn.btn-cyder-blue-1-darken.btn-flat:focus, .btn.btn-cyder-blue-1-darken.btn-flat:visited, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-flat:active, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-flat:visited {
        color: #01324d; }
    .btn.btn-cyder-blue-1-darken.btn-circle, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-blue-1-darken.btn-circle.btn-outline, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-blue-1-darken.btn-circle i, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-blue-1-darken.btn-circle.btn-xs, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-blue-1-darken.btn-circle.btn-sm, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-blue-1-darken.btn-circle.btn-lg, .btn.btn-cyder-blue-1-darken.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-green-1-darken, .btn.btn-cyder-green-1-darken.dropdown-toggle {
    background: #2c663a;
    color: #fff;
    border-color: #2c663a;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-green-1-darken:hover, .btn.btn-cyder-green-1-darken:active, .btn.btn-cyder-green-1-darken:focus, .btn.btn-cyder-green-1-darken:visited, .btn.btn-cyder-green-1-darken.focus, .btn.btn-cyder-green-1-darken.dropdown-toggle:hover, .btn.btn-cyder-green-1-darken.dropdown-toggle:active, .btn.btn-cyder-green-1-darken.dropdown-toggle:focus, .btn.btn-cyder-green-1-darken.dropdown-toggle:visited, .btn.btn-cyder-green-1-darken.dropdown-toggle.focus {
      color: #fff;
      background: #20492a;
      border-color: #20492a; }
    .btn.btn-cyder-green-1-darken.btn-raised, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-green-1-darken.btn-rounded, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-green-1-darken.btn-outline, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-outline {
      background: none;
      color: #2c663a;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-green-1-darken.btn-outline:hover, .btn.btn-cyder-green-1-darken.btn-outline:active, .btn.btn-cyder-green-1-darken.btn-outline:focus, .btn.btn-cyder-green-1-darken.btn-outline:visited, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-outline:active, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-outline:visited {
        color: #20492a;
        border-color: #20492a; }
      .btn.btn-cyder-green-1-darken.btn-outline.btn-rounded, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-green-1-darken.btn-flat, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-flat {
      color: #2c663a; }
      .btn.btn-cyder-green-1-darken.btn-flat:hover, .btn.btn-cyder-green-1-darken.btn-flat:active, .btn.btn-cyder-green-1-darken.btn-flat:focus, .btn.btn-cyder-green-1-darken.btn-flat:visited, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-flat:active, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-flat:visited {
        color: #20492a; }
    .btn.btn-cyder-green-1-darken.btn-circle, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-green-1-darken.btn-circle.btn-outline, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-green-1-darken.btn-circle i, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-green-1-darken.btn-circle.btn-xs, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-green-1-darken.btn-circle.btn-sm, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-green-1-darken.btn-circle.btn-lg, .btn.btn-cyder-green-1-darken.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-green-2-darken, .btn.btn-cyder-green-2-darken.dropdown-toggle {
    background: #060b05;
    color: #fff;
    border-color: #060b05;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-green-2-darken:hover, .btn.btn-cyder-green-2-darken:active, .btn.btn-cyder-green-2-darken:focus, .btn.btn-cyder-green-2-darken:visited, .btn.btn-cyder-green-2-darken.focus, .btn.btn-cyder-green-2-darken.dropdown-toggle:hover, .btn.btn-cyder-green-2-darken.dropdown-toggle:active, .btn.btn-cyder-green-2-darken.dropdown-toggle:focus, .btn.btn-cyder-green-2-darken.dropdown-toggle:visited, .btn.btn-cyder-green-2-darken.dropdown-toggle.focus {
      color: #fff;
      background: black;
      border-color: black; }
    .btn.btn-cyder-green-2-darken.btn-raised, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-green-2-darken.btn-rounded, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-green-2-darken.btn-outline, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-outline {
      background: none;
      color: #060b05;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-green-2-darken.btn-outline:hover, .btn.btn-cyder-green-2-darken.btn-outline:active, .btn.btn-cyder-green-2-darken.btn-outline:focus, .btn.btn-cyder-green-2-darken.btn-outline:visited, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-outline:active, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-outline:visited {
        color: black;
        border-color: black; }
      .btn.btn-cyder-green-2-darken.btn-outline.btn-rounded, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-green-2-darken.btn-flat, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-flat {
      color: #060b05; }
      .btn.btn-cyder-green-2-darken.btn-flat:hover, .btn.btn-cyder-green-2-darken.btn-flat:active, .btn.btn-cyder-green-2-darken.btn-flat:focus, .btn.btn-cyder-green-2-darken.btn-flat:visited, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-flat:active, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-flat:visited {
        color: black; }
    .btn.btn-cyder-green-2-darken.btn-circle, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-green-2-darken.btn-circle.btn-outline, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-green-2-darken.btn-circle i, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-green-2-darken.btn-circle.btn-xs, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-green-2-darken.btn-circle.btn-sm, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-green-2-darken.btn-circle.btn-lg, .btn.btn-cyder-green-2-darken.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-orange-1-darken, .btn.btn-cyder-orange-1-darken.dropdown-toggle {
    background: #cd8100;
    color: #fff;
    border-color: #cd8100;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-orange-1-darken:hover, .btn.btn-cyder-orange-1-darken:active, .btn.btn-cyder-orange-1-darken:focus, .btn.btn-cyder-orange-1-darken:visited, .btn.btn-cyder-orange-1-darken.focus, .btn.btn-cyder-orange-1-darken.dropdown-toggle:hover, .btn.btn-cyder-orange-1-darken.dropdown-toggle:active, .btn.btn-cyder-orange-1-darken.dropdown-toggle:focus, .btn.btn-cyder-orange-1-darken.dropdown-toggle:visited, .btn.btn-cyder-orange-1-darken.dropdown-toggle.focus {
      color: #fff;
      background: #a46800;
      border-color: #a46800; }
    .btn.btn-cyder-orange-1-darken.btn-raised, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-orange-1-darken.btn-rounded, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-orange-1-darken.btn-outline, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-outline {
      background: none;
      color: #cd8100;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-orange-1-darken.btn-outline:hover, .btn.btn-cyder-orange-1-darken.btn-outline:active, .btn.btn-cyder-orange-1-darken.btn-outline:focus, .btn.btn-cyder-orange-1-darken.btn-outline:visited, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-outline:active, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-outline:visited {
        color: #a46800;
        border-color: #a46800; }
      .btn.btn-cyder-orange-1-darken.btn-outline.btn-rounded, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-orange-1-darken.btn-flat, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-flat {
      color: #cd8100; }
      .btn.btn-cyder-orange-1-darken.btn-flat:hover, .btn.btn-cyder-orange-1-darken.btn-flat:active, .btn.btn-cyder-orange-1-darken.btn-flat:focus, .btn.btn-cyder-orange-1-darken.btn-flat:visited, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-flat:active, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-flat:visited {
        color: #a46800; }
    .btn.btn-cyder-orange-1-darken.btn-circle, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-orange-1-darken.btn-circle.btn-outline, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-orange-1-darken.btn-circle i, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-orange-1-darken.btn-circle.btn-xs, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-orange-1-darken.btn-circle.btn-sm, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-orange-1-darken.btn-circle.btn-lg, .btn.btn-cyder-orange-1-darken.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-c-lighttile-darken, .btn.btn-c-lighttile-darken.dropdown-toggle {
    background: #40a9ab;
    color: #fff;
    border-color: #40a9ab;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-c-lighttile-darken:hover, .btn.btn-c-lighttile-darken:active, .btn.btn-c-lighttile-darken:focus, .btn.btn-c-lighttile-darken:visited, .btn.btn-c-lighttile-darken.focus, .btn.btn-c-lighttile-darken.dropdown-toggle:hover, .btn.btn-c-lighttile-darken.dropdown-toggle:active, .btn.btn-c-lighttile-darken.dropdown-toggle:focus, .btn.btn-c-lighttile-darken.dropdown-toggle:visited, .btn.btn-c-lighttile-darken.dropdown-toggle.focus {
      color: #fff;
      background: #358c8e;
      border-color: #358c8e; }
    .btn.btn-c-lighttile-darken.btn-raised, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-c-lighttile-darken.btn-rounded, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-c-lighttile-darken.btn-outline, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-outline {
      background: none;
      color: #40a9ab;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-c-lighttile-darken.btn-outline:hover, .btn.btn-c-lighttile-darken.btn-outline:active, .btn.btn-c-lighttile-darken.btn-outline:focus, .btn.btn-c-lighttile-darken.btn-outline:visited, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-outline:hover, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-outline:active, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-outline:focus, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-outline:visited {
        color: #358c8e;
        border-color: #358c8e; }
      .btn.btn-c-lighttile-darken.btn-outline.btn-rounded, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-c-lighttile-darken.btn-flat, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-flat {
      color: #40a9ab; }
      .btn.btn-c-lighttile-darken.btn-flat:hover, .btn.btn-c-lighttile-darken.btn-flat:active, .btn.btn-c-lighttile-darken.btn-flat:focus, .btn.btn-c-lighttile-darken.btn-flat:visited, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-flat:hover, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-flat:active, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-flat:focus, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-flat:visited {
        color: #358c8e; }
    .btn.btn-c-lighttile-darken.btn-circle, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-c-lighttile-darken.btn-circle.btn-outline, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-c-lighttile-darken.btn-circle i, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-c-lighttile-darken.btn-circle.btn-xs, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-c-lighttile-darken.btn-circle.btn-sm, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-c-lighttile-darken.btn-circle.btn-lg, .btn.btn-c-lighttile-darken.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-cyder-pink-1-darken, .btn.btn-cyder-pink-1-darken.dropdown-toggle {
    background: #e01a39;
    color: #fff;
    border-color: #e01a39;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-cyder-pink-1-darken:hover, .btn.btn-cyder-pink-1-darken:active, .btn.btn-cyder-pink-1-darken:focus, .btn.btn-cyder-pink-1-darken:visited, .btn.btn-cyder-pink-1-darken.focus, .btn.btn-cyder-pink-1-darken.dropdown-toggle:hover, .btn.btn-cyder-pink-1-darken.dropdown-toggle:active, .btn.btn-cyder-pink-1-darken.dropdown-toggle:focus, .btn.btn-cyder-pink-1-darken.dropdown-toggle:visited, .btn.btn-cyder-pink-1-darken.dropdown-toggle.focus {
      color: #fff;
      background: #bb1630;
      border-color: #bb1630; }
    .btn.btn-cyder-pink-1-darken.btn-raised, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-cyder-pink-1-darken.btn-rounded, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-cyder-pink-1-darken.btn-outline, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-outline {
      background: none;
      color: #e01a39;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-cyder-pink-1-darken.btn-outline:hover, .btn.btn-cyder-pink-1-darken.btn-outline:active, .btn.btn-cyder-pink-1-darken.btn-outline:focus, .btn.btn-cyder-pink-1-darken.btn-outline:visited, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-outline:hover, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-outline:active, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-outline:focus, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-outline:visited {
        color: #bb1630;
        border-color: #bb1630; }
      .btn.btn-cyder-pink-1-darken.btn-outline.btn-rounded, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-cyder-pink-1-darken.btn-flat, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-flat {
      color: #e01a39; }
      .btn.btn-cyder-pink-1-darken.btn-flat:hover, .btn.btn-cyder-pink-1-darken.btn-flat:active, .btn.btn-cyder-pink-1-darken.btn-flat:focus, .btn.btn-cyder-pink-1-darken.btn-flat:visited, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-flat:hover, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-flat:active, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-flat:focus, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-flat:visited {
        color: #bb1630; }
    .btn.btn-cyder-pink-1-darken.btn-circle, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-cyder-pink-1-darken.btn-circle.btn-outline, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-cyder-pink-1-darken.btn-circle i, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-cyder-pink-1-darken.btn-circle.btn-xs, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-cyder-pink-1-darken.btn-circle.btn-sm, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-cyder-pink-1-darken.btn-circle.btn-lg, .btn.btn-cyder-pink-1-darken.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-c-newdark, .btn.btn-c-newdark.dropdown-toggle {
    background: #313b3d;
    color: #fff;
    border-color: #313b3d;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-c-newdark:hover, .btn.btn-c-newdark:active, .btn.btn-c-newdark:focus, .btn.btn-c-newdark:visited, .btn.btn-c-newdark.focus, .btn.btn-c-newdark.dropdown-toggle:hover, .btn.btn-c-newdark.dropdown-toggle:active, .btn.btn-c-newdark.dropdown-toggle:focus, .btn.btn-c-newdark.dropdown-toggle:visited, .btn.btn-c-newdark.dropdown-toggle.focus {
      color: #fff;
      background: #1f2526;
      border-color: #1f2526; }
    .btn.btn-c-newdark.btn-raised, .btn.btn-c-newdark.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-c-newdark.btn-rounded, .btn.btn-c-newdark.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-c-newdark.btn-outline, .btn.btn-c-newdark.dropdown-toggle.btn-outline {
      background: none;
      color: #313b3d;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-c-newdark.btn-outline:hover, .btn.btn-c-newdark.btn-outline:active, .btn.btn-c-newdark.btn-outline:focus, .btn.btn-c-newdark.btn-outline:visited, .btn.btn-c-newdark.dropdown-toggle.btn-outline:hover, .btn.btn-c-newdark.dropdown-toggle.btn-outline:active, .btn.btn-c-newdark.dropdown-toggle.btn-outline:focus, .btn.btn-c-newdark.dropdown-toggle.btn-outline:visited {
        color: #1f2526;
        border-color: #1f2526; }
      .btn.btn-c-newdark.btn-outline.btn-rounded, .btn.btn-c-newdark.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-c-newdark.btn-flat, .btn.btn-c-newdark.dropdown-toggle.btn-flat {
      color: #313b3d; }
      .btn.btn-c-newdark.btn-flat:hover, .btn.btn-c-newdark.btn-flat:active, .btn.btn-c-newdark.btn-flat:focus, .btn.btn-c-newdark.btn-flat:visited, .btn.btn-c-newdark.dropdown-toggle.btn-flat:hover, .btn.btn-c-newdark.dropdown-toggle.btn-flat:active, .btn.btn-c-newdark.dropdown-toggle.btn-flat:focus, .btn.btn-c-newdark.dropdown-toggle.btn-flat:visited {
        color: #1f2526; }
    .btn.btn-c-newdark.btn-circle, .btn.btn-c-newdark.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-c-newdark.btn-circle.btn-outline, .btn.btn-c-newdark.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-c-newdark.btn-circle i, .btn.btn-c-newdark.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-c-newdark.btn-circle.btn-xs, .btn.btn-c-newdark.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-c-newdark.btn-circle.btn-sm, .btn.btn-c-newdark.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-c-newdark.btn-circle.btn-lg, .btn.btn-c-newdark.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-c-lightblue, .btn.btn-c-lightblue.dropdown-toggle {
    background: #78adc9;
    color: #fff;
    border-color: #78adc9;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-c-lightblue:hover, .btn.btn-c-lightblue:active, .btn.btn-c-lightblue:focus, .btn.btn-c-lightblue:visited, .btn.btn-c-lightblue.focus, .btn.btn-c-lightblue.dropdown-toggle:hover, .btn.btn-c-lightblue.dropdown-toggle:active, .btn.btn-c-lightblue.dropdown-toggle:focus, .btn.btn-c-lightblue.dropdown-toggle:visited, .btn.btn-c-lightblue.dropdown-toggle.focus {
      color: #fff;
      background: #5b9bbd;
      border-color: #5b9bbd; }
    .btn.btn-c-lightblue.btn-raised, .btn.btn-c-lightblue.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-c-lightblue.btn-rounded, .btn.btn-c-lightblue.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-c-lightblue.btn-outline, .btn.btn-c-lightblue.dropdown-toggle.btn-outline {
      background: none;
      color: #78adc9;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-c-lightblue.btn-outline:hover, .btn.btn-c-lightblue.btn-outline:active, .btn.btn-c-lightblue.btn-outline:focus, .btn.btn-c-lightblue.btn-outline:visited, .btn.btn-c-lightblue.dropdown-toggle.btn-outline:hover, .btn.btn-c-lightblue.dropdown-toggle.btn-outline:active, .btn.btn-c-lightblue.dropdown-toggle.btn-outline:focus, .btn.btn-c-lightblue.dropdown-toggle.btn-outline:visited {
        color: #5b9bbd;
        border-color: #5b9bbd; }
      .btn.btn-c-lightblue.btn-outline.btn-rounded, .btn.btn-c-lightblue.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-c-lightblue.btn-flat, .btn.btn-c-lightblue.dropdown-toggle.btn-flat {
      color: #78adc9; }
      .btn.btn-c-lightblue.btn-flat:hover, .btn.btn-c-lightblue.btn-flat:active, .btn.btn-c-lightblue.btn-flat:focus, .btn.btn-c-lightblue.btn-flat:visited, .btn.btn-c-lightblue.dropdown-toggle.btn-flat:hover, .btn.btn-c-lightblue.dropdown-toggle.btn-flat:active, .btn.btn-c-lightblue.dropdown-toggle.btn-flat:focus, .btn.btn-c-lightblue.dropdown-toggle.btn-flat:visited {
        color: #5b9bbd; }
    .btn.btn-c-lightblue.btn-circle, .btn.btn-c-lightblue.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-c-lightblue.btn-circle.btn-outline, .btn.btn-c-lightblue.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-c-lightblue.btn-circle i, .btn.btn-c-lightblue.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-c-lightblue.btn-circle.btn-xs, .btn.btn-c-lightblue.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-c-lightblue.btn-circle.btn-sm, .btn.btn-c-lightblue.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-c-lightblue.btn-circle.btn-lg, .btn.btn-c-lightblue.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-c-lighttile, .btn.btn-c-lighttile.dropdown-toggle {
    background: #6fc7c9;
    color: #fff;
    border-color: #6fc7c9;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-c-lighttile:hover, .btn.btn-c-lighttile:active, .btn.btn-c-lighttile:focus, .btn.btn-c-lighttile:visited, .btn.btn-c-lighttile.focus, .btn.btn-c-lighttile.dropdown-toggle:hover, .btn.btn-c-lighttile.dropdown-toggle:active, .btn.btn-c-lighttile.dropdown-toggle:focus, .btn.btn-c-lighttile.dropdown-toggle:visited, .btn.btn-c-lighttile.dropdown-toggle.focus {
      color: #fff;
      background: #51bbbe;
      border-color: #51bbbe; }
    .btn.btn-c-lighttile.btn-raised, .btn.btn-c-lighttile.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-c-lighttile.btn-rounded, .btn.btn-c-lighttile.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-c-lighttile.btn-outline, .btn.btn-c-lighttile.dropdown-toggle.btn-outline {
      background: none;
      color: #6fc7c9;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-c-lighttile.btn-outline:hover, .btn.btn-c-lighttile.btn-outline:active, .btn.btn-c-lighttile.btn-outline:focus, .btn.btn-c-lighttile.btn-outline:visited, .btn.btn-c-lighttile.dropdown-toggle.btn-outline:hover, .btn.btn-c-lighttile.dropdown-toggle.btn-outline:active, .btn.btn-c-lighttile.dropdown-toggle.btn-outline:focus, .btn.btn-c-lighttile.dropdown-toggle.btn-outline:visited {
        color: #51bbbe;
        border-color: #51bbbe; }
      .btn.btn-c-lighttile.btn-outline.btn-rounded, .btn.btn-c-lighttile.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-c-lighttile.btn-flat, .btn.btn-c-lighttile.dropdown-toggle.btn-flat {
      color: #6fc7c9; }
      .btn.btn-c-lighttile.btn-flat:hover, .btn.btn-c-lighttile.btn-flat:active, .btn.btn-c-lighttile.btn-flat:focus, .btn.btn-c-lighttile.btn-flat:visited, .btn.btn-c-lighttile.dropdown-toggle.btn-flat:hover, .btn.btn-c-lighttile.dropdown-toggle.btn-flat:active, .btn.btn-c-lighttile.dropdown-toggle.btn-flat:focus, .btn.btn-c-lighttile.dropdown-toggle.btn-flat:visited {
        color: #51bbbe; }
    .btn.btn-c-lighttile.btn-circle, .btn.btn-c-lighttile.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-c-lighttile.btn-circle.btn-outline, .btn.btn-c-lighttile.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-c-lighttile.btn-circle i, .btn.btn-c-lighttile.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-c-lighttile.btn-circle.btn-xs, .btn.btn-c-lighttile.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-c-lighttile.btn-circle.btn-sm, .btn.btn-c-lighttile.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-c-lighttile.btn-circle.btn-lg, .btn.btn-c-lighttile.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-facebook, .btn.btn-facebook.dropdown-toggle {
    background: #365397;
    color: #fff;
    border-color: #365397;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-facebook:hover, .btn.btn-facebook:active, .btn.btn-facebook:focus, .btn.btn-facebook:visited, .btn.btn-facebook.focus, .btn.btn-facebook.dropdown-toggle:hover, .btn.btn-facebook.dropdown-toggle:active, .btn.btn-facebook.dropdown-toggle:focus, .btn.btn-facebook.dropdown-toggle:visited, .btn.btn-facebook.dropdown-toggle.focus {
      color: #fff;
      background: #2b4279;
      border-color: #2b4279; }
    .btn.btn-facebook.btn-raised, .btn.btn-facebook.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-facebook.btn-rounded, .btn.btn-facebook.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-facebook.btn-outline, .btn.btn-facebook.dropdown-toggle.btn-outline {
      background: none;
      color: #365397;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-facebook.btn-outline:hover, .btn.btn-facebook.btn-outline:active, .btn.btn-facebook.btn-outline:focus, .btn.btn-facebook.btn-outline:visited, .btn.btn-facebook.dropdown-toggle.btn-outline:hover, .btn.btn-facebook.dropdown-toggle.btn-outline:active, .btn.btn-facebook.dropdown-toggle.btn-outline:focus, .btn.btn-facebook.dropdown-toggle.btn-outline:visited {
        color: #2b4279;
        border-color: #2b4279; }
      .btn.btn-facebook.btn-outline.btn-rounded, .btn.btn-facebook.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-facebook.btn-flat, .btn.btn-facebook.dropdown-toggle.btn-flat {
      color: #365397; }
      .btn.btn-facebook.btn-flat:hover, .btn.btn-facebook.btn-flat:active, .btn.btn-facebook.btn-flat:focus, .btn.btn-facebook.btn-flat:visited, .btn.btn-facebook.dropdown-toggle.btn-flat:hover, .btn.btn-facebook.dropdown-toggle.btn-flat:active, .btn.btn-facebook.dropdown-toggle.btn-flat:focus, .btn.btn-facebook.dropdown-toggle.btn-flat:visited {
        color: #2b4279; }
    .btn.btn-facebook.btn-circle, .btn.btn-facebook.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-facebook.btn-circle.btn-outline, .btn.btn-facebook.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-facebook.btn-circle i, .btn.btn-facebook.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-facebook.btn-circle.btn-xs, .btn.btn-facebook.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-facebook.btn-circle.btn-sm, .btn.btn-facebook.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-facebook.btn-circle.btn-lg, .btn.btn-facebook.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-twitter, .btn.btn-twitter.dropdown-toggle {
    background: #00a9f1;
    color: #fff;
    border-color: #00a9f1;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-twitter:hover, .btn.btn-twitter:active, .btn.btn-twitter:focus, .btn.btn-twitter:visited, .btn.btn-twitter.focus, .btn.btn-twitter.dropdown-toggle:hover, .btn.btn-twitter.dropdown-toggle:active, .btn.btn-twitter.dropdown-toggle:focus, .btn.btn-twitter.dropdown-toggle:visited, .btn.btn-twitter.dropdown-toggle.focus {
      color: #fff;
      background: #008cc8;
      border-color: #008cc8; }
    .btn.btn-twitter.btn-raised, .btn.btn-twitter.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-twitter.btn-rounded, .btn.btn-twitter.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-twitter.btn-outline, .btn.btn-twitter.dropdown-toggle.btn-outline {
      background: none;
      color: #00a9f1;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-twitter.btn-outline:hover, .btn.btn-twitter.btn-outline:active, .btn.btn-twitter.btn-outline:focus, .btn.btn-twitter.btn-outline:visited, .btn.btn-twitter.dropdown-toggle.btn-outline:hover, .btn.btn-twitter.dropdown-toggle.btn-outline:active, .btn.btn-twitter.dropdown-toggle.btn-outline:focus, .btn.btn-twitter.dropdown-toggle.btn-outline:visited {
        color: #008cc8;
        border-color: #008cc8; }
      .btn.btn-twitter.btn-outline.btn-rounded, .btn.btn-twitter.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-twitter.btn-flat, .btn.btn-twitter.dropdown-toggle.btn-flat {
      color: #00a9f1; }
      .btn.btn-twitter.btn-flat:hover, .btn.btn-twitter.btn-flat:active, .btn.btn-twitter.btn-flat:focus, .btn.btn-twitter.btn-flat:visited, .btn.btn-twitter.dropdown-toggle.btn-flat:hover, .btn.btn-twitter.dropdown-toggle.btn-flat:active, .btn.btn-twitter.dropdown-toggle.btn-flat:focus, .btn.btn-twitter.dropdown-toggle.btn-flat:visited {
        color: #008cc8; }
    .btn.btn-twitter.btn-circle, .btn.btn-twitter.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-twitter.btn-circle.btn-outline, .btn.btn-twitter.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-twitter.btn-circle i, .btn.btn-twitter.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-twitter.btn-circle.btn-xs, .btn.btn-twitter.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-twitter.btn-circle.btn-sm, .btn.btn-twitter.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-twitter.btn-circle.btn-lg, .btn.btn-twitter.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-linkedin, .btn.btn-linkedin.dropdown-toggle {
    background: #006db3;
    color: #fff;
    border-color: #006db3;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-linkedin:hover, .btn.btn-linkedin:active, .btn.btn-linkedin:focus, .btn.btn-linkedin:visited, .btn.btn-linkedin.focus, .btn.btn-linkedin.dropdown-toggle:hover, .btn.btn-linkedin.dropdown-toggle:active, .btn.btn-linkedin.dropdown-toggle:focus, .btn.btn-linkedin.dropdown-toggle:visited, .btn.btn-linkedin.dropdown-toggle.focus {
      color: #fff;
      background: #00548a;
      border-color: #00548a; }
    .btn.btn-linkedin.btn-raised, .btn.btn-linkedin.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-linkedin.btn-rounded, .btn.btn-linkedin.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-linkedin.btn-outline, .btn.btn-linkedin.dropdown-toggle.btn-outline {
      background: none;
      color: #006db3;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-linkedin.btn-outline:hover, .btn.btn-linkedin.btn-outline:active, .btn.btn-linkedin.btn-outline:focus, .btn.btn-linkedin.btn-outline:visited, .btn.btn-linkedin.dropdown-toggle.btn-outline:hover, .btn.btn-linkedin.dropdown-toggle.btn-outline:active, .btn.btn-linkedin.dropdown-toggle.btn-outline:focus, .btn.btn-linkedin.dropdown-toggle.btn-outline:visited {
        color: #00548a;
        border-color: #00548a; }
      .btn.btn-linkedin.btn-outline.btn-rounded, .btn.btn-linkedin.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-linkedin.btn-flat, .btn.btn-linkedin.dropdown-toggle.btn-flat {
      color: #006db3; }
      .btn.btn-linkedin.btn-flat:hover, .btn.btn-linkedin.btn-flat:active, .btn.btn-linkedin.btn-flat:focus, .btn.btn-linkedin.btn-flat:visited, .btn.btn-linkedin.dropdown-toggle.btn-flat:hover, .btn.btn-linkedin.dropdown-toggle.btn-flat:active, .btn.btn-linkedin.dropdown-toggle.btn-flat:focus, .btn.btn-linkedin.dropdown-toggle.btn-flat:visited {
        color: #00548a; }
    .btn.btn-linkedin.btn-circle, .btn.btn-linkedin.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-linkedin.btn-circle.btn-outline, .btn.btn-linkedin.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-linkedin.btn-circle i, .btn.btn-linkedin.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-linkedin.btn-circle.btn-xs, .btn.btn-linkedin.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-linkedin.btn-circle.btn-sm, .btn.btn-linkedin.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-linkedin.btn-circle.btn-lg, .btn.btn-linkedin.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-apple, .btn.btn-apple.dropdown-toggle {
    background: #737373;
    color: #fff;
    border-color: #737373;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-apple:hover, .btn.btn-apple:active, .btn.btn-apple:focus, .btn.btn-apple:visited, .btn.btn-apple.focus, .btn.btn-apple.dropdown-toggle:hover, .btn.btn-apple.dropdown-toggle:active, .btn.btn-apple.dropdown-toggle:focus, .btn.btn-apple.dropdown-toggle:visited, .btn.btn-apple.dropdown-toggle.focus {
      color: #fff;
      background: #5f5f5f;
      border-color: #5f5f5f; }
    .btn.btn-apple.btn-raised, .btn.btn-apple.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-apple.btn-rounded, .btn.btn-apple.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-apple.btn-outline, .btn.btn-apple.dropdown-toggle.btn-outline {
      background: none;
      color: #737373;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-apple.btn-outline:hover, .btn.btn-apple.btn-outline:active, .btn.btn-apple.btn-outline:focus, .btn.btn-apple.btn-outline:visited, .btn.btn-apple.dropdown-toggle.btn-outline:hover, .btn.btn-apple.dropdown-toggle.btn-outline:active, .btn.btn-apple.dropdown-toggle.btn-outline:focus, .btn.btn-apple.dropdown-toggle.btn-outline:visited {
        color: #5f5f5f;
        border-color: #5f5f5f; }
      .btn.btn-apple.btn-outline.btn-rounded, .btn.btn-apple.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-apple.btn-flat, .btn.btn-apple.dropdown-toggle.btn-flat {
      color: #737373; }
      .btn.btn-apple.btn-flat:hover, .btn.btn-apple.btn-flat:active, .btn.btn-apple.btn-flat:focus, .btn.btn-apple.btn-flat:visited, .btn.btn-apple.dropdown-toggle.btn-flat:hover, .btn.btn-apple.dropdown-toggle.btn-flat:active, .btn.btn-apple.dropdown-toggle.btn-flat:focus, .btn.btn-apple.dropdown-toggle.btn-flat:visited {
        color: #5f5f5f; }
    .btn.btn-apple.btn-circle, .btn.btn-apple.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-apple.btn-circle.btn-outline, .btn.btn-apple.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-apple.btn-circle i, .btn.btn-apple.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-apple.btn-circle.btn-xs, .btn.btn-apple.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-apple.btn-circle.btn-sm, .btn.btn-apple.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-apple.btn-circle.btn-lg, .btn.btn-apple.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-google, .btn.btn-google.dropdown-toggle {
    background: #4285f4;
    color: #fff;
    border-color: #4285f4;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-google:hover, .btn.btn-google:active, .btn.btn-google:focus, .btn.btn-google:visited, .btn.btn-google.focus, .btn.btn-google.dropdown-toggle:hover, .btn.btn-google.dropdown-toggle:active, .btn.btn-google.dropdown-toggle:focus, .btn.btn-google.dropdown-toggle:visited, .btn.btn-google.dropdown-toggle.focus {
      color: #fff;
      background: #1b6cf2;
      border-color: #1b6cf2; }
    .btn.btn-google.btn-raised, .btn.btn-google.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-google.btn-rounded, .btn.btn-google.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-google.btn-outline, .btn.btn-google.dropdown-toggle.btn-outline {
      background: none;
      color: #4285f4;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-google.btn-outline:hover, .btn.btn-google.btn-outline:active, .btn.btn-google.btn-outline:focus, .btn.btn-google.btn-outline:visited, .btn.btn-google.dropdown-toggle.btn-outline:hover, .btn.btn-google.dropdown-toggle.btn-outline:active, .btn.btn-google.dropdown-toggle.btn-outline:focus, .btn.btn-google.dropdown-toggle.btn-outline:visited {
        color: #1b6cf2;
        border-color: #1b6cf2; }
      .btn.btn-google.btn-outline.btn-rounded, .btn.btn-google.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-google.btn-flat, .btn.btn-google.dropdown-toggle.btn-flat {
      color: #4285f4; }
      .btn.btn-google.btn-flat:hover, .btn.btn-google.btn-flat:active, .btn.btn-google.btn-flat:focus, .btn.btn-google.btn-flat:visited, .btn.btn-google.dropdown-toggle.btn-flat:hover, .btn.btn-google.dropdown-toggle.btn-flat:active, .btn.btn-google.dropdown-toggle.btn-flat:focus, .btn.btn-google.dropdown-toggle.btn-flat:visited {
        color: #1b6cf2; }
    .btn.btn-google.btn-circle, .btn.btn-google.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-google.btn-circle.btn-outline, .btn.btn-google.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-google.btn-circle i, .btn.btn-google.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-google.btn-circle.btn-xs, .btn.btn-google.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-google.btn-circle.btn-sm, .btn.btn-google.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-google.btn-circle.btn-lg, .btn.btn-google.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-google-plus, .btn.btn-google-plus.dropdown-toggle {
    background: #e0452c;
    color: #fff;
    border-color: #e0452c;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-google-plus:hover, .btn.btn-google-plus:active, .btn.btn-google-plus:focus, .btn.btn-google-plus:visited, .btn.btn-google-plus.focus, .btn.btn-google-plus.dropdown-toggle:hover, .btn.btn-google-plus.dropdown-toggle:active, .btn.btn-google-plus.dropdown-toggle:focus, .btn.btn-google-plus.dropdown-toggle:visited, .btn.btn-google-plus.dropdown-toggle.focus {
      color: #fff;
      background: #c6351d;
      border-color: #c6351d; }
    .btn.btn-google-plus.btn-raised, .btn.btn-google-plus.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-google-plus.btn-rounded, .btn.btn-google-plus.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-google-plus.btn-outline, .btn.btn-google-plus.dropdown-toggle.btn-outline {
      background: none;
      color: #e0452c;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-google-plus.btn-outline:hover, .btn.btn-google-plus.btn-outline:active, .btn.btn-google-plus.btn-outline:focus, .btn.btn-google-plus.btn-outline:visited, .btn.btn-google-plus.dropdown-toggle.btn-outline:hover, .btn.btn-google-plus.dropdown-toggle.btn-outline:active, .btn.btn-google-plus.dropdown-toggle.btn-outline:focus, .btn.btn-google-plus.dropdown-toggle.btn-outline:visited {
        color: #c6351d;
        border-color: #c6351d; }
      .btn.btn-google-plus.btn-outline.btn-rounded, .btn.btn-google-plus.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-google-plus.btn-flat, .btn.btn-google-plus.dropdown-toggle.btn-flat {
      color: #e0452c; }
      .btn.btn-google-plus.btn-flat:hover, .btn.btn-google-plus.btn-flat:active, .btn.btn-google-plus.btn-flat:focus, .btn.btn-google-plus.btn-flat:visited, .btn.btn-google-plus.dropdown-toggle.btn-flat:hover, .btn.btn-google-plus.dropdown-toggle.btn-flat:active, .btn.btn-google-plus.dropdown-toggle.btn-flat:focus, .btn.btn-google-plus.dropdown-toggle.btn-flat:visited {
        color: #c6351d; }
    .btn.btn-google-plus.btn-circle, .btn.btn-google-plus.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-google-plus.btn-circle.btn-outline, .btn.btn-google-plus.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-google-plus.btn-circle i, .btn.btn-google-plus.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-google-plus.btn-circle.btn-xs, .btn.btn-google-plus.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-google-plus.btn-circle.btn-sm, .btn.btn-google-plus.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-google-plus.btn-circle.btn-lg, .btn.btn-google-plus.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-youtube, .btn.btn-youtube.dropdown-toggle {
    background: #ff3333;
    color: #fff;
    border-color: #ff3333;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-youtube:hover, .btn.btn-youtube:active, .btn.btn-youtube:focus, .btn.btn-youtube:visited, .btn.btn-youtube.focus, .btn.btn-youtube.dropdown-toggle:hover, .btn.btn-youtube.dropdown-toggle:active, .btn.btn-youtube.dropdown-toggle:focus, .btn.btn-youtube.dropdown-toggle:visited, .btn.btn-youtube.dropdown-toggle.focus {
      color: #fff;
      background: #ff0a0a;
      border-color: #ff0a0a; }
    .btn.btn-youtube.btn-raised, .btn.btn-youtube.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-youtube.btn-rounded, .btn.btn-youtube.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-youtube.btn-outline, .btn.btn-youtube.dropdown-toggle.btn-outline {
      background: none;
      color: #ff3333;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-youtube.btn-outline:hover, .btn.btn-youtube.btn-outline:active, .btn.btn-youtube.btn-outline:focus, .btn.btn-youtube.btn-outline:visited, .btn.btn-youtube.dropdown-toggle.btn-outline:hover, .btn.btn-youtube.dropdown-toggle.btn-outline:active, .btn.btn-youtube.dropdown-toggle.btn-outline:focus, .btn.btn-youtube.dropdown-toggle.btn-outline:visited {
        color: #ff0a0a;
        border-color: #ff0a0a; }
      .btn.btn-youtube.btn-outline.btn-rounded, .btn.btn-youtube.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-youtube.btn-flat, .btn.btn-youtube.dropdown-toggle.btn-flat {
      color: #ff3333; }
      .btn.btn-youtube.btn-flat:hover, .btn.btn-youtube.btn-flat:active, .btn.btn-youtube.btn-flat:focus, .btn.btn-youtube.btn-flat:visited, .btn.btn-youtube.dropdown-toggle.btn-flat:hover, .btn.btn-youtube.dropdown-toggle.btn-flat:active, .btn.btn-youtube.dropdown-toggle.btn-flat:focus, .btn.btn-youtube.dropdown-toggle.btn-flat:visited {
        color: #ff0a0a; }
    .btn.btn-youtube.btn-circle, .btn.btn-youtube.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-youtube.btn-circle.btn-outline, .btn.btn-youtube.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-youtube.btn-circle i, .btn.btn-youtube.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-youtube.btn-circle.btn-xs, .btn.btn-youtube.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-youtube.btn-circle.btn-sm, .btn.btn-youtube.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-youtube.btn-circle.btn-lg, .btn.btn-youtube.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-vimeo, .btn.btn-vimeo.dropdown-toggle {
    background: #48b6ed;
    color: #fff;
    border-color: #48b6ed;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-vimeo:hover, .btn.btn-vimeo:active, .btn.btn-vimeo:focus, .btn.btn-vimeo:visited, .btn.btn-vimeo.focus, .btn.btn-vimeo.dropdown-toggle:hover, .btn.btn-vimeo.dropdown-toggle:active, .btn.btn-vimeo.dropdown-toggle:focus, .btn.btn-vimeo.dropdown-toggle:visited, .btn.btn-vimeo.dropdown-toggle.focus {
      color: #fff;
      background: #23a7e9;
      border-color: #23a7e9; }
    .btn.btn-vimeo.btn-raised, .btn.btn-vimeo.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-vimeo.btn-rounded, .btn.btn-vimeo.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-vimeo.btn-outline, .btn.btn-vimeo.dropdown-toggle.btn-outline {
      background: none;
      color: #48b6ed;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-vimeo.btn-outline:hover, .btn.btn-vimeo.btn-outline:active, .btn.btn-vimeo.btn-outline:focus, .btn.btn-vimeo.btn-outline:visited, .btn.btn-vimeo.dropdown-toggle.btn-outline:hover, .btn.btn-vimeo.dropdown-toggle.btn-outline:active, .btn.btn-vimeo.dropdown-toggle.btn-outline:focus, .btn.btn-vimeo.dropdown-toggle.btn-outline:visited {
        color: #23a7e9;
        border-color: #23a7e9; }
      .btn.btn-vimeo.btn-outline.btn-rounded, .btn.btn-vimeo.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-vimeo.btn-flat, .btn.btn-vimeo.dropdown-toggle.btn-flat {
      color: #48b6ed; }
      .btn.btn-vimeo.btn-flat:hover, .btn.btn-vimeo.btn-flat:active, .btn.btn-vimeo.btn-flat:focus, .btn.btn-vimeo.btn-flat:visited, .btn.btn-vimeo.dropdown-toggle.btn-flat:hover, .btn.btn-vimeo.dropdown-toggle.btn-flat:active, .btn.btn-vimeo.dropdown-toggle.btn-flat:focus, .btn.btn-vimeo.dropdown-toggle.btn-flat:visited {
        color: #23a7e9; }
    .btn.btn-vimeo.btn-circle, .btn.btn-vimeo.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-vimeo.btn-circle.btn-outline, .btn.btn-vimeo.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-vimeo.btn-circle i, .btn.btn-vimeo.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-vimeo.btn-circle.btn-xs, .btn.btn-vimeo.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-vimeo.btn-circle.btn-sm, .btn.btn-vimeo.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-vimeo.btn-circle.btn-lg, .btn.btn-vimeo.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-pinterest, .btn.btn-pinterest.dropdown-toggle {
    background: #ce1a19;
    color: #fff;
    border-color: #ce1a19;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-pinterest:hover, .btn.btn-pinterest:active, .btn.btn-pinterest:focus, .btn.btn-pinterest:visited, .btn.btn-pinterest.focus, .btn.btn-pinterest.dropdown-toggle:hover, .btn.btn-pinterest.dropdown-toggle:active, .btn.btn-pinterest.dropdown-toggle:focus, .btn.btn-pinterest.dropdown-toggle:visited, .btn.btn-pinterest.dropdown-toggle.focus {
      color: #fff;
      background: #aa1515;
      border-color: #aa1515; }
    .btn.btn-pinterest.btn-raised, .btn.btn-pinterest.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-pinterest.btn-rounded, .btn.btn-pinterest.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-pinterest.btn-outline, .btn.btn-pinterest.dropdown-toggle.btn-outline {
      background: none;
      color: #ce1a19;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-pinterest.btn-outline:hover, .btn.btn-pinterest.btn-outline:active, .btn.btn-pinterest.btn-outline:focus, .btn.btn-pinterest.btn-outline:visited, .btn.btn-pinterest.dropdown-toggle.btn-outline:hover, .btn.btn-pinterest.dropdown-toggle.btn-outline:active, .btn.btn-pinterest.dropdown-toggle.btn-outline:focus, .btn.btn-pinterest.dropdown-toggle.btn-outline:visited {
        color: #aa1515;
        border-color: #aa1515; }
      .btn.btn-pinterest.btn-outline.btn-rounded, .btn.btn-pinterest.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-pinterest.btn-flat, .btn.btn-pinterest.dropdown-toggle.btn-flat {
      color: #ce1a19; }
      .btn.btn-pinterest.btn-flat:hover, .btn.btn-pinterest.btn-flat:active, .btn.btn-pinterest.btn-flat:focus, .btn.btn-pinterest.btn-flat:visited, .btn.btn-pinterest.dropdown-toggle.btn-flat:hover, .btn.btn-pinterest.dropdown-toggle.btn-flat:active, .btn.btn-pinterest.dropdown-toggle.btn-flat:focus, .btn.btn-pinterest.dropdown-toggle.btn-flat:visited {
        color: #aa1515; }
    .btn.btn-pinterest.btn-circle, .btn.btn-pinterest.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-pinterest.btn-circle.btn-outline, .btn.btn-pinterest.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-pinterest.btn-circle i, .btn.btn-pinterest.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-pinterest.btn-circle.btn-xs, .btn.btn-pinterest.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-pinterest.btn-circle.btn-sm, .btn.btn-pinterest.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-pinterest.btn-circle.btn-lg, .btn.btn-pinterest.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-yelp, .btn.btn-yelp.dropdown-toggle {
    background: #c30f00;
    color: #fff;
    border-color: #c30f00;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-yelp:hover, .btn.btn-yelp:active, .btn.btn-yelp:focus, .btn.btn-yelp:visited, .btn.btn-yelp.focus, .btn.btn-yelp.dropdown-toggle:hover, .btn.btn-yelp.dropdown-toggle:active, .btn.btn-yelp.dropdown-toggle:focus, .btn.btn-yelp.dropdown-toggle:visited, .btn.btn-yelp.dropdown-toggle.focus {
      color: #fff;
      background: #9a0c00;
      border-color: #9a0c00; }
    .btn.btn-yelp.btn-raised, .btn.btn-yelp.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-yelp.btn-rounded, .btn.btn-yelp.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-yelp.btn-outline, .btn.btn-yelp.dropdown-toggle.btn-outline {
      background: none;
      color: #c30f00;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-yelp.btn-outline:hover, .btn.btn-yelp.btn-outline:active, .btn.btn-yelp.btn-outline:focus, .btn.btn-yelp.btn-outline:visited, .btn.btn-yelp.dropdown-toggle.btn-outline:hover, .btn.btn-yelp.dropdown-toggle.btn-outline:active, .btn.btn-yelp.dropdown-toggle.btn-outline:focus, .btn.btn-yelp.dropdown-toggle.btn-outline:visited {
        color: #9a0c00;
        border-color: #9a0c00; }
      .btn.btn-yelp.btn-outline.btn-rounded, .btn.btn-yelp.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-yelp.btn-flat, .btn.btn-yelp.dropdown-toggle.btn-flat {
      color: #c30f00; }
      .btn.btn-yelp.btn-flat:hover, .btn.btn-yelp.btn-flat:active, .btn.btn-yelp.btn-flat:focus, .btn.btn-yelp.btn-flat:visited, .btn.btn-yelp.dropdown-toggle.btn-flat:hover, .btn.btn-yelp.dropdown-toggle.btn-flat:active, .btn.btn-yelp.dropdown-toggle.btn-flat:focus, .btn.btn-yelp.dropdown-toggle.btn-flat:visited {
        color: #9a0c00; }
    .btn.btn-yelp.btn-circle, .btn.btn-yelp.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-yelp.btn-circle.btn-outline, .btn.btn-yelp.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-yelp.btn-circle i, .btn.btn-yelp.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-yelp.btn-circle.btn-xs, .btn.btn-yelp.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-yelp.btn-circle.btn-sm, .btn.btn-yelp.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-yelp.btn-circle.btn-lg, .btn.btn-yelp.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-dribbble, .btn.btn-dribbble.dropdown-toggle {
    background: #ed4584;
    color: #fff;
    border-color: #ed4584;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-dribbble:hover, .btn.btn-dribbble:active, .btn.btn-dribbble:focus, .btn.btn-dribbble:visited, .btn.btn-dribbble.focus, .btn.btn-dribbble.dropdown-toggle:hover, .btn.btn-dribbble.dropdown-toggle:active, .btn.btn-dribbble.dropdown-toggle:focus, .btn.btn-dribbble.dropdown-toggle:visited, .btn.btn-dribbble.dropdown-toggle.focus {
      color: #fff;
      background: #e9206b;
      border-color: #e9206b; }
    .btn.btn-dribbble.btn-raised, .btn.btn-dribbble.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-dribbble.btn-rounded, .btn.btn-dribbble.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-dribbble.btn-outline, .btn.btn-dribbble.dropdown-toggle.btn-outline {
      background: none;
      color: #ed4584;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-dribbble.btn-outline:hover, .btn.btn-dribbble.btn-outline:active, .btn.btn-dribbble.btn-outline:focus, .btn.btn-dribbble.btn-outline:visited, .btn.btn-dribbble.dropdown-toggle.btn-outline:hover, .btn.btn-dribbble.dropdown-toggle.btn-outline:active, .btn.btn-dribbble.dropdown-toggle.btn-outline:focus, .btn.btn-dribbble.dropdown-toggle.btn-outline:visited {
        color: #e9206b;
        border-color: #e9206b; }
      .btn.btn-dribbble.btn-outline.btn-rounded, .btn.btn-dribbble.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-dribbble.btn-flat, .btn.btn-dribbble.dropdown-toggle.btn-flat {
      color: #ed4584; }
      .btn.btn-dribbble.btn-flat:hover, .btn.btn-dribbble.btn-flat:active, .btn.btn-dribbble.btn-flat:focus, .btn.btn-dribbble.btn-flat:visited, .btn.btn-dribbble.dropdown-toggle.btn-flat:hover, .btn.btn-dribbble.dropdown-toggle.btn-flat:active, .btn.btn-dribbble.dropdown-toggle.btn-flat:focus, .btn.btn-dribbble.dropdown-toggle.btn-flat:visited {
        color: #e9206b; }
    .btn.btn-dribbble.btn-circle, .btn.btn-dribbble.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-dribbble.btn-circle.btn-outline, .btn.btn-dribbble.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-dribbble.btn-circle i, .btn.btn-dribbble.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-dribbble.btn-circle.btn-xs, .btn.btn-dribbble.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-dribbble.btn-circle.btn-sm, .btn.btn-dribbble.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-dribbble.btn-circle.btn-lg, .btn.btn-dribbble.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-amazon, .btn.btn-amazon.dropdown-toggle {
    background: #ff9700;
    color: #fff;
    border-color: #ff9700;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-amazon:hover, .btn.btn-amazon:active, .btn.btn-amazon:focus, .btn.btn-amazon:visited, .btn.btn-amazon.focus, .btn.btn-amazon.dropdown-toggle:hover, .btn.btn-amazon.dropdown-toggle:active, .btn.btn-amazon.dropdown-toggle:focus, .btn.btn-amazon.dropdown-toggle:visited, .btn.btn-amazon.dropdown-toggle.focus {
      color: #fff;
      background: #d67f00;
      border-color: #d67f00; }
    .btn.btn-amazon.btn-raised, .btn.btn-amazon.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-amazon.btn-rounded, .btn.btn-amazon.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-amazon.btn-outline, .btn.btn-amazon.dropdown-toggle.btn-outline {
      background: none;
      color: #ff9700;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-amazon.btn-outline:hover, .btn.btn-amazon.btn-outline:active, .btn.btn-amazon.btn-outline:focus, .btn.btn-amazon.btn-outline:visited, .btn.btn-amazon.dropdown-toggle.btn-outline:hover, .btn.btn-amazon.dropdown-toggle.btn-outline:active, .btn.btn-amazon.dropdown-toggle.btn-outline:focus, .btn.btn-amazon.dropdown-toggle.btn-outline:visited {
        color: #d67f00;
        border-color: #d67f00; }
      .btn.btn-amazon.btn-outline.btn-rounded, .btn.btn-amazon.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-amazon.btn-flat, .btn.btn-amazon.dropdown-toggle.btn-flat {
      color: #ff9700; }
      .btn.btn-amazon.btn-flat:hover, .btn.btn-amazon.btn-flat:active, .btn.btn-amazon.btn-flat:focus, .btn.btn-amazon.btn-flat:visited, .btn.btn-amazon.dropdown-toggle.btn-flat:hover, .btn.btn-amazon.dropdown-toggle.btn-flat:active, .btn.btn-amazon.dropdown-toggle.btn-flat:focus, .btn.btn-amazon.dropdown-toggle.btn-flat:visited {
        color: #d67f00; }
    .btn.btn-amazon.btn-circle, .btn.btn-amazon.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-amazon.btn-circle.btn-outline, .btn.btn-amazon.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-amazon.btn-circle i, .btn.btn-amazon.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-amazon.btn-circle.btn-xs, .btn.btn-amazon.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-amazon.btn-circle.btn-sm, .btn.btn-amazon.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-amazon.btn-circle.btn-lg, .btn.btn-amazon.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-skype, .btn.btn-skype.dropdown-toggle {
    background: #00acf4;
    color: #fff;
    border-color: #00acf4;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-skype:hover, .btn.btn-skype:active, .btn.btn-skype:focus, .btn.btn-skype:visited, .btn.btn-skype.focus, .btn.btn-skype.dropdown-toggle:hover, .btn.btn-skype.dropdown-toggle:active, .btn.btn-skype.dropdown-toggle:focus, .btn.btn-skype.dropdown-toggle:visited, .btn.btn-skype.dropdown-toggle.focus {
      color: #fff;
      background: #008fcb;
      border-color: #008fcb; }
    .btn.btn-skype.btn-raised, .btn.btn-skype.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-skype.btn-rounded, .btn.btn-skype.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-skype.btn-outline, .btn.btn-skype.dropdown-toggle.btn-outline {
      background: none;
      color: #00acf4;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-skype.btn-outline:hover, .btn.btn-skype.btn-outline:active, .btn.btn-skype.btn-outline:focus, .btn.btn-skype.btn-outline:visited, .btn.btn-skype.dropdown-toggle.btn-outline:hover, .btn.btn-skype.dropdown-toggle.btn-outline:active, .btn.btn-skype.dropdown-toggle.btn-outline:focus, .btn.btn-skype.dropdown-toggle.btn-outline:visited {
        color: #008fcb;
        border-color: #008fcb; }
      .btn.btn-skype.btn-outline.btn-rounded, .btn.btn-skype.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-skype.btn-flat, .btn.btn-skype.dropdown-toggle.btn-flat {
      color: #00acf4; }
      .btn.btn-skype.btn-flat:hover, .btn.btn-skype.btn-flat:active, .btn.btn-skype.btn-flat:focus, .btn.btn-skype.btn-flat:visited, .btn.btn-skype.dropdown-toggle.btn-flat:hover, .btn.btn-skype.dropdown-toggle.btn-flat:active, .btn.btn-skype.dropdown-toggle.btn-flat:focus, .btn.btn-skype.dropdown-toggle.btn-flat:visited {
        color: #008fcb; }
    .btn.btn-skype.btn-circle, .btn.btn-skype.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-skype.btn-circle.btn-outline, .btn.btn-skype.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-skype.btn-circle i, .btn.btn-skype.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-skype.btn-circle.btn-xs, .btn.btn-skype.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-skype.btn-circle.btn-sm, .btn.btn-skype.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-skype.btn-circle.btn-lg, .btn.btn-skype.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-instagram, .btn.btn-instagram.dropdown-toggle {
    background: #396d9a;
    color: #fff;
    border-color: #396d9a;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-instagram:hover, .btn.btn-instagram:active, .btn.btn-instagram:focus, .btn.btn-instagram:visited, .btn.btn-instagram.focus, .btn.btn-instagram.dropdown-toggle:hover, .btn.btn-instagram.dropdown-toggle:active, .btn.btn-instagram.dropdown-toggle:focus, .btn.btn-instagram.dropdown-toggle:visited, .btn.btn-instagram.dropdown-toggle.focus {
      color: #fff;
      background: #2e587c;
      border-color: #2e587c; }
    .btn.btn-instagram.btn-raised, .btn.btn-instagram.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-instagram.btn-rounded, .btn.btn-instagram.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-instagram.btn-outline, .btn.btn-instagram.dropdown-toggle.btn-outline {
      background: none;
      color: #396d9a;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-instagram.btn-outline:hover, .btn.btn-instagram.btn-outline:active, .btn.btn-instagram.btn-outline:focus, .btn.btn-instagram.btn-outline:visited, .btn.btn-instagram.dropdown-toggle.btn-outline:hover, .btn.btn-instagram.dropdown-toggle.btn-outline:active, .btn.btn-instagram.dropdown-toggle.btn-outline:focus, .btn.btn-instagram.dropdown-toggle.btn-outline:visited {
        color: #2e587c;
        border-color: #2e587c; }
      .btn.btn-instagram.btn-outline.btn-rounded, .btn.btn-instagram.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-instagram.btn-flat, .btn.btn-instagram.dropdown-toggle.btn-flat {
      color: #396d9a; }
      .btn.btn-instagram.btn-flat:hover, .btn.btn-instagram.btn-flat:active, .btn.btn-instagram.btn-flat:focus, .btn.btn-instagram.btn-flat:visited, .btn.btn-instagram.dropdown-toggle.btn-flat:hover, .btn.btn-instagram.dropdown-toggle.btn-flat:active, .btn.btn-instagram.dropdown-toggle.btn-flat:focus, .btn.btn-instagram.dropdown-toggle.btn-flat:visited {
        color: #2e587c; }
    .btn.btn-instagram.btn-circle, .btn.btn-instagram.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-instagram.btn-circle.btn-outline, .btn.btn-instagram.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-instagram.btn-circle i, .btn.btn-instagram.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-instagram.btn-circle.btn-xs, .btn.btn-instagram.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-instagram.btn-circle.btn-sm, .btn.btn-instagram.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-instagram.btn-circle.btn-lg, .btn.btn-instagram.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-dropbox, .btn.btn-dropbox.dropdown-toggle {
    background: #0d84de;
    color: #fff;
    border-color: #0d84de;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-dropbox:hover, .btn.btn-dropbox:active, .btn.btn-dropbox:focus, .btn.btn-dropbox:visited, .btn.btn-dropbox.focus, .btn.btn-dropbox.dropdown-toggle:hover, .btn.btn-dropbox.dropdown-toggle:active, .btn.btn-dropbox.dropdown-toggle:focus, .btn.btn-dropbox.dropdown-toggle:visited, .btn.btn-dropbox.dropdown-toggle.focus {
      color: #fff;
      background: #0b6db7;
      border-color: #0b6db7; }
    .btn.btn-dropbox.btn-raised, .btn.btn-dropbox.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-dropbox.btn-rounded, .btn.btn-dropbox.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-dropbox.btn-outline, .btn.btn-dropbox.dropdown-toggle.btn-outline {
      background: none;
      color: #0d84de;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-dropbox.btn-outline:hover, .btn.btn-dropbox.btn-outline:active, .btn.btn-dropbox.btn-outline:focus, .btn.btn-dropbox.btn-outline:visited, .btn.btn-dropbox.dropdown-toggle.btn-outline:hover, .btn.btn-dropbox.dropdown-toggle.btn-outline:active, .btn.btn-dropbox.dropdown-toggle.btn-outline:focus, .btn.btn-dropbox.dropdown-toggle.btn-outline:visited {
        color: #0b6db7;
        border-color: #0b6db7; }
      .btn.btn-dropbox.btn-outline.btn-rounded, .btn.btn-dropbox.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-dropbox.btn-flat, .btn.btn-dropbox.dropdown-toggle.btn-flat {
      color: #0d84de; }
      .btn.btn-dropbox.btn-flat:hover, .btn.btn-dropbox.btn-flat:active, .btn.btn-dropbox.btn-flat:focus, .btn.btn-dropbox.btn-flat:visited, .btn.btn-dropbox.dropdown-toggle.btn-flat:hover, .btn.btn-dropbox.dropdown-toggle.btn-flat:active, .btn.btn-dropbox.dropdown-toggle.btn-flat:focus, .btn.btn-dropbox.dropdown-toggle.btn-flat:visited {
        color: #0b6db7; }
    .btn.btn-dropbox.btn-circle, .btn.btn-dropbox.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-dropbox.btn-circle.btn-outline, .btn.btn-dropbox.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-dropbox.btn-circle i, .btn.btn-dropbox.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-dropbox.btn-circle.btn-xs, .btn.btn-dropbox.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-dropbox.btn-circle.btn-sm, .btn.btn-dropbox.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-dropbox.btn-circle.btn-lg, .btn.btn-dropbox.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-flickr, .btn.btn-flickr.dropdown-toggle {
    background: #ea0066;
    color: #fff;
    border-color: #ea0066;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-flickr:hover, .btn.btn-flickr:active, .btn.btn-flickr:focus, .btn.btn-flickr:visited, .btn.btn-flickr.focus, .btn.btn-flickr.dropdown-toggle:hover, .btn.btn-flickr.dropdown-toggle:active, .btn.btn-flickr.dropdown-toggle:focus, .btn.btn-flickr.dropdown-toggle:visited, .btn.btn-flickr.dropdown-toggle.focus {
      color: #fff;
      background: #c10054;
      border-color: #c10054; }
    .btn.btn-flickr.btn-raised, .btn.btn-flickr.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-flickr.btn-rounded, .btn.btn-flickr.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-flickr.btn-outline, .btn.btn-flickr.dropdown-toggle.btn-outline {
      background: none;
      color: #ea0066;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-flickr.btn-outline:hover, .btn.btn-flickr.btn-outline:active, .btn.btn-flickr.btn-outline:focus, .btn.btn-flickr.btn-outline:visited, .btn.btn-flickr.dropdown-toggle.btn-outline:hover, .btn.btn-flickr.dropdown-toggle.btn-outline:active, .btn.btn-flickr.dropdown-toggle.btn-outline:focus, .btn.btn-flickr.dropdown-toggle.btn-outline:visited {
        color: #c10054;
        border-color: #c10054; }
      .btn.btn-flickr.btn-outline.btn-rounded, .btn.btn-flickr.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-flickr.btn-flat, .btn.btn-flickr.dropdown-toggle.btn-flat {
      color: #ea0066; }
      .btn.btn-flickr.btn-flat:hover, .btn.btn-flickr.btn-flat:active, .btn.btn-flickr.btn-flat:focus, .btn.btn-flickr.btn-flat:visited, .btn.btn-flickr.dropdown-toggle.btn-flat:hover, .btn.btn-flickr.dropdown-toggle.btn-flat:active, .btn.btn-flickr.dropdown-toggle.btn-flat:focus, .btn.btn-flickr.dropdown-toggle.btn-flat:visited {
        color: #c10054; }
    .btn.btn-flickr.btn-circle, .btn.btn-flickr.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-flickr.btn-circle.btn-outline, .btn.btn-flickr.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-flickr.btn-circle i, .btn.btn-flickr.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-flickr.btn-circle.btn-xs, .btn.btn-flickr.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-flickr.btn-circle.btn-sm, .btn.btn-flickr.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-flickr.btn-circle.btn-lg, .btn.btn-flickr.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-github, .btn.btn-github.dropdown-toggle {
    background: #2f2f2f;
    color: #fff;
    border-color: #2f2f2f;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-github:hover, .btn.btn-github:active, .btn.btn-github:focus, .btn.btn-github:visited, .btn.btn-github.focus, .btn.btn-github.dropdown-toggle:hover, .btn.btn-github.dropdown-toggle:active, .btn.btn-github.dropdown-toggle:focus, .btn.btn-github.dropdown-toggle:visited, .btn.btn-github.dropdown-toggle.focus {
      color: #fff;
      background: #1b1b1b;
      border-color: #1b1b1b; }
    .btn.btn-github.btn-raised, .btn.btn-github.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-github.btn-rounded, .btn.btn-github.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-github.btn-outline, .btn.btn-github.dropdown-toggle.btn-outline {
      background: none;
      color: #2f2f2f;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-github.btn-outline:hover, .btn.btn-github.btn-outline:active, .btn.btn-github.btn-outline:focus, .btn.btn-github.btn-outline:visited, .btn.btn-github.dropdown-toggle.btn-outline:hover, .btn.btn-github.dropdown-toggle.btn-outline:active, .btn.btn-github.dropdown-toggle.btn-outline:focus, .btn.btn-github.dropdown-toggle.btn-outline:visited {
        color: #1b1b1b;
        border-color: #1b1b1b; }
      .btn.btn-github.btn-outline.btn-rounded, .btn.btn-github.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-github.btn-flat, .btn.btn-github.dropdown-toggle.btn-flat {
      color: #2f2f2f; }
      .btn.btn-github.btn-flat:hover, .btn.btn-github.btn-flat:active, .btn.btn-github.btn-flat:focus, .btn.btn-github.btn-flat:visited, .btn.btn-github.dropdown-toggle.btn-flat:hover, .btn.btn-github.dropdown-toggle.btn-flat:active, .btn.btn-github.dropdown-toggle.btn-flat:focus, .btn.btn-github.dropdown-toggle.btn-flat:visited {
        color: #1b1b1b; }
    .btn.btn-github.btn-circle, .btn.btn-github.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-github.btn-circle.btn-outline, .btn.btn-github.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-github.btn-circle i, .btn.btn-github.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-github.btn-circle.btn-xs, .btn.btn-github.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-github.btn-circle.btn-sm, .btn.btn-github.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-github.btn-circle.btn-lg, .btn.btn-github.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-tumblr, .btn.btn-tumblr.dropdown-toggle {
    background: #304c68;
    color: #fff;
    border-color: #304c68;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-tumblr:hover, .btn.btn-tumblr:active, .btn.btn-tumblr:focus, .btn.btn-tumblr:visited, .btn.btn-tumblr.focus, .btn.btn-tumblr.dropdown-toggle:hover, .btn.btn-tumblr.dropdown-toggle:active, .btn.btn-tumblr.dropdown-toggle:focus, .btn.btn-tumblr.dropdown-toggle:visited, .btn.btn-tumblr.dropdown-toggle.focus {
      color: #fff;
      background: #23384c;
      border-color: #23384c; }
    .btn.btn-tumblr.btn-raised, .btn.btn-tumblr.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-tumblr.btn-rounded, .btn.btn-tumblr.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-tumblr.btn-outline, .btn.btn-tumblr.dropdown-toggle.btn-outline {
      background: none;
      color: #304c68;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-tumblr.btn-outline:hover, .btn.btn-tumblr.btn-outline:active, .btn.btn-tumblr.btn-outline:focus, .btn.btn-tumblr.btn-outline:visited, .btn.btn-tumblr.dropdown-toggle.btn-outline:hover, .btn.btn-tumblr.dropdown-toggle.btn-outline:active, .btn.btn-tumblr.dropdown-toggle.btn-outline:focus, .btn.btn-tumblr.dropdown-toggle.btn-outline:visited {
        color: #23384c;
        border-color: #23384c; }
      .btn.btn-tumblr.btn-outline.btn-rounded, .btn.btn-tumblr.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-tumblr.btn-flat, .btn.btn-tumblr.dropdown-toggle.btn-flat {
      color: #304c68; }
      .btn.btn-tumblr.btn-flat:hover, .btn.btn-tumblr.btn-flat:active, .btn.btn-tumblr.btn-flat:focus, .btn.btn-tumblr.btn-flat:visited, .btn.btn-tumblr.dropdown-toggle.btn-flat:hover, .btn.btn-tumblr.dropdown-toggle.btn-flat:active, .btn.btn-tumblr.dropdown-toggle.btn-flat:focus, .btn.btn-tumblr.dropdown-toggle.btn-flat:visited {
        color: #23384c; }
    .btn.btn-tumblr.btn-circle, .btn.btn-tumblr.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-tumblr.btn-circle.btn-outline, .btn.btn-tumblr.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-tumblr.btn-circle i, .btn.btn-tumblr.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-tumblr.btn-circle.btn-xs, .btn.btn-tumblr.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-tumblr.btn-circle.btn-sm, .btn.btn-tumblr.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-tumblr.btn-circle.btn-lg, .btn.btn-tumblr.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }
  .btn.btn-foursquare, .btn.btn-foursquare.dropdown-toggle {
    background: #207dc5;
    color: #fff;
    border-color: #207dc5;
    text-transform: uppercase;
    font-size: 0.875rem; }
    .btn.btn-foursquare:hover, .btn.btn-foursquare:active, .btn.btn-foursquare:focus, .btn.btn-foursquare:visited, .btn.btn-foursquare.focus, .btn.btn-foursquare.dropdown-toggle:hover, .btn.btn-foursquare.dropdown-toggle:active, .btn.btn-foursquare.dropdown-toggle:focus, .btn.btn-foursquare.dropdown-toggle:visited, .btn.btn-foursquare.dropdown-toggle.focus {
      color: #fff;
      background: #1a67a2;
      border-color: #1a67a2; }
    .btn.btn-foursquare.btn-raised, .btn.btn-foursquare.dropdown-toggle.btn-raised {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
    .btn.btn-foursquare.btn-rounded, .btn.btn-foursquare.dropdown-toggle.btn-rounded {
      border-radius: 12px; }
    .btn.btn-foursquare.btn-outline, .btn.btn-foursquare.dropdown-toggle.btn-outline {
      background: none;
      color: #207dc5;
      border-style: solid;
      border-width: 1px; }
      .btn.btn-foursquare.btn-outline:hover, .btn.btn-foursquare.btn-outline:active, .btn.btn-foursquare.btn-outline:focus, .btn.btn-foursquare.btn-outline:visited, .btn.btn-foursquare.dropdown-toggle.btn-outline:hover, .btn.btn-foursquare.dropdown-toggle.btn-outline:active, .btn.btn-foursquare.dropdown-toggle.btn-outline:focus, .btn.btn-foursquare.dropdown-toggle.btn-outline:visited {
        color: #1a67a2;
        border-color: #1a67a2; }
      .btn.btn-foursquare.btn-outline.btn-rounded, .btn.btn-foursquare.dropdown-toggle.btn-outline.btn-rounded {
        border-radius: 15px; }
    .btn.btn-foursquare.btn-flat, .btn.btn-foursquare.dropdown-toggle.btn-flat {
      color: #207dc5; }
      .btn.btn-foursquare.btn-flat:hover, .btn.btn-foursquare.btn-flat:active, .btn.btn-foursquare.btn-flat:focus, .btn.btn-foursquare.btn-flat:visited, .btn.btn-foursquare.dropdown-toggle.btn-flat:hover, .btn.btn-foursquare.dropdown-toggle.btn-flat:active, .btn.btn-foursquare.dropdown-toggle.btn-flat:focus, .btn.btn-foursquare.dropdown-toggle.btn-flat:visited {
        color: #1a67a2; }
    .btn.btn-foursquare.btn-circle, .btn.btn-foursquare.dropdown-toggle.btn-circle {
      border-radius: 50%;
      padding: 0;
      height: 55px;
      line-height: 55px;
      width: 55px;
      text-align: center;
      font-size: 16px;
      border-width: 0 !important; }
      .btn.btn-foursquare.btn-circle.btn-outline, .btn.btn-foursquare.dropdown-toggle.btn-circle.btn-outline {
        border-width: 1px !important; }
      .btn.btn-foursquare.btn-circle i, .btn.btn-foursquare.dropdown-toggle.btn-circle i {
        margin: 0;
        padding: 0; }
      .btn.btn-foursquare.btn-circle.btn-xs, .btn.btn-foursquare.dropdown-toggle.btn-circle.btn-xs {
        border-radius: 50%;
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 14px; }
      .btn.btn-foursquare.btn-circle.btn-sm, .btn.btn-foursquare.dropdown-toggle.btn-circle.btn-sm {
        border-radius: 50%;
        height: 45px;
        line-height: 45px;
        width: 45px;
        font-size: 17px; }
      .btn.btn-foursquare.btn-circle.btn-lg, .btn.btn-foursquare.dropdown-toggle.btn-circle.btn-lg {
        height: 65px;
        line-height: 65px;
        width: 65px;
        border-radius: 50%;
        font-size: 20px; }

[data-background="light"] .btn.btn-flat {
  background: #ffffff;
  border-color: #ffffff; }
  [data-background="light"] .btn.btn-flat:hover, [data-background="light"] .btn.btn-flat:active, [data-background="light"] .btn.btn-flat:focus, [data-background="light"] .btn.btn-flat:visited {
    background: #fafafa;
    border-color: #fafafa; }

[data-background="dark"] .btn.btn-flat {
  background: #424242;
  border-color: #424242; }
  [data-background="dark"] .btn.btn-flat:hover, [data-background="dark"] .btn.btn-flat:active, [data-background="dark"] .btn.btn-flat:focus, [data-background="dark"] .btn.btn-flat:visited {
    background: #3d3d3d;
    border-color: #3d3d3d; }

[data-background="sgbc"] .btn.btn-flat {
  background: #424242;
  border-color: #424242; }
  [data-background="sgbc"] .btn.btn-flat:hover, [data-background="sgbc"] .btn.btn-flat:active, [data-background="sgbc"] .btn.btn-flat:focus, [data-background="sgbc"] .btn.btn-flat:visited {
    background: #3d3d3d;
    border-color: #3d3d3d; }

[data-background="primary"] .btn.btn-flat {
  background: #303f9f;
  border-color: #303f9f; }
  [data-background="primary"] .btn.btn-flat:hover, [data-background="primary"] .btn.btn-flat:active, [data-background="primary"] .btn.btn-flat:focus, [data-background="primary"] .btn.btn-flat:visited {
    background: #2e3c97;
    border-color: #2e3c97; }

[data-background="secondary"] .btn.btn-flat {
  background: #7b1fa2;
  border-color: #7b1fa2; }
  [data-background="secondary"] .btn.btn-flat:hover, [data-background="secondary"] .btn.btn-flat:active, [data-background="secondary"] .btn.btn-flat:focus, [data-background="secondary"] .btn.btn-flat:visited {
    background: #741d99;
    border-color: #741d99; }

[data-background="info"] .btn.btn-flat {
  background: #0288d1;
  border-color: #0288d1; }
  [data-background="info"] .btn.btn-flat:hover, [data-background="info"] .btn.btn-flat:active, [data-background="info"] .btn.btn-flat:focus, [data-background="info"] .btn.btn-flat:visited {
    background: #0281c7;
    border-color: #0281c7; }

[data-background="success"] .btn.btn-flat {
  background: #388e3c;
  border-color: #388e3c; }
  [data-background="success"] .btn.btn-flat:hover, [data-background="success"] .btn.btn-flat:active, [data-background="success"] .btn.btn-flat:focus, [data-background="success"] .btn.btn-flat:visited {
    background: #358739;
    border-color: #358739; }

[data-background="warning"] .btn.btn-flat {
  background: #ffa000;
  border-color: #ffa000; }
  [data-background="warning"] .btn.btn-flat:hover, [data-background="warning"] .btn.btn-flat:active, [data-background="warning"] .btn.btn-flat:focus, [data-background="warning"] .btn.btn-flat:visited {
    background: #f59a00;
    border-color: #f59a00; }

[data-background="danger"] .btn.btn-flat {
  background: #d32f2f;
  border-color: #d32f2f; }
  [data-background="danger"] .btn.btn-flat:hover, [data-background="danger"] .btn.btn-flat:active, [data-background="danger"] .btn.btn-flat:focus, [data-background="danger"] .btn.btn-flat:visited {
    background: #cd2b2b;
    border-color: #cd2b2b; }

[data-background="green"] .btn.btn-flat {
  background: #5d7e3d;
  border-color: #5d7e3d; }
  [data-background="green"] .btn.btn-flat:hover, [data-background="green"] .btn.btn-flat:active, [data-background="green"] .btn.btn-flat:focus, [data-background="green"] .btn.btn-flat:visited {
    background: #58773a;
    border-color: #58773a; }

[data-background="c-newdark"] .btn.btn-flat {
  background: #313b3d;
  border-color: #313b3d; }
  [data-background="c-newdark"] .btn.btn-flat:hover, [data-background="c-newdark"] .btn.btn-flat:active, [data-background="c-newdark"] .btn.btn-flat:focus, [data-background="c-newdark"] .btn.btn-flat:visited {
    background: #2c3637;
    border-color: #2c3637; }

[data-background="light"] .btn.btn-default {
  background: #ffffff;
  border-color: #ffffff;
  color: #424242; }
  [data-background="light"] .btn.btn-default:hover, [data-background="light"] .btn.btn-default:active, [data-background="light"] .btn.btn-default:focus, [data-background="light"] .btn.btn-default:visited {
    background: #e0e0e0;
    border-color: #e0e0e0;
    color: #424242; }
  [data-background="light"] .btn.btn-default.btn-outline {
    background: none;
    color: #424242;
    border-style: solid;
    border-width: 1px;
    border-color: #424242; }
    [data-background="light"] .btn.btn-default.btn-outline:hover, [data-background="light"] .btn.btn-default.btn-outline:active, [data-background="light"] .btn.btn-default.btn-outline:focus, [data-background="light"] .btn.btn-default.btn-outline:visited {
      color: #2e2e2e;
      border-color: #2e2e2e; }
    [data-background="light"] .btn.btn-default.btn-outline.btn-rounded {
      border-radius: 15px; }
  [data-background="light"] .btn.btn-default.btn-flat {
    background: transparent;
    border-color: transparent;
    color: #424242; }
    [data-background="light"] .btn.btn-default.btn-flat:hover, [data-background="light"] .btn.btn-default.btn-flat:active, [data-background="light"] .btn.btn-default.btn-flat:focus, [data-background="light"] .btn.btn-default.btn-flat:visited {
      background: transparent;
      border-color: transparent;
      color: #2e2e2e; }

[data-background="dark"] .btn.btn-default {
  background: #424242;
  border-color: #424242;
  color: #ffffff; }
  [data-background="dark"] .btn.btn-default:hover, [data-background="dark"] .btn.btn-default:active, [data-background="dark"] .btn.btn-default:focus, [data-background="dark"] .btn.btn-default:visited {
    background: whitesmoke;
    border-color: whitesmoke;
    color: #424242; }
  [data-background="dark"] .btn.btn-default.btn-outline {
    background: none;
    color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff; }
    [data-background="dark"] .btn.btn-default.btn-outline:hover, [data-background="dark"] .btn.btn-default.btn-outline:active, [data-background="dark"] .btn.btn-default.btn-outline:focus, [data-background="dark"] .btn.btn-default.btn-outline:visited {
      color: #ebebeb;
      border-color: #ebebeb; }
    [data-background="dark"] .btn.btn-default.btn-outline.btn-rounded {
      border-radius: 15px; }
  [data-background="dark"] .btn.btn-default.btn-flat {
    background: transparent;
    border-color: transparent;
    color: #ffffff; }
    [data-background="dark"] .btn.btn-default.btn-flat:hover, [data-background="dark"] .btn.btn-default.btn-flat:active, [data-background="dark"] .btn.btn-default.btn-flat:focus, [data-background="dark"] .btn.btn-default.btn-flat:visited {
      background: transparent;
      border-color: transparent;
      color: #ebebeb; }

[data-background="primary"] .btn.btn-default {
  background: #303f9f;
  border-color: #303f9f;
  color: #ffffff; }
  [data-background="primary"] .btn.btn-default:hover, [data-background="primary"] .btn.btn-default:active, [data-background="primary"] .btn.btn-default:focus, [data-background="primary"] .btn.btn-default:visited {
    background: whitesmoke;
    border-color: whitesmoke;
    color: #303f9f; }
  [data-background="primary"] .btn.btn-default.btn-outline {
    background: none;
    color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff; }
    [data-background="primary"] .btn.btn-default.btn-outline:hover, [data-background="primary"] .btn.btn-default.btn-outline:active, [data-background="primary"] .btn.btn-default.btn-outline:focus, [data-background="primary"] .btn.btn-default.btn-outline:visited {
      color: #ebebeb;
      border-color: #ebebeb; }
    [data-background="primary"] .btn.btn-default.btn-outline.btn-rounded {
      border-radius: 15px; }
  [data-background="primary"] .btn.btn-default.btn-flat {
    background: transparent;
    border-color: transparent;
    color: #ffffff; }
    [data-background="primary"] .btn.btn-default.btn-flat:hover, [data-background="primary"] .btn.btn-default.btn-flat:active, [data-background="primary"] .btn.btn-default.btn-flat:focus, [data-background="primary"] .btn.btn-default.btn-flat:visited {
      background: transparent;
      border-color: transparent;
      color: #ebebeb; }

.btn-group.btn-group-rounded .btn {
  border-radius: 0; }
  .btn-group.btn-group-rounded .btn.btn-rounded:first-child {
    border-radius: 12px 0 0 12px; }
  .btn-group.btn-group-rounded .btn.btn-rounded:last-child {
    border-radius: 0 12px 12px 0; }

.btn-group.btn-group-outlined .btn {
  border-radius: 0; }
  .btn-group.btn-group-outlined .btn.btn-rounded:first-child {
    border-radius: 12px 0 0 12px; }
  .btn-group.btn-group-outlined .btn.btn-rounded:last-child {
    border-radius: 0 12px 12px 0; }

.btn-group.btn-group-flat .btn {
  border-radius: 0; }
  .btn-group.btn-group-flat .btn.btn-rounded:first-child {
    border-radius: 12px 0 0 12px; }
  .btn-group.btn-group-flat .btn.btn-rounded:last-child {
    border-radius: 0 12px 12px 0; }

.btn-group .btn {
  border-radius: 0; }
  .btn-group .btn.btn-light:not(.btn-outline):not(.btn-flat) {
    border-color: white; }
    .btn-group .btn.btn-light:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: white; }
    .btn-group .btn.btn-light:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-light:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-light:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-light:not(.btn-outline):not(.btn-flat):visited {
      background: #f2f2f2;
      border-color: #f2f2f2; }
  .btn-group .btn.btn-dark:not(.btn-outline):not(.btn-flat) {
    border-color: #4f4f4f; }
    .btn-group .btn.btn-dark:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #4f4f4f; }
    .btn-group .btn.btn-dark:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-dark:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-dark:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-dark:not(.btn-outline):not(.btn-flat):visited {
      background: #353535;
      border-color: #353535; }
  .btn-group .btn.btn-grey:not(.btn-outline):not(.btn-flat) {
    border-color: #6f7377; }
    .btn-group .btn.btn-grey:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #6f7377; }
    .btn-group .btn.btn-grey:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-grey:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-grey:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-grey:not(.btn-outline):not(.btn-flat):visited {
      background: #57595d;
      border-color: #57595d; }
  .btn-group .btn.btn-sgbc:not(.btn-outline):not(.btn-flat) {
    border-color: #6f7377; }
    .btn-group .btn.btn-sgbc:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #6f7377; }
    .btn-group .btn.btn-sgbc:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-sgbc:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-sgbc:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-sgbc:not(.btn-outline):not(.btn-flat):visited {
      background: #57595d;
      border-color: #57595d; }
  .btn-group .btn.btn-default:not(.btn-outline):not(.btn-flat) {
    border-color: #2e2e2e; }
    .btn-group .btn.btn-default:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #2e2e2e; }
    .btn-group .btn.btn-default:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-default:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-default:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-default:not(.btn-outline):not(.btn-flat):visited {
      background: #141414;
      border-color: #141414; }
  .btn-group .btn.btn-primary:not(.btn-outline):not(.btn-flat) {
    border-color: #3647b3; }
    .btn-group .btn.btn-primary:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #3647b3; }
    .btn-group .btn.btn-primary:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-primary:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-primary:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-primary:not(.btn-outline):not(.btn-flat):visited {
      background: #2a378b;
      border-color: #2a378b; }
  .btn-group .btn.btn-secondary:not(.btn-outline):not(.btn-flat) {
    border-color: #8b23b7; }
    .btn-group .btn.btn-secondary:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #8b23b7; }
    .btn-group .btn.btn-secondary:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-secondary:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-secondary:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-secondary:not(.btn-outline):not(.btn-flat):visited {
      background: #6b1b8d;
      border-color: #6b1b8d; }
  .btn-group .btn.btn-info:not(.btn-outline):not(.btn-flat) {
    border-color: #0298ea; }
    .btn-group .btn.btn-info:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #0298ea; }
    .btn-group .btn.btn-info:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-info:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-info:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-info:not(.btn-outline):not(.btn-flat):visited {
      background: #0278b8;
      border-color: #0278b8; }
  .btn-group .btn.btn-success:not(.btn-outline):not(.btn-flat) {
    border-color: #3fa044; }
    .btn-group .btn.btn-success:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #3fa044; }
    .btn-group .btn.btn-success:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-success:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-success:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-success:not(.btn-outline):not(.btn-flat):visited {
      background: #317c34;
      border-color: #317c34; }
  .btn-group .btn.btn-warning:not(.btn-outline):not(.btn-flat) {
    border-color: #ffaa1a; }
    .btn-group .btn.btn-warning:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #ffaa1a; }
    .btn-group .btn.btn-warning:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-warning:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-warning:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-warning:not(.btn-outline):not(.btn-flat):visited {
      background: #e69000;
      border-color: #e69000; }
  .btn-group .btn.btn-danger:not(.btn-outline):not(.btn-flat) {
    border-color: #d74444; }
    .btn-group .btn.btn-danger:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #d74444; }
    .btn-group .btn.btn-danger:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-danger:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-danger:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-danger:not(.btn-outline):not(.btn-flat):visited {
      background: #c02929;
      border-color: #c02929; }
  .btn-group .btn.btn-green:not(.btn-outline):not(.btn-flat) {
    border-color: #6a8f45; }
    .btn-group .btn.btn-green:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #6a8f45; }
    .btn-group .btn.btn-green:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-green:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-green:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-green:not(.btn-outline):not(.btn-flat):visited {
      background: #506d35;
      border-color: #506d35; }
  .btn-group .btn.btn-cyder-blue-1:not(.btn-outline):not(.btn-flat) {
    border-color: #028eda; }
    .btn-group .btn.btn-cyder-blue-1:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #028eda; }
    .btn-group .btn.btn-cyder-blue-1:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-blue-1:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-blue-1:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-blue-1:not(.btn-outline):not(.btn-flat):visited {
      background: #026ea8;
      border-color: #026ea8; }
  .btn-group .btn.btn-cyder-blue-2:not(.btn-outline):not(.btn-flat) {
    border-color: #40c5d8; }
    .btn-group .btn.btn-cyder-blue-2:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #40c5d8; }
    .btn-group .btn.btn-cyder-blue-2:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-blue-2:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-blue-2:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-blue-2:not(.btn-outline):not(.btn-flat):visited {
      background: #27abbe;
      border-color: #27abbe; }
  .btn-group .btn.btn-cyder-green-1:not(.btn-outline):not(.btn-flat) {
    border-color: #4bad62; }
    .btn-group .btn.btn-cyder-green-1:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #4bad62; }
    .btn-group .btn.btn-cyder-green-1:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-green-1:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-green-1:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-green-1:not(.btn-outline):not(.btn-flat):visited {
      background: #3b894e;
      border-color: #3b894e; }
  .btn-group .btn.btn-cyder-green-2:not(.btn-outline):not(.btn-flat) {
    border-color: #2a5224; }
    .btn-group .btn.btn-cyder-green-2:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #2a5224; }
    .btn-group .btn.btn-cyder-green-2:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-green-2:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-green-2:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-green-2:not(.btn-outline):not(.btn-flat):visited {
      background: #182e14;
      border-color: #182e14; }
  .btn-group .btn.btn-cyder-orange-1:not(.btn-outline):not(.btn-flat) {
    border-color: #ffb434; }
    .btn-group .btn.btn-cyder-orange-1:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #ffb434; }
    .btn-group .btn.btn-cyder-orange-1:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-orange-1:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-orange-1:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-orange-1:not(.btn-outline):not(.btn-flat):visited {
      background: #ffa201;
      border-color: #ffa201; }
  .btn-group .btn.btn-cyder-pink-1:not(.btn-outline):not(.btn-flat) {
    border-color: #ef7185; }
    .btn-group .btn.btn-cyder-pink-1:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #ef7185; }
    .btn-group .btn.btn-cyder-pink-1:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-pink-1:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-pink-1:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-pink-1:not(.btn-outline):not(.btn-flat):visited {
      background: #e9435d;
      border-color: #e9435d; }
  .btn-group .btn.btn-cyder-yellow-1:not(.btn-outline):not(.btn-flat) {
    border-color: #ffd96b; }
    .btn-group .btn.btn-cyder-yellow-1:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #ffd96b; }
    .btn-group .btn.btn-cyder-yellow-1:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-yellow-1:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-yellow-1:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-yellow-1:not(.btn-outline):not(.btn-flat):visited {
      background: #ffcd38;
      border-color: #ffcd38; }
  .btn-group .btn.btn-cyder-blue-1-darken:not(.btn-outline):not(.btn-flat) {
    border-color: #015d8f; }
    .btn-group .btn.btn-cyder-blue-1-darken:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #015d8f; }
    .btn-group .btn.btn-cyder-blue-1-darken:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-blue-1-darken:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-blue-1-darken:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-blue-1-darken:not(.btn-outline):not(.btn-flat):visited {
      background: #013c5c;
      border-color: #013c5c; }
  .btn-group .btn.btn-cyder-green-1-darken:not(.btn-outline):not(.btn-flat) {
    border-color: #347744; }
    .btn-group .btn.btn-cyder-green-1-darken:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #347744; }
    .btn-group .btn.btn-cyder-green-1-darken:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-green-1-darken:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-green-1-darken:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-green-1-darken:not(.btn-outline):not(.btn-flat):visited {
      background: #245430;
      border-color: #245430; }
  .btn-group .btn.btn-cyder-green-2-darken:not(.btn-outline):not(.btn-flat) {
    border-color: #0f1d0c; }
    .btn-group .btn.btn-cyder-green-2-darken:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #0f1d0c; }
    .btn-group .btn.btn-cyder-green-2-darken:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-green-2-darken:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-green-2-darken:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-green-2-darken:not(.btn-outline):not(.btn-flat):visited {
      background: black;
      border-color: black; }
  .btn-group .btn.btn-cyder-orange-1-darken:not(.btn-outline):not(.btn-flat) {
    border-color: #e69200; }
    .btn-group .btn.btn-cyder-orange-1-darken:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #e69200; }
    .btn-group .btn.btn-cyder-orange-1-darken:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-orange-1-darken:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-orange-1-darken:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-orange-1-darken:not(.btn-outline):not(.btn-flat):visited {
      background: #b37100;
      border-color: #b37100; }
  .btn-group .btn.btn-c-lighttile-darken:not(.btn-outline):not(.btn-flat) {
    border-color: #4ab9bb; }
    .btn-group .btn.btn-c-lighttile-darken:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #4ab9bb; }
    .btn-group .btn.btn-c-lighttile-darken:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-c-lighttile-darken:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-c-lighttile-darken:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-c-lighttile-darken:not(.btn-outline):not(.btn-flat):visited {
      background: #399799;
      border-color: #399799; }
  .btn-group .btn.btn-cyder-pink-1-darken:not(.btn-outline):not(.btn-flat) {
    border-color: #e72c4a; }
    .btn-group .btn.btn-cyder-pink-1-darken:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #e72c4a; }
    .btn-group .btn.btn-cyder-pink-1-darken:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-cyder-pink-1-darken:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-cyder-pink-1-darken:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-cyder-pink-1-darken:not(.btn-outline):not(.btn-flat):visited {
      background: #c91733;
      border-color: #c91733; }
  .btn-group .btn.btn-c-newdark:not(.btn-outline):not(.btn-flat) {
    border-color: #3c494b; }
    .btn-group .btn.btn-c-newdark:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #3c494b; }
    .btn-group .btn.btn-c-newdark:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-c-newdark:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-c-newdark:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-c-newdark:not(.btn-outline):not(.btn-flat):visited {
      background: #262d2f;
      border-color: #262d2f; }
  .btn-group .btn.btn-c-lightblue:not(.btn-outline):not(.btn-flat) {
    border-color: #8ab8d0; }
    .btn-group .btn.btn-c-lightblue:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #8ab8d0; }
    .btn-group .btn.btn-c-lightblue:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-c-lightblue:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-c-lightblue:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-c-lightblue:not(.btn-outline):not(.btn-flat):visited {
      background: #66a2c2;
      border-color: #66a2c2; }
  .btn-group .btn.btn-c-lighttile:not(.btn-outline):not(.btn-flat) {
    border-color: #82ced0; }
    .btn-group .btn.btn-c-lighttile:not(.btn-outline):not(.btn-flat):nth-child(even) {
      background-color: #82ced0; }
    .btn-group .btn.btn-c-lighttile:not(.btn-outline):not(.btn-flat):hover, .btn-group .btn.btn-c-lighttile:not(.btn-outline):not(.btn-flat):active, .btn-group .btn.btn-c-lighttile:not(.btn-outline):not(.btn-flat):focus, .btn-group .btn.btn-c-lighttile:not(.btn-outline):not(.btn-flat):visited {
      background: #5cc0c2;
      border-color: #5cc0c2; }

[data-controller="ui-elements"][data-view="buttons"] .row .button-groups-example {
  margin-bottom: 20px; }
  [data-controller="ui-elements"][data-view="buttons"] .row .button-groups-example:last-child {
    margin-bottom: 0; }
